import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useWorkOrdersStore = defineStore('workorders', {
    state: () => {
        return {
            workorders: useStorage('workorders', [] as Array<any>),
            orders: useStorage('orders', []),
            expenses: useStorage('expenses', []),
            timeRegister: useStorage('timeRegister', {}),
            activeTimeRegister: useStorage('activeTimeRegister', {}),

            textosPredefinidos: useStorage('textosPredefinidos', []),
            tiposHora: useStorage('tiposHora', [] as Array<any>),
            materials: useStorage('materials', {}),
        }
    },

    actions: {},

    getters: {
        getWorkorder: (state) => (id:any) => {
            return state.workorders.flat()?.find((wo:any) => wo.id == id);
        }
    }
})