<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>
        <ion-title>{{ $t('selecciona-una-instalacion') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-list style="border-radius: 15px">
        <template v-if="$appVersion() > 148">
          <ion-item
            v-for="(s, key) in data"
            :key="`sistema-${key}`"
            @click="$emit('done', s)"
          >
            {{ s.name || s?.nombre }}
          </ion-item>
        </template>

        <template v-else> <!-- VERSIONES ANTIGUAS A LA 1.4.8 -->
          <SystemTree :systems="data.flat()" @done="(sistema) => $emit('done', sistema)"/>
        </template>
      </ion-list>

      <!-- <ion-infinite-scroll
        threshold="100px"
        id="infinite-scroll"
        @ionInfinite="$emit('load', $event)"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('Cargando más sistemas...')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll> -->
    </ion-content>

  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonItem,
  IonContent,
  // IonLabel,
  // IonSearchbar,
  // IonCheckbox,
  // IonInfiniteScroll,
  // IonInfiniteScrollContent,
} from '@ionic/vue';

import SystemTree from '@/components/TreeView/SystemTree.vue';

export default {
  name: 'MSetSelectingSistema',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    data: {
      required: true,
      type: Array,
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonItem,
    IonContent,
    SystemTree,
    // IonLabel,
    // IonSearchbar,
    // IonCheckbox,
    // IonInfiniteScroll,
    // IonInfiniteScrollContent,
  },

  computed: {},

  setup() {

    return {};
  },

  methods: {},
};
</script>