<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>
        <ion-title>{{ $t('Añadir activos') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="$emit('done', null)">{{ $t('Añadir') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        class="defaultSearchBar"
        :placeholder="$t('Buscar activos...')"
        v-model="search"
        @ionInput="$emit('search', search)"
        :debounce="500"
      />

        <ion-list style="border-radius: 15px">
          <template v-if="data?.length">
            <ion-item v-for="(activo, key) in data" :key="`modal-activo-${key}`">
              <ion-checkbox slot="start" v-model="activo.active"> </ion-checkbox>

              <ion-label>
                <p>
                  <template v-if="activo.sistema?.sistemaTree">

                    {{ `${activo.sistema?.sistemaTree} / ${activo.sistema?.nombre}` }}
                  </template>
                  <template v-else>
                      {{ activo.sistema?.nombre }}
                  </template>
                </p>


                <h3>
                  <template v-if="activo.maquinaTree">
                    {{ `${activo.maquinaTree} /` }} <span style='font-weight: 500;'>{{ (activo?.nombre || `#${activo.id}`) }}</span>
                  </template>
                  <template v-else>
                      {{ activo?.nombre || `#${activo.id}` }}
                  </template>

                  {{ activo?.modelo?.modelo }} {{ activo?.modelo?.marca }}
                </h3>
                <p>{{ activo.numeroserie }}</p>
                <p>{{ activo.ubicacion }}</p>
                <p>{{ activo.observaciones }}</p>
              </ion-label>
            </ion-item>
          </template>

          <template v-else>
            <ion-item
              lines="none"
              style="margin: 0; color: var(--ion-color-primary)"
              @click="$emit('newAsset', search)"
            >
              <ion-icon slot="start" :icon="add"></ion-icon>

              <ion-label>
                <h3>Añadir "{{ search }}" a la lista de activos</h3>
              </ion-label>
            </ion-item>
          </template>
        </ion-list>

        <ion-infinite-scroll
          threshold="100px"
          id="infinite-scroll"
          @ionInfinite="$emit('load', $event)"
        >
          <ion-infinite-scroll-content
            loading-spinner="bubbles"
            :loading-text="$t('Cargando más activos...')"
          >
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </ion-content>

  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonSearchbar,
  IonCheckbox,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/vue';

import {
  add,
} from 'ionicons/icons';

import { ref } from 'vue';

export default {
  name: 'MSetActivos',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    data: {
      required: true,
      type: Array,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonSearchbar,
    IonCheckbox,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },

  computed: {},

  setup() {

    return {
      add,

      search: ref(null),
    };
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
ion-searchbar.defaultSearchBar {
  --background: #fff;
  --border-radius: 1rem;
}
</style>