<template>
  <ion-modal
    :is-open="modelValue"
    :breakpoints="[0.1, 0.5, 1]"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ data.codigo }} {{ data?.nombre }}</ion-title>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <p style="text-align: center">{{ data.observaciones }}</p>

      <img
        :src="data.imagen"
        style="object-fit: cover; width: 100%"
      />
    </ion-content>

  </ion-modal>
</template>

<script>

import {
  IonModal,
  IonButton,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonContent,
} from '@ionic/vue';

export default {
  name: 'MSetImageMaterialModal',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    data: {
      required: true,
      type: Object,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonContent,
  },

  setup() {
    return {};
  },

  methods: {},
};
</script>