<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>
        <ion-title>{{ $t('Seleccionar activo') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-list style="border-radius: 15px">
        <ion-item @click="$emit('done', null)">
          <ion-label>
            <h3 style="font-weight: 500">– {{ $t('Sin activo') }} –</h3>
          </ion-label>
        </ion-item>
        <ion-item
          v-for="(maquina, key) in data"
          :key="`maquina-${key}`"
          @click="$emit('done', maquina)"
        >
          <ion-label style="white-space: normal">
            <p>{{ maquina.sistema?.nombre }}</p>
            <h3>
              {{ maquina?.nombre || `#${maquina.id}` }}
              {{ maquina?.modelo?.modelo }} {{ maquina?.modelo?.marca }}
            </h3>
            <p>{{ maquina.numeroserie }}</p>
            <p>{{ maquina.ubicacion }}</p>
            <p>{{ maquina.observaciones }}</p>
          </ion-label>
        </ion-item>
      </ion-list>

    </ion-content>

  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
} from '@ionic/vue';

export default {
  name: 'MSetActivoMaterial',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    data: {
      required: true,
      type: Array,
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent
  },

  setup() {
    return {};
  },

  methods: {},
};
</script>