<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t('Nuevo activo') }}</ion-title>

        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-button :disabled="isDisabled"  @click="$emit('done', null)">
            <template v-if="isDisabled">
              <!-- <ion-spinner name="lines-small"></ion-spinner> -->
            </template>
            {{ $t('Añadir') }}
          </ion-button>
        </ion-buttons>

      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
        <ion-list style="border-radius: 15px">
          <ion-item>
            <ion-input
              :label="$t('Nombre')"
              labelPlacement=""
              class="ion-text-right"
              v-model="assetCopy.nombre"
              @ionInput="$emit('update:newActivo', assetCopy)"
            />
          </ion-item>

          <ion-item>
            <ion-input
              :label="$t('Núm. Serie')"
              labelPlacement=""
              class="ion-text-right"
              v-model="assetCopy.numeroserie"
              @ionInput="$emit('update:newActivo', assetCopy)"
            />
          </ion-item>

          <ion-item>
            <ion-input
              :label="$t('Ubicación')"
              labelPlacement=""
              class="ion-text-right"
              v-model="assetCopy.ubicacion"
              @ionInput="$emit('update:newActivo', assetCopy)"
            />
          </ion-item>

          <ion-item>
            <ion-textarea
              :label="$t('Observaciones')"
              v-model="assetCopy.observaciones"
              :autoGrow="true"
              @ionChange="$emit('update:newActivo', assetCopy)"
            />
          </ion-item>

          <ion-item>
            <ion-input
              :label="$t('Modelo')"
              labelPlacement=""
              @click="$emit('setModeloNewActivo', true)"
              class="ion-text-right"
              :value="newActivo.modeloNombre"
              readonly
              clear-input
            ></ion-input>
          </ion-item>

          <ion-item v-if="newActivo?.checklist">
            <ion-input
              :label="$t('Checklist')"
              labelPlacement=""
              class="ion-text-right"
              :value="`${newActivo?.checklist?.codigo || '()'} — ${newActivo?.checklist?.nombre}`"
              readonly
            ></ion-input>
          </ion-item>

          <ion-item>
            <ion-input
              :label="$t('Instalación')"
              labelPlacement=""
              @click="$emit('setSistema', true)"
              class="ion-text-right"
              :value="newActivo.nombreSistema"
              readonly
            />
          </ion-item>

          <ion-item lines="none">
            <ion-label>{{ $t('Imagen') }}</ion-label>
            <ion-buttons>
              <ion-button
                size="small"
                fill="clear"
                color="tertiary"
                @click="$emit('addPhotoActivo', null)"
              >
                <ion-icon :icon="camera"></ion-icon>
              </ion-button>

              <ion-button
                size="small"
                fill="clear"
                color="dark"
                @click="$emit('galleryPicker', null)"
              >
                <ion-icon :icon="imagesOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>

          <div class="ion-padding">
            <ion-img
              v-for="(image, index) in newActivo.images"
              :key="`image-preview-${index}`"
              style="width: 200px; height: 200px"
              :src="image"
            ></ion-img>
          </div>

          <div class="ion-padding">
            <ion-img
              v-if="newActivo.image"
              style="width: 100%"
              :src="newActivo.image"
            ></ion-img>
          </div>
        </ion-list>
    </ion-content>

  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonInput,
  IonTextarea,
  IonImg,
  // IonSpinner,
} from '@ionic/vue';

import {
  add,
  imagesOutline,
  camera,
} from 'ionicons/icons';

import { ref } from 'vue';

export default {
  name: 'MSetActivo',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    newActivo: {
      required: true,
      type: Object,
    },

    isDisabled: {
      required: true,
      type: Boolean,
      default: false
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonInput,
    IonTextarea,
    IonImg,
    // IonSpinner,
  },

  watch: {
    modelValue(v) {
      if (v) this.assetCopy = structuredClone(this.newActivo);
    },
  },

  computed: {},

  setup() {

    return {
      add,
      imagesOutline,
      camera,

      assetCopy: ref({})
    };
  },

  methods: {},
};
</script>
<style lang='scss' scoped>
ion-textarea {
  text-align: right;
}
</style>