<template>
  <div id="fullscreen">
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useVideoPlayer ,VideoPlayerHook, VideoPlayerProps } from '../composables/CVideoPlayer';
import { VideoModel } from '../interfaces/interfaces';
export default defineComponent({
  name: 'FullscreenVideoPlayer',

  props: {
    videoData: {
      type: null
    },
    modelValue: {
      required: true,
      type: Boolean,
    },
  },

  emits: {
    'update:modelValue': () => true
  },

  setup(props, { emit }) {

    let ret: any = {};
    const onVPEvents: VideoPlayerProps = {} as VideoPlayerProps;

    onVPEvents.onPlay = async (fromPlayerId: string, currentTime: number | undefined) => {
        console.log(`<<<< onPlay in ViewVideo ${fromPlayerId} ct: ${currentTime}`);
    }
    onVPEvents.onPause = async (fromPlayerId: string, currentTime: number | undefined) => {
        console.log(`<<<< onPause in ViewVideo ${fromPlayerId} ct: ${currentTime}`)
    }
    onVPEvents.onEnded = async (fromPlayerId: string, currentTime: number | undefined) => {
        console.log(`<<<< onEnded in ViewVideo ${fromPlayerId} ct: ${currentTime}`)
        await playerLeave();
    }
    onVPEvents.onExit = async (dismiss: boolean) => {
        console.log(`<<<< onExit in ViewVideo ${dismiss}`)
        await playerLeave();
    }
    onVPEvents.onReady = async (fromPlayerId: string, currentTime: number | undefined) => {
        console.log(`<<<< onReady in ViewVideo ${fromPlayerId} ct: ${currentTime}`)
    }

    setTimeout(() => {
      playerLeave();
    }, 2000);

    const vpHook: VideoPlayerHook = useVideoPlayer(onVPEvents);
    const content = ref({} as VideoModel);

    const playerLeave = async () => {

      await vpHook.removeListeners();
      await vpHook.stopAllPlayers();
      emit('update:modelValue')
    }


    const playVideo = async () => {
      // const params = [
      //   "fullscreen",
      //   props.videoData.src,
      //   "fullscreen",
      //   "div"
      // ];

      // if( props.videoData.subtitle_url != null && props.videoData.subtitle_langage != null) {
      //   params = [...params, props.videoData.subtitle_url, props.videoData.subtitle_langage];
      // }
      // if(props.videoData.subtitle_options != null) {
      //   params = [...params, props.videoData.subtitle_options];
      // }

      ret = await vpHook.initPlayer("fullscreen", props.videoData.src, "fullscrean", "div");
      console.log(`ret : ${JSON.stringify(ret)}`);

    }
    onMounted(async () => {
        await playVideo();
    });
    return {content};
  }
});
</script>