<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ $t('Seleccionar familia') }}</ion-title>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar :placeholder="$t('Buscar familias...')" v-model="familiesSearch" />

      <ion-list style="border-radius: 15px">
        <ion-item
          v-for="(fam, key) in computedExpenseFamilies"
          :key="`modal-familia-gasto-${key}`"
          @click="setFamiliaGasto(fam)"
        >

          <ion-label>
            <h3>{{ fam?.nombre }}</h3>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-modal>
</template>

<script>
import { ref } from 'vue';

import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonSearchbar,
} from '@ionic/vue';

// Stores
import { useGmaoStore } from '@/stores/gmao';

export default {
  name: 'MSetFamilia',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonSearchbar,
  },

  computed: {
    computedExpenseFamilies() {
      return this.expensesFam?.filter((f) => f?.nombre.toLowerCase().includes(this.familiesSearch?.toLowerCase() || ''));
    },
  },

  setup() {

    const gmao = useGmaoStore();

    return {
      expensesFam: ref([]),
      familiesSearch: ref(null),

      // STORES
      gmao,
    };
  },

  methods: {
    setFamiliaGasto(familia) {
      const expenseObject = {
        tipo: familia
      };

      this.getExpensesFam(familia?.id).then((d) => {
        this.$emit('update:modelValue', false);

        if (d.length) this.$emit('setSubFamilia', {tipo: familia});
        else expenseObject.subtipo = null;

        this.$emit('update:expenseAdd', expenseObject);
      });

    },

    async getExpensesFam(id_familia = null, id_proyecto = null) {
      return await this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getExpensesFamilies',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            id_familia,
            id_proyecto,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.expensesFam = data;

          return data;
        }).catch(() => {
          // TODO: offline
        })
    },
  },
};
</script>