<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ $t('Seleccionar material') }}</ion-title>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        :placeholder="$t('Buscar materiales...')"
        v-model="materialSearch"
        @ionInput="applyFilters(true)"
        :debounce="500"
      />

      <template v-if="computedAddMaterials.active?.length">

        <h5>{{ $t('Sugeridos por activo') }}</h5>

        <ion-list style="border-radius: 15px">
          <ion-item
            v-for="(material, key) in computedAddMaterials.active"
            :key="`modal-material-active-${key}`"
            @click.prevent="$emit('done', material)"
          >
            <ion-avatar slot="start" v-if="material.imagen">
              <img :src="material.imagen" @click.stop="this.$emit('done:image', material)"/>
            </ion-avatar>
            <ion-label>
              <h3>
                {{
                  comportamientos?.ver_codigo_articulo_en_partes_materiales
                    ? `(${material.codigo}) `
                    : ''
                }}
                {{ material?.nombre }}
              </h3>
            </ion-label>
          </ion-item>
        </ion-list>
      </template>

      <template v-if="computedAddMaterials.model?.length">
          <h5>{{ $t('Sugeridos por modelo') }}</h5>

          <ion-list>
            <ion-item
              v-for="(material, key) in computedAddMaterials.model"
              :key="`modal-material-model-${key}`"
              @click.prevent="$emit('done', material)"
            >
              <ion-avatar slot="start" v-if="material.imagen">
                <img :src="material.imagen" @click.stop="this.$emit('done:image', material)"/>
              </ion-avatar>
              <ion-label>
                <h3>
                  {{
                    comportamientos?.ver_codigo_articulo_en_partes_materiales
                      ? `(${material.codigo}) `
                      : ''
                  }}
                  {{ material?.nombre }}
                </h3>
              </ion-label>
            </ion-item>
          </ion-list>
      </template>

      <h5>{{ $t('Todos los materiales') }}</h5>

      <ion-list fullscreen>
        <ion-item
          v-for="(material, key) in computedAddMaterials.all"
          :key="`modal-material-${key}`"
          @click.prevent="$emit('done', material)"
        >
          <ion-avatar slot="start" v-if="material.imagen">
            <img :src="material.imagen" @click.stop="this.$emit('done:image', material)"/>
          </ion-avatar>
          <ion-label>
            <h3>
              {{
                comportamientos?.ver_codigo_articulo_en_partes_materiales
                  ? `(${material.codigo}) `
                  : ''
              }}
              {{ material?.nombre }}
            </h3>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll
        threshold="100px"
        id="infinite-scroll"
        @ionInfinite="onLoad($event)"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('Cargando más articulos...')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>

    </ion-content>

  </ion-modal>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';

// Stores
import { useGmaoStore } from '@/stores/gmao';

// EXPORT MODULE
import { SQLiteDBConnection } from 'vue-sqlite-hook/dist';
import { useOfflineStore } from '@/stores/offline';

import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonAvatar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
} from '@ionic/vue';

export default {
  name: 'MSetMaterials',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    comportamientos: {
      type: Object,
      required: false,
    },

    cliente: {
      type: String,
      required: false,
    },

    selectedMaterial: {
      type: Object,
      required: false,
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonAvatar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
  },

  setup() {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();

    const app = getCurrentInstance();

    return {
      materialSearch: ref(null),
      materials: ref([]),

      current_page: ref(1),
      last_page: ref(null),
      total: ref(null),
      loading: ref(null),

      // Stores
      gmao,
      offline,

      // OTHER
      app,
    };
  },

  computed: {
    computedAddMaterials() {
      const { materials } = this;
      if (!materials?.length && !navigator.onLine) {
        return {
          all: this.filterMaterials(
            this.offline.selMaterials?.all?.data.slice(0, 20) || []
          ),
          active: [],
          model: [],
        };
      }

      return {
        all: this.filterMaterials(materials?.map((v) => v.all?.data).flat() || []),
        active: this.filterMaterials(materials?.map((v) => v.active?.data).flat() || []),
        model: this.filterMaterials(materials?.map((v) => v.model?.data).flat() || []),
      };
    },
  },

  watch: {
    modelValue(ev) {
      if (ev) this.applyFilters(true);
    },
  },

  methods: {

    filterMaterials(materials) {
      return materials
      ?.filter(m => m && typeof m === 'object' && Object.keys(m).length > 0)
      ?.filter((m) =>
        Object?.values(m)
          ?.join(`;`)
          ?.toLowerCase()
          ?.includes((this.materialSearch || '')?.toLowerCase())
      );
    },

    /** PAGINATION */
    onLoad(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFilters(false).then(() => {
          event.target.complete();
        });
      } else {
        event.target.complete();
      }
    },
    async applyFilters(reset = true) {
      if (reset) {
        this.materials = [];
        this.current_page = 1;
      }
      return this.getMaterials();
    },

    async getMaterials() {
      this.loading = true;
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getMateriales',
            token: this.gmao.user.token,
            value: this.gmao.user.id,
            active: this.selectedMaterial.activo,
            id_cliente: this.cliente || null,
            page: this.current_page,
            search: this.materialSearch,
            almacen: (this.gmao.warehouse?.map((w) => w.id) || []).toString() || '',
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.materials.push(data);

          this.current_page = data.all.current_page;
          this.last_page = data.all.last_page;
          this.total = data.all.total;
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();
              const query = `
                SELECT * FROM articulos A
                ${
                  this.materialSearch || this.selectedMaterial.activo
                    ? `
                  WHERE
                    ${
                      this.materialSearch
                        ? `
                      nombre LIKE '%${this.materialSearch}%' OR codigo LIKE '%${this.materialSearch}%'
                    `
                        : ''
                    }
                    ${this.materialSearch && this.selectedMaterial.activo ? 'AND' : ''}
                    ${
                      this.selectedMaterial.activo
                        ? `
                      EXISTS (SELECT * FROM repuestos_en_maquina R WHERE R.id_maquina=${this.selectedMaterial.activo} AND R.id_articulo=A.id)
                    `
                        : ''
                    }
                `
                    : ''
                }
              `;

              const getMaterialsPromise = new Promise((resolve) => {
                resolve(
                  this.$SQLiteQuery(
                    'getMaterials_workorder',
                    'partes',
                    query,
                    e.config,
                    db
                  )
                );
              });
              getMaterialsPromise.then(() => {
                setTimeout(() => {
                  // this.setActivos = false;
                  sqlite.closeConnection('gmaoTecnicos').then(async () => {
                    this.materials.push(this.offline.selMaterials);
                    // await this.getWorkorder(this.$route.params.id).then(async () => {
                    // });
                  });
                }, 100);
              });
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: GET Materiales");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
                this.$Sentry.captureException(err);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

  },
};
</script>