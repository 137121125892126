<template>
  <ion-page>
    <AppToolbar>
      <template v-slot:start>
        <ion-icon
          :icon="chevronBack"
          @click="$router.go(-1)"
          class="f20"
        ></ion-icon>
      </template>

      <template v-slot:end>
        <ion-button
          v-if="$hasPermissions('maquinas','editar')"
          @click="() => isUserOnline() ? prepareAssetEdit() : undefined"
          :disabled="active?.not_editable"
        >
          <ion-icon
            class="f20"
            :icon="createOutline"
          />
        </ion-button>
      </template>

      <template v-slot:bottom>
        <ion-button
          expand="block"
          color="light"
          @click="toggleTimer"
          :disabled="active?.not_editable"
        >
          <ion-icon slot="start" :icon="stopwatchOutline"></ion-icon>
          {{ isRunning ? $t('stop_timer') : $t('start_timer') }}
        </ion-button>
      </template>

      <template v-slot:chip v-if="timeIsRunning">
        <div style="margin-right: 8px">
          <svg height="10" width="10" class="blinking">
            <circle cx="5" cy="5" r="5" fill="red" />
          </svg>
        </div>
        {{ `${time.hours}h ${time.minutes}m ${time.seconds}s` }}
      </template>
      <span class="ion-text-wrap">#{{ active.id }} {{ active?.nombre }}</span>
      <span v-if="active.numeroserie">{{ active.numeroserie }}</span>
    </AppToolbar>

    <ion-content :fullscreen="true" v-if="active">
      <AssetData
        :asset="active"
        :activeDeepCopy="activeDeepCopy"
        v-model="editAsset"
        @setAsset="(ev) => isUserOnline() ? cambiarActivo(ev) : undefined"
        @setModelo="(ev) => isUserOnline() ? setModelo = ev : undefined"
        @mutateAsset="(ev) => setActiveMutation(ev)"
      />

      <ion-item
        lines="none"
        class="title"
        v-if="!+gmao.comportamientos.no_imagenes && active.imagenes?.length"
      >
        <h5>{{ $t('IMAGENES') }}</h5>
        <!-- <ion-button
          class="header-icon ion-no-margin"
          size="small"
          fill="clear"
          color="tertiary"
          @click="addPhotoActionSheet()"
          :disabled="active?.not_editable"
          slot="end"
        >
          <ion-icon :icon="add" style="margin-right: 4px" />
          <span>{{ $t('Añadir') }}</span>
        </ion-button> -->
      </ion-item>

      <ion-card
        style="box-shadow: none; border-radius: 15px"
        class="bloque"
        v-if="!+gmao.comportamientos.no_imagenes && active.imagenes?.length"
      >
        <ion-card-content class="ion-no-padding">
          <ion-item lines="none" v-if="!active?.imagenes?.length"
            >{{ $t('Sin imágenes') }}
          </ion-item>
          <ion-grid v-if="active?.imagenes?.length">
            <ion-row class="ion-nowrap" style="overflow-x: auto">
              <ion-col
                size="5"
                v-for="(photo) in active.imagenes"
                :key="`imagen-${photo.id}`"
              >
                <img
                  v-if="photo.src"
                  :src="photo.src"
                  @click="imageViewer(photo)"
                  style="object-fit: cover; width: 100%; height: 100%"
                />
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>

      <ion-item
        lines="none"
        class="title"
        v-if="!+gmao.comportamientos.no_imagenes"
      >
        <h5>{{ $t('IMAGENES PARTE ACTIVO') }}</h5>
        <ion-button
          class="header-icon ion-no-margin"
          size="small"
          fill="clear"
          color="tertiary"
          @click="addPhotoActionSheet()"
          :disabled="active?.not_editable"
          slot="end"
        >
          <ion-icon :icon="add" style="margin-right: 4px" />
          <span>{{ $t('Añadir') }}</span>
        </ion-button>
      </ion-item>

      <ion-card
        style="box-shadow: none; border-radius: 15px"
        class="bloque"
        v-if="!+gmao.comportamientos.no_imagenes"
      >
        <ion-card-content class="ion-no-padding">
          <ion-item lines="none" v-if="!woActiveImages?.length"
            >{{ $t('Sin imágenes') }}
          </ion-item>
          <ion-grid v-else>
            <ion-row class="ion-nowrap" style="overflow-x: auto">
              <ion-col
                size="5"
                v-for="(photo) in woActiveImages"
                :key="`imagen-${photo.id}`"
              >
                <div>
                  <img
                    v-if="photo.src"
                    :src="photo.src"
                    @click="imageViewer(photo)"
                    style="object-fit: cover; width: 100%; height: 100%"
                  />
                  <ion-buttons class="removeBtn">
                    <ion-button
                      @click="alertPopup($t('Estas-seguro-que-quieres-borrar-esta-imagen'), () => deleteMedia(photo.id, null))"
                      :disabled="active?.not_editable"
                    >
                      <ion-icon style="color: var(--ion-color-danger);" class="f20" :icon="closeCircle"></ion-icon>
                    </ion-button>
                  </ion-buttons>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>

      <!-- SELECTOR CHECKLIST -->
      <ion-item class="title" lines="none" v-if="+gmao.comportamientos.tecnico_puede_cambiar_checklist">
        <h5>{{ $t('Selector de checklist') }}</h5>
      </ion-item>

      <ion-item class="title" lines="none" v-if="active?.modelo_parte_maquina?.nombre?.length > 26"  style="font-size:small">
        <span >{{ active?.modelo_parte_maquina?.codigo }} — {{ active?.modelo_parte_maquina?.nombre }} </span>
      </ion-item>

      <ion-card
        style="box-shadow: none; border-radius: 15px"
        class="bloque"
        v-if="+gmao.comportamientos.tecnico_puede_cambiar_checklist"
      >
        <ion-card-content class="ion-no-padding">
          <ion-item :disabled="active.not_editable" lines="none">
            <ion-input
              :value="active?.modelo_parte_maquina ? `${active?.modelo_parte_maquina?.codigo} — ${active?.modelo_parte_maquina?.nombre}` : null"
              readonly
              :placeholder="$t('Selecciona un checklist')"
              @click="() => { if(isUserOnline()) {setActiveChecklist = true; applyFiltersChecklists(true)}}"
            />
          </ion-item>
        </ion-card-content>
      </ion-card>

      <template v-if="workorderActive.tipo_letra == 'L'">
        <ion-card
          v-if="active.lecturas?.length > 0"
          style="box-shadow: none; border-radius: 15px"
        >
          <!-- <div class="hr" style="margin: 0;" /> -->

          <ion-card-content class="ion-no-padding">
            <ion-list class="ion-text-wrap">
              <CheckField
                v-for="field in active.lecturas"
                :key="`check-field-${field.id}`"
                v-model="field.lectura"
                v-model:lecturaObservaciones="field.observaciones"
                :labelLectura="`${$t('Lectura')} (${field.componente_maquina?.unidades || field.componente_modelo?.unidades})`"
                type="LECTURA"
                :label="field.componente_maquina?.nombre || field.componente_modelo?.nombre"
                :not_editable="active?.not_editable"
                @changedValue="async (ev) => await setUpdateParteMaquinaChecklist(ev, field)"
                @changedValue:lecturaObservaciones="async (ev) => await setUpdateParteMaquinaChecklist(ev, field, {observaciones: field.observaciones})"
              />
            </ion-list>
          </ion-card-content>
        </ion-card>

      </template>

      <template v-else>
          <!-- <ion-item v-if="!!+gmao.comportamientos.duplicar_no_finalizados_preventivos" class="title" lines="none"> -->
          <ion-item class="title" lines="none">
            <ion-label>
              <h5>{{ $t('CHECKS') }}  {{ active?.modelo_parte_maquina?.codigo }} — {{ active?.modelo_parte_maquina?.nombre }}</h5>
              <ion-text style="font-size: 10px; margin-right: 8px; max-width: 65%">
                {{ $t('Marcar activo como terminado.') }}
              </ion-text>
            </ion-label>
            
            <ion-checkbox
              :disabled="active?.not_editable"
              v-model="active.finalizado"
              @ionChange="toggleFinished"
            >
            </ion-checkbox>
          </ion-item>

          <ion-card :disabled="active?.not_editable" style="box-shadow: none; border-radius: 15px">
            <template v-if="offline.status">
              <ion-item lines="none">
                <ion-text
                  class="offline-warning">
                  {{ $t('offline-generic-module-not-available-msg') }}
                </ion-text>
              </ion-item>
            </template>
            <template v-else>
              <ion-card-content class="ion-no-padding">
                <template v-if="+gmao.comportamientos.activo_vandalizado && active?.parte_maquina">
                  <ion-item>
                    <ion-select
                      v-model="active.parte_maquina.vandalized"
                      :label="$t('Vandalizado')"
                      label-placement="floating"
                      @ionChange="toggleVandalized"
                    >
                      <ion-select-option :value="null">- {{ $t('Sin especificar') }} -</ion-select-option>
                      <ion-select-option :value="1">{{ $t('Si') }}</ion-select-option>
                      <ion-select-option :value="0">{{ $t('No') }}</ion-select-option>
                    </ion-select>
                  </ion-item>
                </template>
              </ion-card-content>
            </template>
          </ion-card>

          <ion-item class="title" lines="none" v-if="active.respuestas?.length > 0">
            <h5>{{ $t('CHECKLIST') }}</h5>
            <ion-button
              v-if="!+gmao.comportamientos.ocultar_finalizar_el_checklist"
              :disabled="active?.not_editable"
              class="header-icon ion-no-margin"
              size="small"
              fill="clear"
              color="tertiary"
              @click="checkAllFields"
              slot="end"
            >
              <ion-icon :icon="checkmarkOutline" style="margin-right: 4px" />
              <span>{{ $t('marcar-todos') }}</span>
            </ion-button>

          </ion-item>
          <ion-card
            v-if="active.respuestas?.length > 0"
            style="box-shadow: none; border-radius: 15px"
          >
            <ion-card-content class="ion-no-padding">
              <ion-list lines="inset" class="ion-text-wrap">
                <template
                  v-for="field in active.respuestas"
                  :key="`check-field-${field.id}`"
                >
                  <CheckField
                    v-if="field?.campo"
                    :id="field.id"
                    v-model="field.valor"
                    :type="field.campo.tipo"
                    :label="field.label_campo"
                    :options="field?.campo?.predefinido?.opciones || []"
                    :not_editable="active?.not_editable"
                    :src="field?.foto"
                    :foto="field.campo.predefinido?.foto"
                    @openImageModal="(ev) => isUserOnline() ? imageViewer(field, ev) : undefined"
                    @changedValue="async (ev) => await setUpdateParteMaquinaChecklist(ev, field)"
                  />
                </template>
              </ion-list>
            </ion-card-content>
          </ion-card>

          <template v-if="!+gmao.comportamientos?.no_activo_anomalias">
            <ion-item class="title" lines="none">
              <h5>{{ $t('ANOMALIAS') }}</h5>
            </ion-item>
  
            <ion-card style="box-shadow: none; border-radius: 15px">
              <ion-card-content class="ion-no-padding">
                <ion-grid>
                  <ion-row>
                    <ion-col size="12">
                      <ion-textarea
                        :value="active.anomalia?.anomalias"
                        class="gmao-textarea"
                        :disabled="active?.not_editable"
                        :placeholder="$t('Escribe algo aquí...')"
                        @ionBlur="setAnomaly($event.target.value)"
                      ></ion-textarea>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </template>

          <ion-item
            class="title"
            lines="none"
            v-if="
              +gmao.modulos.modulo_faq && +gmao.comportamientos.faq_sobre_activos
            "
          >
            <h5>{{ $t('PREGUNTAS FRECUENTES') }}</h5>
          </ion-item>

          <ion-card
            :disabled="active?.not_editable"
            style="box-shadow: none; border-radius: 15px"
            v-if="
              +gmao.modulos.modulo_faq && +gmao.comportamientos.faq_sobre_activos
            "
          >
            <ion-card-content class="ion-no-padding">
              <ion-item
                v-if="!+gmao.comportamientos.faq_solo_respuestas"
                lines="none"
              >
                <ion-select
                  v-if="(questions || this.offline.questions).length"
                  @ionChange="getReplies($event.target.value, true)"
                  v-model="active.id_pregunta"
                >
                  <ion-select-option :value="null">
                    – {{ $t('Selecciona un problema') }} –
                  </ion-select-option>

                  <ion-select-option
                    :value="question.id"
                    v-for="question in (questions || this.offline.questions)"
                    :key="`question-${question.id}`"
                  >
                    {{ question.texto }}
                  </ion-select-option>
                </ion-select>
                <ion-text v-else>
                  {{$t('No hay problemas')}}
                </ion-text>
              </ion-item>

              <ion-item lines="none">
                <ion-textarea
                  readonly
                  :value="checkedReplies.map(a => a.texto).toString()"
                  :placeholder="$t('Selecciona una solución')"
                  @click="setReplies = true"
                  :autoGrow="true"
                />
              </ion-item>
            </ion-card-content>
          </ion-card>

          <ion-item lines="none" class="title" v-if="active?.sub_maquinas?.length">
            <h5>{{ $t('ACTIVOS HIJOS') }}</h5>
          </ion-item>

          <ion-card
            style="box-shadow: none; border-radius: 15px"
            lines="none"
            class="bloque"
            v-if="active?.sub_maquinas?.length"
          >
            <ion-card-content class="ion-no-padding">
              <ion-list lines="none">
                <ion-item-sliding
                  v-for="activo in active?.sub_maquinas"
                  :key="`active-${activo.id}`"
                  :disabled="active.not_editable"
                >
                  <ion-item
                    :class="[
                      `priority-${activo.prioridad}`,
                      {
                        'active-finished':
                          activo.finalizado || activo?.pivot?.tareas_completadas,
                      },
                      'activosOT',
                    ]"
                    @click="
                      $router.push({
                        name: 'item',
                        params: { asset: activo.id, workorder: $route.params.workorder, sistema: $route.params.sistema },
                      })
                    "
                  >
                    <ion-label>
                      <h3 class="wrap-text">
                        {{ activo.nombre }} {{ activo?.modelo.modelo }}
                        {{ activo?.modelo.marca }}
                      </h3>
                      <p class="wrap-text">{{ activo.numeroserie }}</p>
                      <p class="wrap-text">{{ activo.ubicacion }}</p>
                      <p class="wrap-text">{{ activo.observaciones }}</p>
                      <!-- <p class="wrap-text">{{ p.problema }}</p> -->
                    </ion-label>

                    <ion-buttons slot="end">
                      <ion-icon :icon="chevronForward" class="f20"></ion-icon>
                    </ion-buttons>
                  </ion-item>

                  <ion-item-options side="end">
                    <ion-item-option
                      @click="isUserOnline() ? deleteWorkorderActive(activo) : undefined"
                      color="danger"
                    >
                      <ion-icon slot="start" :icon="closeOutline"></ion-icon>
                      {{ $t('Eliminar') }}
                    </ion-item-option>
                  </ion-item-options>
                </ion-item-sliding>

                <ion-button
                  v-if="active.sub_maquinas_count > 5"
                  class="ion-no-margin"
                  style="display:flex;"
                  size="small"
                  fill="clear"
                  color="tertiary"
                  @click="$router.push({name: 'wo-assets', params: { workorder: $route.params.workorder, asset: $route.params.asset, sistema: $route.params.sistema} })"
                >
                  <span>{{ $t('Ver más') }}</span>
                </ion-button>
              </ion-list>
            </ion-card-content>
          </ion-card>

      </template>

    <!-- SELECTOR CHECKLIST -->
    <template v-if="active?.parte_maquina && !!+gmao.comportamientos.tiempo_parada_activo">
      <ion-item class="title" lines="none">
        <h5>{{ $t('Improductividad') }}</h5>
      </ion-item>

      <ion-card
        style="box-shadow: none; border-radius: 15px"
        class="bloque"
        v-if="active"
      >
        <template v-if="offline.status">
          <ion-item lines="none">
            <ion-text
              class="offline-warning">
              {{ $t('offline-generic-module-not-available-msg') }}
            </ion-text>
          </ion-item>
        </template>
        <template v-else>
          <ion-card-content class="ion-no-padding">
            <ion-item>
              <ion-label id="open-modal" position="stacked">{{ $t('Tiempo improductivo') }} (HH:mm)</ion-label>
              <ion-input
                v-model="active.parte_maquina.t_improductivo"
                clear-input
                type="time"
                step="2"
                @ionChange="setImproductividad"
              >
              </ion-input>
            </ion-item>
          </ion-card-content>
        </template>
      </ion-card>
    </template>

    <!-- Nuevo funcionamiento de la vista activo... -->
    <!-- <ion-footer collapse="fade" style="bottom: 1em">
      <ion-button @click="saveBack" expand="block" :disabled="active?.not_editable">
        {{ $t('guardar-y-volver') }}
      </ion-button>
    </ion-footer> -->
    </ion-content>

    <ion-modal :is-open="setArticles" :initialBreakpoint="0.95" @didDismiss="setArticles = false">
      <ion-header >
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="setArticles = false">{{
              $t('Cancelar')
            }}</ion-button>
          </ion-buttons>

          <ion-title lines="none">{{ $t('Establecer materiales') }}</ion-title>

          <ion-buttons slot="end">
            <ion-button :disabled="active?.not_editable" @click="setFaqArticles">{{ $t('Guardar') }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content  style="height:calc(100% - 80px)" class="ion-padding" v-if="articles.length">
        <ion-list lines="none">
          <template
            v-for="material in articles"
            :key="`material-${material.id}`"
          >
          <template v-if="material?.articulo?.almacenes?.length">
            <ion-item v-if="material?.articulo?.almacenes?.length">
              <ion-checkbox slot="start" v-model="material.active">
              </ion-checkbox>

              <ion-label>
                <h3>{{ material.articulo?.nombre }}</h3>
              </ion-label>
            </ion-item>

            <ion-item>
              <ion-label>{{ $t('Cantidad') }}</ion-label>
              <ion-input
                type="tel"
                step="0.01"
                v-model="material.cantidad"
              ></ion-input>
            </ion-item>

            <ion-item>
              <ion-label>{{ $t('Almacén') }}</ion-label>
              <ion-select
                v-if="material.articulo?.almacenes"
                v-model="material.almacen"
              >
                <ion-select-option
                  :value="storage.id"
                  v-for="storage in material.articulo.almacenes"
                  :key="`storage-${storage.id}`"
                >
                  {{ storage.nombre }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </template>

            <div class="hr" />
          </template>
        </ion-list>
      </ion-content>

    </ion-modal>

    <MSetImageModal
      v-model="imageModal"
      :data="activeImagesCollection || []"
      :index="clickedIndex"
      :is_offline="!offline.status"
    />

    <!-- Edit Modelo de Maquina -->
    <ion-modal
      :is-open="setModelo"
      :initialBreakpoint="0.95"
      @didDismiss="setModelo = false"
    >
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="setModelo = false">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ $t('Selecciona el modelo') }}</ion-title>
          <!-- <ion-buttons slot="end">
            <ion-button @click="setWorkorderActives">{{ $t('Añadir') }}</ion-button>
          </ion-buttons> -->
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <ion-searchbar
          :placeholder="$t('Buscar modelos...')"
          v-model="activeSearchModel"
          @ionInput="applyFiltersModelos(true)"
          :debounce='500'
        />

        <ion-list style="border-radius: 15px">
          <template v-if="items.flat().length">
            <ion-item v-for="(modelo, key) in items.flat()" :key="`modal-modelo-${key}`" @click="setActivoModelo(modelo)">
              <ion-label>
                <p>{{ modelo.sistema?.nombre }}</p>
                <h3>
                  {{ modelo.marca || `#${modelo.id}` }}
                  {{ modelo.modelo }}
                </h3>
                <p>{{ modelo.descripcion }}</p>
              </ion-label>
            </ion-item>
          </template>

          <template v-else>
            <ion-item
              lines="none"
              style="margin: 0; color: var(--ion-color-primary)"
              @click="
                () => {
                  newModelo.marca = activeSearchModel;
                  setNewModelo = true;
                  setModelo = false;
                }
              "
            >
              <ion-icon slot="start" :icon="add"></ion-icon>

              <ion-label>
                <h3>Añadir "{{ activeSearchModel }}" a la lista de modelos</h3>
              </ion-label>
            </ion-item>
          </template>
        </ion-list>
        <ion-infinite-scroll
          threshold="100px"
          id="infinite-scroll"
          @ionInfinite="onLoadModelos($event)"
        >
          <ion-infinite-scroll-content
            loading-spinner="bubbles"
            :loading-text="$t('Cargando más modelos...')"
          >
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-content>
    </ion-modal>

    <ion-modal
      :is-open="setNewModelo"
      :initialBreakpoint="0.95"
      @didDismiss="setNewModelo = false"
    >
      <ion-header>
        <ion-toolbar>
          <ion-title>{{ $t('Nuevo modelo') }}</ion-title>

          <ion-buttons slot="start">
            <ion-button @click="setNewModelo = false">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>

          <ion-buttons slot="end">
            <ion-button @click="setActivoModelo(newModelo, true)">{{ $t('Añadir') }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <ion-list style="border-radius: 15px">
          <ion-item>
            <ion-label>{{ $t('Marca') }}</ion-label>
            <ion-input class="ion-text-right" v-model="newModelo.marca" />
          </ion-item>

          <ion-item>
            <ion-label>{{ $t('Modelo') }}</ion-label>
            <ion-input class="ion-text-right" v-model="newModelo.modelo" />
          </ion-item>

          <ion-item>
            <ion-label>{{ $t('Descripcion') }}</ion-label>
            <ion-input class="ion-text-right" v-model="newModelo.descripcion" />
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-modal>

    <ion-modal
      :is-open="setActiveChecklist"
      :initialBreakpoint="0.95"
      @didDismiss="setActiveChecklist = false"
    >
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="setActiveChecklist = false">{{
              $t('Cancelar')
            }}</ion-button>
          </ion-buttons>

          <ion-title>{{ $t('Seleccionar modelo') }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-searchbar
        :placeholder="$t('Buscar modelos...')"
        v-model="searchChecklist"
        class="defaultSearchBar"
        @ionInput="applyFiltersChecklists(true)"
        :debounce='500'
      />

      <ion-content class="ion-padding">
        <template v-if="availableChecklists?.length">
          <ion-list style="border-radius: 15px; margin-bottom: 100px">
            <ion-item
              v-for="(m, key) in availableChecklists.flat()"
              :key="`modalelo-${key}`"
              @click="changeActiveChecklist(m.id)"
            >
              <ion-label class="ion-text-wrap">
                <h3>
                  {{ `${m.codigo || '?'} — ${m.nombre || '?'}` }}
                </h3>
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-infinite-scroll
            threshold="100px"
            id="infinite-scroll"
            @ionInfinite="onLoadChecklists($event)"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              :loading-text="$t('Cargando más modelos...')"
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </template>
      </ion-content>
    </ion-modal>

    <MSetFinder
      v-model="setReplies"
      @done="(values) => setReply(values)"
      :data="replies || this.offline.replies"
      :content="{
        title: 'Selecciona una solución',
        empty_title: 'No hay soluciones',
        multiple: true,
      }"
      :structure="[
        {
          tag: 'h3',
          key: 'texto'
        }
      ]"
    />

  </ion-page>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';
import {
  chevronBack,
  stopwatchOutline,
  closeOutline,
  createOutline,
  imagesOutline,
  camera,
  add,
  closeCircle,
  closeCircleOutline,
  checkmarkCircleOutline,
  pencilOutline,
  informationCircleOutline,
  checkmarkOutline,
} from 'ionicons/icons';
import {
  IonContent,
  IonPage,
  IonSelect,
  IonButton,
  IonButtons,
  IonTitle,
  IonModal,
  IonList,
  IonIcon,
  IonItem,
  IonLabel,
  IonHeader,
  IonSelectOption,
  IonInput,
  IonCheckbox,
  alertController,
  toastController,
  actionSheetController,
  loadingController,
  IonSearchbar,
  IonTextarea,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
} from '@ionic/vue';

import AppToolbar from '@/components/AppToolbar.vue';
import CheckField from '@/components/CheckField.vue';
import AssetData from '@/components/AssetData.vue';

import MSetImageModal from '@/components/Workorder/MSetImageModal.vue';
import MSetFinder from '@/components/MSetFinder.vue';

import { useGmaoStore } from '@/stores/gmao';

import { useWorkOrdersStore } from '@/stores/workorders';

// SQLite
import { SQLiteDBConnection } from 'vue-sqlite-hook/dist';

import { useOfflineStore } from '@/stores/offline';

export default {
  name: 'MachinePage',

  components: {
    IonContent,
    IonPage,
    IonSelect,
    IonModal,
    IonLabel,
    IonList,
    IonButton,
    IonItem,
    IonTitle,
    IonHeader,
    IonIcon,
    IonButtons,
    IonSelectOption,
    IonInput,
    IonCheckbox,
    AppToolbar,
    CheckField,
    IonSearchbar,
    IonTextarea,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    MSetImageModal,
    MSetFinder,

    AssetData,
  },

  computed: {
    timeIsRunning() {
      return (
        this.workOrders?.activeTimeRegister?.from &&
        this.workOrders?.activeTimeRegister?.active === this.active.id &&
        this.workOrders?.activeTimeRegister?.workorder ===
          this.$route.params.workorder
      );
    },
    esTipoLectura() {
      return this.workorderActive.tipo_letra == 'L';
    },
    checkedReplies() {
      return this.replies.filter((r) => this.active?.maquinas_respuestas
      ?.map(a => a.id_respuesta)
        ?.includes(r.id))
          .map(a => {
            return {id:a.id, texto: a.texto}
          });
    }
  },

  async created() {
    this.workorderActive = this.offline.workorder || this.workOrders.getWorkorder(this.$route.params.workorder);

    if (!this.workorderActive && !this.offline.status) {
      await this.getWorkorder(this.$route.params?.workorder).then((data) => {
        this.workorderActive = data;
      });
    } else {
      this.workorderActive = this.offline.workorder;
    }

    if (this.workorderActive) {
      await this.getActive();

      console.log(this.active);
      console.log(this.workorderActive);

      if (this.workorderActive.not_editable) {
        this.active.not_editable = this.workorderActive?.not_editable;
      }

      // IMAGE COLLECTION
      this.woActiveImages = this.workorderActive?.imagenes?.filter((i) => i.id_maquina == this.$route.params.asset) || [];

      this.activeImagesCollection.push(...this.active?.imagenes || []);
      this.activeImagesCollection.push(...this.woActiveImages);


      // NOTE: Check if Asset needs to upload photo - vandalized
      this.setAssetVandalized();


      // CHECK IF TIME IS TRACKING
      if (this.timeIsRunning) {
        this.timeTracking = setInterval(this.elapsedTime.bind(this), 1000);
      }

      // FAQ
      const faq = (this.active?.maquinas_respuestas || [])?.filter(
        (mr) =>
          mr.id_parte == this.$route.params.workorder &&
          mr.id_maquina == this.$route.params.asset
      );
      if (faq?.length) {
        this.active.id_pregunta = faq[0].id_pregunta;
        this.active.id_respuesta = faq?.map((f) => f.id_respuesta);

        this.getReplies(this.active.id_pregunta);
      }

      if (+this.gmao.comportamientos.faq_solo_respuestas) {
        this.getReplies(1);
      } else {
        this.getQuestions();
      }

    } else {
      this.$router.replace({ name: 'workorders' });
    }
  },

  watch: {
    setArticles(new_value) {
      if (!new_value) {
        this.articles = [];
      }
    },

    'active.respuestas': {
      handler(value, old) {
        if (old) this.checksChanged = true;
      },

      deep: true,
    },

    'active.lecturas': {
      handler(value, old) {
        if (old) this.checksChanged = true;
      },

      deep: true,
    },

    'workOrders.activeTimeRegister': {
      handler(value) {
        if (value) {
          this.timeTracking = setInterval(this.elapsedTime.bind(this), 1000);
        } else {
          clearInterval(this.timeTracking);
        }
      },
      deep: true,
    },
  },

  setup() {
    const gmao = useGmaoStore();
    const workOrders = useWorkOrdersStore();

    const offline = useOfflineStore();
    const app = getCurrentInstance();

    return {
      // STORES
      gmao,
      workOrders,
      offline,

      // APP Instances
      app,

      // ICONS
      chevronBack,
      stopwatchOutline,
      closeOutline,
      createOutline,
      imagesOutline,
      camera,
      add,
      closeCircle,
      checkmarkCircleOutline,
      closeCircleOutline,
      pencilOutline,
      informationCircleOutline,
      checkmarkOutline,

      // VARS
      editAsset: ref(false),
      active: ref({}),
      workorderActive: ref({}),
      activeDeepCopy: ref({}),
      questions: ref([]),
      replies: ref([]),
      activeImagesCollection: ref([]),
      setArticles: ref(false),
      selectedReplies: ref([]),
      woActiveImages: ref([]),
      articles: ref([]),
      checksChanged: ref(false),
      cancelToken: ref(null),
      timeTracking: ref(null),
      imageModal: ref(false),
      imageModalOption: ref(false),
      setMaquinaModelo: ref(false),
      clickedIndex: ref(),
      clickedImageId: ref(),
      time: ref({
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),

      // Modelo
      setModelo: ref(false),
      setNewModelo: ref(false),
      items: ref([]),
      current_page: ref(1),
      last_page: ref(null),
      total: ref(null),
      activeSearchModel: ref(null),
      newModelo: ref({}),

      setActiveChecklist: ref(false),
      searchChecklist: ref(null),
      availableChecklists: ref([]),

      setReplies: ref(false),
    };
  },

  methods: {
    isUserOnline() {
      if (this.offline.status) {
        this.$openToastObject(
          this.$t('offline-generic-action-not-available-msg'),
          this.$t('offline-generic-action-not-available-msg-guard'),
          'danger'
        );
        return false;
      }

      return true;
    },

    setAssetVandalized() {
      if (!!this.active?.parte_maquina?.vandalized && !this.woActiveImages?.length) {
        this.$openToastObject(this.$t('asset_vandalized'), this.$t('asset_vandalized_msg'), 'secondary');
            
        this.addPhotoActionSheet(undefined, undefined, true);
      }
    },

    async toggleVandalized() {
      const value = this.active?.parte_maquina.vandalized;
      
      await this.$axios
        .post(
          `/v2/users/actions.php?call=setChecks&token=${this.gmao.user.token}&v2=1`,
          {
            data: {
              parte_id: this.$route.params.workorder || this.offline?.workorder?.id,
              maquina_id: this.active.id,
              vandalized: typeof value == 'number' ? +value : (value || ''),
            }
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )
        .then(async () => {
          await this.getActive();
          this.setAssetVandalized();
        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            // *NEW — XXX: [GET - getIsWorkingDay] NOT IN OFFLINE
            if (this.offline.status) {
              this.$openToastObject(
                this.$t('offline-generic-action-not-available-msg'),
                this.$t('offline-generic-action-not-available-msg-guard'),
                'danger'
              );
              return false;
            }
          }
        });
    },

    setActiveMutation(event) {
      const { field, value } = event;

      if (!field.includes('campo')) this.activeDeepCopy[field] = value;
      else {
        const ob = this.activeDeepCopy.campos.find((a) => a.id == field.split('-')[1]);
        if (ob) ob.descripcion = value;
      }

    },

    changeActiveChecklist(id_modelo) {

      this.alertPopup(
        this.$t(
          'Vas a cambiar el checklist ¿Estás seguro?'),
        async () => {
          const formData = new FormData();
          formData.append('id_parte', this.$route.params.workorder || '');
          formData.append('id_maquina', this.active?.id || '');
          formData.append('id_modelo_parte', id_modelo || '');
          const loading = await loadingController.create({
            message: this.$t('Cargando...'),
          });

          loading.present();
          this.$axios
            .post(`/v2/users/actions.php?call=setNewAssetChecklist&token=${this.gmao.user.token}&v2=1`, formData)
            .then(async () => {
              await this.getWorkorderActive(
                this.workorderActive?.id || this.$route.params?.workorder,
                this.$route.params.asset,
                this.$route.params.sistema,
              );
              this.setActiveChecklist = false;
            }).catch(() => {
              // TODO: offline
            }).finally(() => loading.dismiss());
        },
        { cancel: 'cancelar', confirm: 'confirmar' }
      );
    },

    onLoadChecklists(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFiltersChecklists(false).then(() => {
          event.target.complete();
        });
      } else event.target.complete();
    },

    applyFiltersChecklists(reset = true) {
      if (reset) {
        this.availableChecklists = [];
        this.current_page = 1;
      }
      return this.getAvailableChecklist();
    },

    getAvailableChecklist() {
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getAvailableChecklist',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            search: this.searchChecklist,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.availableChecklists.push(data.data);

          this.current_page = data.current_page;
          this.last_page = data.last_page;
          this.total = data.total;
        }).catch((error) => {
          // TODO: Offline
          console.log(error);
      });
    },

    deleteWorkorderActive(active) {
      this.alertPopup(
        this.$tc(
          'seguro-que-quieres-quitar-el-activo-active-nombre-del-parte-this-workorder-id',
          [active.nombre, this.$route.params.workorder]
        ),
        () => {
          this.$axios
            .post(
              `/v2/users/actions.php?call=setDeleteWorkorderActive&token=${this.gmao.user.token}&v2=1`,
              {
                data: {
                  id_parte: this.$route.params.workorder,
                  id_maquina: active.id,
                }
              },
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              }
            )
            .then(() => {
              const index = this.active.sub_maquinas.findIndex((m) => m.id == active.id);
              this.active.sub_maquinas.splice(index, 1);
            })
            .catch(async (e) => {
              if (e.code == 'ERR_NETWORK' && this.offline.status) {
                // TODO: offline
                // try {
                //   const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

                //   const ret = await sqlite.checkConnectionsConsistency();
                //   const isConn = (await sqlite.isConnection('gmaoTecnicos', false))
                //     .result;

                //   let db = new SQLiteDBConnection();
                //   if (ret.result && isConn) {
                //     db = await sqlite.retrieveConnection('gmaoTecnicos', false);
                //   } else {
                //     db = await sqlite.createConnection(
                //       'gmaoTecnicos',
                //       false,
                //       'no-encryption',
                //       1,
                //       false
                //     );
                //   }
                //   await db.open();
                //   const syncObject = {
                //     callFunction: 'setDeleteWorkorderActive',
                //     data: {
                //       id_parte: this.$route.params.workorder,
                //       id_maquina: active.id,
                //     },
                //   };
                //   const query = `
                //     UPDATE parte_maquinas
                //     SET
                //       deleted_at='${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                //       last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                //       syncObject='${JSON.stringify(syncObject)}'
                //     WHERE id_parte=${this.$route.params.workorder} AND id_maquina=${active.id}
                //   `;
                //   const deleteWorkorderActivePromise = new Promise((resolve) => {
                //     resolve(
                //       this.$SQLiteQuery(
                //         'deleteWorkorderActive_workorder',
                //         'parte_maquinas',
                //         query,
                //         e.config,
                //         db
                //       )
                //     );
                //   });
                //   deleteWorkorderActivePromise.then(() => {
                //     setTimeout(() => {
                //       // this.workorder.maquinas = data.activos;
                //       this.setActivos = false;
                //       sqlite.closeConnection('gmaoTecnicos').then(() => {
                //         this.getMaquinas();
                //       });
                //     }, 500);
                //   });
                //   // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
                // } catch (error) {
                //   throw Error('DEL WO Active: ', error);
                // }
              }
            });
        },
        { cancel: 'cancelar', confirm: 'quitar' }
      );
    },

    async setUpdateParteMaquinaChecklist(value, fieldObject, fieldValueObject = null) {

      /** FOTO OBLIGATORIA
       * Si el campo tiene la foto obligatoria, lo comprobamos de nuevo
       */
      if (!value && +fieldObject?.campo?.predefinido?.foto) {
        if (!this.isUserOnline()) return;
        await this.addPhotoActionSheet(true, fieldObject);
        if (!fieldObject?.foto) this.$openToastObject(this.$t('Este item requiere añadir una foto'), this.$t('Recuerde que es obligatoria la subida de la imagen a este item.'), 'danger');

        return;
      }

      const formData = new FormData();
      let valueFormatted = value+'' || '';
      if (valueFormatted === 'null') valueFormatted = '';

      formData.append('tipo_letra', this.workorderActive?.tipo_letra || '');
      formData.append('parte_maquina', this.active?.parte_maquina?.id || '');

      if (fieldValueObject) {
        formData.append('id', fieldObject?.id || '');
        formData.append('field', Object.keys(fieldValueObject).at(0) || '');
        formData.append('valor', fieldValueObject[Object.keys(fieldValueObject).at(0)]+'' || '');
      } else {
        formData.append('id', fieldObject?.id || '');
        formData.append('valor', valueFormatted);
      }

      this.$axios
        .post(`/v2/users/actions.php?call=setUpdateParteMaquinaChecklist&token=${this.gmao.user.token}&v2=1`, formData)
        .then(() => {
          // TODO: Mestramos al usuario algun mensaje?
          // console.log('Enviado: ', data);
        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();

              const syncObject = {
                callFunction: 'setUpdateParteMaquinaChecklist',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: {
                  tipo_letra: this.workorderActive?.tipo_letra,
                  parte_maquina: this.active?.parte_maquina?.id,
                  id: fieldObject?.id,
                  field: fieldValueObject && Object.keys(fieldValueObject).at(0) || null,
                  valor: fieldValueObject && (fieldValueObject[Object.keys(fieldValueObject).at(0)]+'') || valueFormatted,
                }
              }

              let value = null;
              if (fieldValueObject) value = fieldValueObject[Object.keys(fieldValueObject).at(0)]+'';
              else value = valueFormatted;

              const query = `
                  UPDATE modelos_parte_campos_respuestas
                  SET valor         = '${value}',
                      syncObject    = '${JSON.stringify(syncObject)}',
                      last_modified = '${this.$moment()}'
                  WHERE id=${fieldObject?.id}
                `;

              const storeTimesPromise = new Promise((resolve) => {
                  resolve(this.$SQLiteQuery('setUpdateParteMaquinaChecklist', 'modelos_parte_campos_respuestas', query, e.config, db));
              });
              storeTimesPromise.then(() => {
                setTimeout(() => {
                  sqlite.closeConnection('gmaoTecnicos');
                }, 500);
              });
              // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
            } catch (error) {
              console.error(error);
              throw Error('setUpdateParteMaquinaChecklist: ', error);
            }
          }
        });
    },

    // async addPhotoModeloCampo(field) {
    //   const photo = await this.$Camera.getPhoto({
    //     resultType: this.$CameraResultType.Base64,
    //     source: this.$CameraSource.Camera,
    //     quality: 100,
    //     saveToGallery: true,
    //   });

    //   if (photo) {
    //     this.uploadModeloCampoPhoto(photo, field);
    //   }
    // },

    async uploadModeloCampoPhoto(photo , field) {
      if (Array.isArray(photo) && photo.length) {
        photo = photo[0];
        photo.base64String = photo.base64String || (await this.$getBase64Image(photo.webPath).then((data) => data.split`,`[1]));
      }

      const formData = new FormData();
      if (!photo) { // Actualizamos el campo "foto" a null
        formData.append('id_modelo_parte_compo_respuesta', field?.id || '');
        formData.append('file', null);
      } else { // Subimos foto
        let blob = null;
        if (photo.webPath) {
          blob = await new Promise((resolve, reject) => {
            this.$compressImage(this.$base64toBlob(photo.base64String), resolve, reject);
          });
        } else {
          blob = this.$base64toBlob(photo.base64String);
        }

        formData.append('id_modelo_parte_compo_respuesta', field?.id || '');
        formData.append('valor', field?.valor || '');
        formData.append('file', blob);
      }

      return this.$axios
        .post(
          `/v2/users/actions.php?call=setImageModeloCampoRespuesta&token=${this.gmao.user.token}&v2=1`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )
        .then(({ data }) => {
          /**
           * ** *Comentamos esta linea porque está borrando los valores NO guardados de los checklists
           * FIXME: +* *Quick-fix => recoger la url de la imagen subida y asociarla al objeto en local
           */
          // this.getWorkorderActive(this.$route.params?.workorder, this.$route.params.asset, this.$route.params?.sistema);

          if (data.datos?.foto?.length) {
            field.foto = data.datos?.foto;
            this.openToastOptions(
              this.$t('imagen-subida-con-exito'),
              this.$t('la-imagen-se-ha-subido-correctamente'),
              'success'
            );
          } else if (!data.datos?.foto) {
            field.foto = null;
            this.openToastOptions(
              this.$t('imagen-borrada-con-exito'),
              this.$t('la-imagen-se-ha-borrado-correctamente'),
              'success'
            );
          } else {
            this.openToastOptions(
              this.$t('error-al-subir-la-imagen'),
              this.$t(
                'el-servidor-no-ha-podido-procesar-la-subida-de-imagen-por-favor-intentalo-mas-tarde'
              )
            );
          }
          return data;
        }).catch(() => {
          // TODO:
        });
    },

    getWorkorderActive(wo, asset, sistema) {
      const syncObject = {
        callFunction: 'getParteMaquina',
        data: {
          workorder: wo,
          asset,
          sistema,
        }
      }

      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getParteMaquina',
            token: this.gmao.user.token,
            workorder: wo,
            asset,
            sistema,
            v2: 1,
          },
        })
        .then(({ data }) => {
          if (data) {
            this.active = data;
            this.active.finalizado = !!data?.parte_maquina?.tareas_completadas;
            return data;
          }

          this.$router.go(-1);
        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();

              const query = `
                  SELECT *
                  FROM maquinas
                  WHERE id=${syncObject.data.asset}
              `;
              const getParteMaquinaPromise = new Promise((resolve) => {
                  resolve(this.$SQLiteQuery('getParteMaquina_item', 'maquinas', [query, { wo, sistema, asset }], e.config, db));
              });
              getParteMaquinaPromise.then(() => {
                setTimeout(() => {
                  const maquina = this.offline.item_asset;
                  console.log('Maquina:: ', maquina);
                  
                  this.active = maquina;
                  this.active.finalizado = !!maquina?.tareas_completadas;
                  return maquina;

                }, 500);
              });
            } catch (error) {
              console.error(error);
              
              throw Error('getParteMaquina!!: ', error);
            }
          }
        });
    },

    prepareAssetEdit() {
      this.activeDeepCopy = structuredClone(this.active);
      this.activeDeepCopy.campos = structuredClone(this.activeDeepCopy.campos)
      this.editAsset = true;
      this.getModelos();
    },

    onLoadModelos(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFiltersModelos(false).then(() => {
          event.target.complete();
        });
      } else event.target.complete();
    },

    applyFiltersModelos(reset = true) {
      if (reset) {
        this.items = [];
        // this.wo.workorders = [];
        this.current_page = 1;
      }
      return this.getModelos(this.current_page);
    },

    setActivoModelo(modelo, newModelo = false) {
      const formData = this.$objectToFormData(modelo);
      if (newModelo) {
        this.$axios
          .post(
            `/v2/users/actions.php?call=setModelo&token=${this.gmao.user.token}&v2=1`,
            formData,
            {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }
          )
          .then(({ data }) => {
            if (data.datos) {
              this.activeDeepCopy.modelo = data.datos;
              this.activeDeepCopy.id_modelo = data.datos.id
              this.setNewModelo = false;
            }
          })

      } else this.activeDeepCopy.modelo = modelo; this.activeDeepCopy.id_modelo = modelo.id; this.setModelo = false;
    },

    getModelos() {
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getModelos',
            token: this.gmao.user.token,
            page: this.current_page,
            search: this.activeSearchModel,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.items.push(data.data);
          this.current_page = data.current_page;
          this.last_page = data.last_page;
          this.total = data.total;
        })
        .catch(async () => {
          // TODO:
          // if (e.code == 'ERR_NETWORK' && this.offline.status) {
          //   try {
          //     const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

          //     const ret = await sqlite.checkConnectionsConsistency();
          //     const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

          //     let db = new SQLiteDBConnection();
          //     if (ret.result && isConn) {
          //       db = await sqlite.retrieveConnection('gmaoTecnicos', false);
          //     } else {
          //       db = await sqlite.createConnection(
          //         'gmaoTecnicos',
          //         false,
          //         'no-encryption',
          //         1,
          //         false
          //       );
          //     }
          //     await db.open();
          //     // TODO: *** Add searchAsset: this.activeSearchModel,
          //     const query = `
          //       SELECT *
          //       FROM maquinas
          //     `;
          //     const getMaquinasPromise = new Promise((resolve) => {
          //       resolve(
          //         this.$SQLiteQuery(
          //           'getMaquinas_workorder',
          //           'maquinas',
          //           query,
          //           e.config,
          //           db
          //         )
          //       );
          //     });
          //     getMaquinasPromise.then(() => {
          //       setTimeout(() => {
          //         this.items = this.offline.assets;
          //         sqlite.closeConnection('gmaoTecnicos');
          //       }, 500);
          //     });
          //     // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
          //   } catch (error) {
          //     throw Error('GET Maquinas: ', error);
          //   }
          // }
        });
    },


    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        header,
        message,
        position: 'top',
        color,
        duration: '4000',
      });

      await toast.present();
    },

    async addPhotoActionSheet(modeloCampo = false, field = null, isVandalized = false) {
      if (modeloCampo && !(+field?.campo?.predefinido?.foto && !field?.foto)) return;
      try {
        const actionLinks = [];

        actionLinks.push({
          text: this.$t('gallery-photo'),
          icon: imagesOutline,
          handler: async () => {
            const photos = await this.$Camera.pickImages();
            if (photos && !modeloCampo) await this.uploadMultiplePhotos(photos.photos);
            else await this.uploadModeloCampoPhoto(photos.photos, field);
          },
        });

        actionLinks.push({
          text: this.$t('camera-photo'),
          icon: camera,
          handler: async () => {
            const photo = await this.$Camera.getPhoto({
              resultType: this.$CameraResultType.Base64,
              source: this.$CameraSource.Camera,
              quality: 100,
              saveToGallery: true,
            });

            if (photo && !modeloCampo) this.uploadPhoto(photo);
            else await this.uploadModeloCampoPhoto(photo, field);

          },
        });

        actionLinks.push({
          text: this.$t('cancelar'),
          role: 'cancel',
        });

        const actionSheet = await actionSheetController.create({
          header: this.$t('IMÁGENES'),
          buttons: actionLinks,
          backdropDismiss: !isVandalized
        });

        return new Promise((resolve) => {
          actionSheet.onDidDismiss().then(() => {
            resolve();
          });
          actionSheet.present();
        });
      } catch (error) {
        this.openToastOptions(
          this.$t('algo-no-ha-ido-bien'),
          this.$t('error-al-abrir-camara')
        );
        console.log(error);
      }
    },

    async uploadMultiplePhotos(photos) {
      const loading = await loadingController.create({
        message: this.$t('subiendo-imagenes')
      });

      loading.present();

      let imageUpload = null;
      let query = '';

      if (navigator.onLine) {
        const promises = photos.map(async (photo) => {
          const formData = new FormData();

          photo.base64String =
            photo.base64String ||
            (await this.$getBase64Image(photo.webPath).then((data) => data.split`,`[1]));

          const blob = await new Promise((resolve, reject) => {
            this.$compressImage(this.$base64toBlob(photo.base64String), resolve, reject);
          });

          formData.append('file', blob);
          formData.append('id_parte', this.$route.params.workorder || '');
          formData.append('id_maquina', this.$route.params.asset || '');

          imageUpload = await this.$axios
            .post(
              `/v2/users/actions.php?call=setImageWorkorder&token=${this.gmao.user.token}&v2=1`,
              formData,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            )
            .then(({ data }) => data)
            .catch(() => {
              this.openToastOptions(
                this.$t('error-al-subir-las-imagenes'),
                this.$t(
                  'el-servidor-no-ha-podido-procesar-la-subida-de-imagenes-por-favor-intentalo-mas-tarde'
                )
              );
            });

          return new Promise((resolve) => {
            resolve(imageUpload);
          });
        });

        Promise.all(promises)
          .then(() => {
            this.openToastOptions(
              this.$t('imagenes-subidas-con-exito'),
              this.$t('las-imagenes-se-han-subido-correctamente-a-la-orden-de-trabajo'),
              'success'
            );

            this.getWorkorder(this.$route.params.workorder);
          })
          .finally(() => {
            loading.dismiss();
          });
      } else {
        photos.forEach(async (photo, index) => {
          const formData = new FormData();

          photo.base64String =
            photo.base64String ||
            (await this.$getBase64Image(photo.webPath).then((data) => data.split`,`[1]));
          const blob = this.$base64toBlob(photo.base64String);

          formData.append('file', blob);
          formData.append('id_parte', this.$route.params.workorder || '');
          formData.append('id_maquina', this.$route.params.asset || '');

          this.$axios
            .post(
              `/v2/users/actions.php?call=setImageWorkorder&token=${this.gmao.user.token}&v2=1`,
              formData,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            )
            .then(() => {
              if (index == photos.length - 1) {
                this.getWorkorder(this.$route.params.workorder);
              }
              this.openToastOptions(
                this.$t('imagenes-subidas-con-exito'),
                this.$t('las-imagenes-se-han-subido-correctamente-a-la-orden-de-trabajo'),
                'success'
              );
            })
            .catch(async (e) => {
              if (e.code == 'ERR_NETWORK' && this.offline.status) {
                const syncObject = {
                  callFunction: 'setImageWorkorder',
                  data: {
                    id_parte: this.$route.params.workorder,
                    id_maquina: this.$route.params.asset
                  }
                }
                query += `
                  INSERT INTO parte_imagenes (id, syncObject, id_parte, id_tecnico, id_maquina, base64Src, created_at, updated_at, last_modified)
                  VALUES (${this.$moment().unix() + index}, '${JSON.stringify(syncObject)}', ${this.workorder.id}, ${
                  this.offline.workorder.id_tecnico
                }, ${
                  this.$route.params.asset
                }, 'data:image/png;base64,${
                  photo.base64String
                }', '${this.$moment().format(
                  'YYYY-MM-DD H:mm:ss'
                )}', '${this.$moment().format(
                  'YYYY-MM-DD H:mm:ss'
                )}', '${this.$moment().format('YYYY-MM-DD H:mm:ss')}');
                `;
                if (index == photos.length - 1 && query.trim().length)
                  this.uploaMultiplePhotosSQL(query, e);
                loading.dismiss();
              } else {
                this.openToastOptions(
                  this.$t('error-al-subir-las-imagenes'),
                  this.$t(
                    'el-servidor-no-ha-podido-procesar-la-subida-de-imagenes-por-favor-intentalo-mas-tarde'
                  )
                );
              }
            });
        });
      }
    },

    async uploaMultiplePhotosSQL(query, e) {
      try {
        const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

        const ret = await sqlite.checkConnectionsConsistency();
        const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

        let db = new SQLiteDBConnection();
        if (ret.result && isConn) {
          db = await sqlite.retrieveConnection('gmaoTecnicos', false);
        } else {
          db = await sqlite.createConnection(
            'gmaoTecnicos',
            false,
            'no-encryption',
            1,
            false
          );
        }
        await db.open();

        const uploadMultiplePhotosPromise = new Promise((resolve) => {
          resolve(
            this.$SQLiteQuery(
              'uploadMultiplePhotos_workorder',
              'parte_imagenes',
              query,
              e.config,
              db
            )
          );
        });
        uploadMultiplePhotosPromise.then(() => {
          setTimeout(() => {
            sqlite.closeConnection('gmaoTecnicos').then(() => {
              this.getWorkorder(this.$route.params.workorder);
            });
          }, 500);
        });
        // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
      } catch (error) {
        throw Error('SET Mulitple Images: ', error);
      }
    },

    uploadPhoto(photo) {
      const blob = this.$base64toBlob(photo.base64String);

      const formData = new FormData();

      formData.append('id_parte', this.$route.params.workorder || '');
      formData.append('id_maquina', this.$route.params.asset || '');
      formData.append('file', blob);

      this.$axios
        .post(
          `/v2/users/actions.php?call=setImageWorkorder&token=${this.gmao.user.token}&v2=1`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )
        .then(({ data }) => {
          this.getWorkorder(this.$route.params.workorder);

          if (data.imagen.src.length) {
            this.openToastOptions(
              this.$t('imagen-subida-con-exito'),
              this.$t('la-imagen-se-ha-subido-correctamente-a-la-orden-de-trabajo'),
              'success'
            );
          } else {
            this.openToastOptions(
              this.$t('error-al-subir-la-imagen'),
              this.$t(
                'el-servidor-no-ha-podido-procesar-la-subida-de-imagen-por-favor-intentalo-mas-tarde'
              )
            );
          }
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();
              const syncObject = {
                callFunction: 'setImageWorkorder',
                data: {
                  id_parte: this.$route.params.workorder,
                  id_maquina: this.$route.params.asset
                }
              }
              const query = `
                INSERT INTO parte_imagenes (id, id_parte, syncObject, id_tecnico, id_maquina, base64Src, created_at, updated_at, last_modified)
                VALUES (${this.$moment().unix()}, ${this.workorder.id}, '${JSON.stringify(syncObject)}', ${
                this.offline.workorder.id_tecnico
              }, ${
                this.$route.params.asset
              }, 'data:image/png;base64,${photo.base64String}', '${this.$moment().format(
                'YYYY-MM-DD H:mm:ss'
              )}', '${this.$moment().format(
                'YYYY-MM-DD H:mm:ss'
              )}', '${this.$moment().format('YYYY-MM-DD H:mm:ss')}')
              `;
              const uploadPhotoPromise = new Promise((resolve) => {
                resolve(
                  this.$SQLiteQuery(
                    'uploadPhoto_workorder',
                    'parte_imagenes',
                    query,
                    e.config,
                    db
                  )
                );
              });
              uploadPhotoPromise.then(() => {
                setTimeout(() => {
                  sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.getWorkorder(this.$route.params.workorder);
                  });
                }, 500);
              });
              // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
            } catch (error) {
              throw Error('SET WO Image: ', error);
            }
          }
        });
    },

    getWorkorder(value) {
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getParte',
            token: this.gmao.user.token,
            value,
            field: 'id',
            page: -1,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.workorderActive = data;
          this.woActiveImages = this.workorderActive?.imagenes?.filter((i) => i.id_maquina == this.$route.params?.asset) || [];
          return data;
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();
              const query = `
                SELECT *
                FROM partes P
                WHERE P.id=${value}
              `;
              const getPartePromise = new Promise((resolve) => {
                resolve(
                  this.$SQLiteQuery('getParte_general', 'partes', query, e.config, db)
                );
              });
              getPartePromise.then(() => {
                this.workorderActive = {};
                setTimeout(() => {
                  this.workorderActive = this.offline.workorder;
                  sqlite.closeConnection('gmaoTecnicos');

                  return this.offline.workorder;
                }, 500);
              });
              // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
            } catch (error) {
              throw Error("GET Parte: ", error);
            }
          }
        });
    },

    imageViewer(photo, checkField = false) {
      if (checkField) {
        // "photo" is "field" object
        this.clickedImageId = photo.id
        this.activeImagesCollection.push({
          id: photo.id,
          src: photo?.foto,
          check_field: true
        });
        this.clickedIndex = 1;
      } else {
        this.clickedImageId = photo.id;
        this.clickedIndex = this.activeImagesCollection?.findIndex((i) => i.id == this.clickedImageId);
      }
      this.imageModal = true;
    },

    deleteMedia(imageID, type = 'asset', checkField = false) {
      const localImageArray = type == 'asset' ? this.active.imagenes : this.woActiveImages;
      const fileLocalIndex = localImageArray.findIndex((i) => i.id == imageID);

      if (checkField) {
        return this.uploadModeloCampoPhoto(null, {id: imageID}).then(() => {this.imageModal = false});
      }

      this.$axios
        .post(
          `/v2/users/actions.php?call=setDeleteWorkorderImage&token=${this.gmao.user.token}&v2=1`,
          {
            data: {
              id_imagen: imageID,
              id_parte: this.offline.workorder.id || this.workorderActive.id || this.$route.params.workorder || null,
            }
          },
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        )
        .then(() => {
          localImageArray.splice(fileLocalIndex, 1);
        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();
              const syncObject = {
                callFunction: 'setDeleteWorkorderImage',
                data: {
                  id_imagen: this.clickedImageId,
                  id_parte: this.$route.params.workorder,
                },
              };
              const query = `
                UPDATE parte_imagenes
                SET
                  deleted_at='${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  syncObject='${JSON.stringify(syncObject)}'
                WHERE parte_imagenes.id_parte=${
                  this.offline.workorder.id || this.workorderActive.id || this.$route.params.workorder || 0
                } AND parte_imagenes.id=${this.clickedImageId || 0}
              `;
              const deleteImagePromise = new Promise((resolve) => {
                resolve(
                  this.$SQLiteQuery(
                    'deleteImage_workorder',
                    'parte_imagenes',
                    query,
                    e.config,
                    db
                  )
                );
              });
              deleteImagePromise.then(() => {
                setTimeout(() => {
                  sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.getWorkorder(this.$route.params.workorder);
                    localImageArray.splice(fileLocalIndex, 1);
                  });
                }, 500);
              });
              // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
            } catch (error) {
              throw Error('DEL Image: ', error);
            }
          }
        });
    },

    cambiarActivo(activeDeepCopy = null){
      this.$axios.post(`/v2/users/actions.php?call=setEditActivo&token=${this.gmao.user.token}&v2=1`, activeDeepCopy).then(
        () => {
          this.active = activeDeepCopy;
          this.editAsset = false;
        }
      ).catch(async (e) => {
        if (e.code == 'ERR_NETWORK' && this.offline.status) {
          try {
            const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

            const ret = await sqlite.checkConnectionsConsistency();
            const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

            let db = new SQLiteDBConnection();
            if (ret.result && isConn) {
              db = await sqlite.retrieveConnection('gmaoTecnicos', false);
            } else {
              db = await sqlite.createConnection(
                'gmaoTecnicos',
                false,
                'no-encryption',
                1,
                false
              );
            }
            await db.open();
            const syncObject = {
              callFunction: 'setEditActivo',
              data: activeDeepCopy
            }
            const query = `
              UPDATE maquinas
              SET
                nombre='${activeDeepCopy.nombre}',
                numeroserie='${activeDeepCopy.numeroserie}',
                fecha_instalacion='${activeDeepCopy.fecha_instalacion}',
                fingarantia='${activeDeepCopy.fingarantia}',
                syncObject='${JSON.stringify(syncObject)}',
                last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
              WHERE id=${activeDeepCopy.id};
              ${activeDeepCopy.modelo.id ? `
                UPDATE maquina_modelos
                SET
                  modelo='${activeDeepCopy.modelo.modelo}',
                  marca='${activeDeepCopy.modelo.marca}',
                  last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                WHERE id=${activeDeepCopy.modelo.id}
              ` : ''}
            `;

            const cambiarActivoPromise = new Promise((resolve) => {
                resolve(this.$SQLiteQuery('cambiarActivo_item', 'maquinas', query, e.config, db));
            });
            cambiarActivoPromise.then(() => {
              setTimeout(() => {
                this.active = this.offline.item_asset;
                sqlite.closeConnection('gmaoTecnicos');
              }, 500);
            });
            // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
          } catch (error) {
            throw Error('Cambiar Activo: ', error);
          }
        }
      });
    },

    getQuestions() {
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getPreguntas',
            token: this.gmao.user.token,
            value: this.gmao.user.id,
            page: -1,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.questions = data;
        }).catch((e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) this.questions = this.offline.questions;
        })
        .finally(() => {
          // this.loading = false;
        });
    },

    getReplies(value = null, reset = false) {
      if (reset) {
        this.active.id_respuesta = null;
      }

      console.log('ARG: ', this.active);
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getRespuestas',
            token: this.gmao.user.token,
            value,
            field: 'id_pregunta',
            modelo: this.active?.id_modelo,
            familia: this.active?.modelo?.id_familia,
            page: -1,
            v2: 1,
          },
        })
        .then(({ data }) => {
          data.map(a => {
            if ((this.checkedReplies || []).map(a=>a.id)?.includes(a.id)) a.active = true;
            return a;
          })
          this.replies = data;
        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();
              const query = `
                SELECT *
                FROM faq_respuestas R
                WHERE R.id_pregunta=${value}
                ORDER BY posicion ASC
              `;
              const getRepliesPromise = new Promise((resolve) => {
                  resolve(this.$SQLiteQuery('getReplies_item', 'faq_respuestas', query, e.config, db));
              });
              getRepliesPromise.then(() => {
                setTimeout(() => {
                  sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.replies = this.offline.replies;
                  });
                }, 500);
              });
              // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
            } catch (error) {
              throw Error('GET Replies: ', error);
            }
          }
        })
        .finally(() => {
          // this.loading = false;
        });
    },

    async setReply(value) {
      value = value.filter((r) => r); // Remove all null values
      // FIXME: Revisar selector 'multiple' (se entra dos veces en la función 'setReply')
      this.articles = [];

      this.setActiveFaq({
        id_pregunta: !+this.gmao.comportamientos.faq_solo_respuestas
          ? this.active.id_pregunta
          : 1,
        id_respuesta: value,
      });
      this.selectedReplies = this.replies.filter((r) => value.includes(r.id));

      if (this.selectedReplies.some((r) => r.materiales.length)) {
        this.selectedReplies.forEach((r) => {
          this.articles = this.articles.concat(r.materiales);
        });

        this.articles?.map((m) => {
          m.active = true;
          m.almacen = (m.articulo?.almacenes || m.almacenes || []).length
            ? (m.articulo?.almacenes[0]?.id || m.almacenes[0].id)
            : null;
          return m;
        });

        this.setArticles = true;
      } else {
        this.setReplies = false;
        await this.getActive();
      }
    },

    setFaqArticles() {
      const materiales = this.articles.filter(
        (m) => m.active && m.cantidad && m.almacen
      );

      const articulos = materiales.map((m) => {
        return {
          id: m.id,
          almacen: m.almacen,
          cantidad: m.cantidad,
          id_respuesta: m.id_respuesta,
          id_articulo: m.id_articulo
        }
      });

      this.$axios
        .post(
          `/v2/users/actions.php?call=setMaterialRespuestaParte&token=${this.gmao.user.token}&v2=1`,
          {
            data: {
              id_tecnico: this.gmao.user.id,
              workorder: this.workorderActive?.id || this.$route.params?.workorder,
              active: this.active.id,
              articles: articulos,
            }
          },
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        )
        .then(async ({ data }) => {
          if (!data?.status) {
            this.$openToastObject(this.$t('Ha ocurrido un error'), this.$t(data.msn || 'Ha ocurrido un error en el servidor al crear material asociado del parte.'), 'danger');
          }
          await this.getActive();
          this.setArticles = false;
          this.setReplies = false;

        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();

              const syncObject = {
                callFunction: 'setMaterialRespuestaParte',
                data: {
                  id_tecnico: this.gmao.user.id,
                  workorder: this.$route.params.workorder || this.workorderActive?.id,
                  active: this.active.id,
                  articles: articulos,
                }
              };

              let query =
                `
                  INSERT OR REPLACE INTO parte_materiales
                    (id, syncObject, id_parte, id_tecnico, id_articulo, cantidad, pcp, pvp, descuento_por, observaciones, id_maquina, iva, created_at, updated_at, last_modified, syncObject)
                  VALUES
                `;
              materiales.forEach((m, i) => {
                query += `
                (
                  ${this.$moment().unix()},
                  '${JSON.stringify(syncObject)}',
                  ${this.$route.params?.workorder || 0},
                  ${this.gmao.user?.id || 0},
                  ${m?.id || 0},
                  ${m?.cantidad || 0.00},
                  ${m?.pcp?.toFixed(2) || 0.00},
                  ${m?.pvp?.toFixed(2) || 0.00},
                  0,
                  '${m?.observaciones || ''}',
                  ${this.active?.id || 0},
                  ${this.gmao.comportamientos?.tipo_iva_default || 0},
                  '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  '${JSON.stringify(
                    {
                      id_tecnico: this.gmao.user.id,
                      workorder: this.$route.params.workorder,
                      active: this.active.id,
                      articles: materiales,
                    }
                  )}'
                )${i == materiales.length - 1 ? ';' : ','}
                `
              });

              const setFaqArticlesPromise = new Promise((resolve) => {
                  resolve(this.$SQLiteQuery('setFaqArticles_item', 'parte_materiales', query, e.config, db));
              });
              setFaqArticlesPromise.then(() => {
                setTimeout(() => {
                  sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.setArticles = false;
                  });
                }, 500);
              });
              // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
            } catch (error) {
              throw Error('setActiveFaq: ', error);
            }
          }
        });
    },

    async getActive() {
      await this.getWorkorderActive(
        this.workorderActive?.id || this.$route.params?.workorder,
        this.$route.params.asset,
        this.$route.params.sistema,
      ).then((data) => {
        this.active = data;
      });
    },

    setActiveFaq(fields) {
      if (this.cancelToken) this.cancelToken.cancel();
      this.cancelToken = this.$axios.CancelToken.source();

      if (!fields?.id_respuesta?.length) {
        this.$openToastObject(this.$t('Ha ocurrido un error'), this.$t('Tiene que seleccionar al menos una respuesta'), 'danger');
        return;
      }

      this.$axios.post(
        `/v2/users/actions.php?call=setActiveFaq&token=${this.gmao.user.token}&v2=1`,
        {
          data: {
            id: this.active.id,
            workorder: this.$route.params.workorder || this.workorderActive?.id,
            fields,
          }
        },
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          cancelToken: this.cancelToken.token,
        }
      ).catch(async (e) => {
        if (e.code == 'ERR_NETWORK' && this.offline.status) {
          try {
            const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

            const ret = await sqlite.checkConnectionsConsistency();
            const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

            let db = new SQLiteDBConnection();
            if (ret.result && isConn) {
              db = await sqlite.retrieveConnection('gmaoTecnicos', false);
            } else {
              db = await sqlite.createConnection(
                'gmaoTecnicos',
                false,
                'no-encryption',
                1,
                false
              );
            }
            await db.open();
            const syncObject = {
              callFunction: 'setActiveFaq',
              data: {
                id: this.active.id,
                workorder: this.$route.params.workorder,
                fields,
              }
            };
            let query = `
              INSERT INTO faq_respuestas_parte_maquinas
                (id, syncObject, id_respuesta, id_pregunta, id_parte, id_maquina, deleted_at, last_modified)
              VALUES
                (${this.$moment().unix()}, '${JSON.stringify(syncObject)}', 0, 0, ${this.$route.params.workorder}, ${this.active.id}, '${this.$moment().format('YYYY-MM-DD H:mm:ss')}', '${this.$moment().format('YYYY-MM-DD H:mm:ss')}');
            `;
            if (fields?.id_respuesta?.length > 0) {
              fields.id_respuesta.forEach((r, i) => {
                query += `
                INSERT INTO faq_respuestas_parte_maquinas
                  (id, syncObject, id_respuesta, id_pregunta, id_parte, id_maquina, created_at, updated_at, last_modified)
                VALUES
                  (${this.$moment().unix() + i + 1}, '${JSON.stringify(syncObject)}', ${r || 0}, ${fields.id_pregunta || 0}, ${this.$route.params.workorder}, ${this.active.id}, '${this.$moment().format('YYYY-MM-DD H:mm:ss')}', '${this.$moment().format('YYYY-MM-DD H:mm:ss')}', '${this.$moment().format('YYYY-MM-DD H:mm:ss')}');
                `
              });
            }

            const setActiveFaqPromise = new Promise((resolve) => {
                resolve(this.$SQLiteQuery('setActiveFaq_item', 'faq_respuestas_parte_maquinas', query, e.config, db));
            });
            setActiveFaqPromise.then(() => {
              setTimeout(() => {
                sqlite.closeConnection('gmaoTecnicos');
              }, 500);
            });
            // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
          } catch (error) {
            throw Error('setActiveFaq: ', error);
          }
        }
      });
    },

    async checkAllFields() {
      if (this.workorderActive.tipo_letra == 'L' && this.active?.lecturas?.length) {
        this.active.lecturas?.map((r) => {
          r.lectura = 0;
          return r;
        });
      } else {
        this.active.respuestas?.map((r) => {
          switch (r?.campo?.tipo) {
            case 'CHECK':
              r.valor = true;
              break;
            case 'TEXTO':
              if (!r.valor) r.valor = "Correcto";
          }
  
          return r;
        });
      }
      this.active.finalizado = true;

      await this.saveBack();
    },

    async toggleFinished() {
      await this.$axios
        .post(
          `/v2/users/actions.php?call=setChecks&token=${this.gmao.user.token}&v2=1`,
          {
            data: {
              parte_id: this.$route.params.workorder || this.offline?.workorder?.id,
              maquina_id: this.active.id,
              finalizado: +this.active.finalizado,
            }
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )
        .then(() => {
          this.$openToastObject(this.$t('Activo finalizado'), this.$t('El activo ha sido resuelto correctamente.'), 'success');
          this.$router.go(-1);
        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();
              const syncObject = {
                callFunction: 'setChecks',
                data: {
                  parte_id: this.$route.params.workorder || this.offline?.workorder?.id,
                  maquina_id: this.active.id,
                  finalizado: +this.active.finalizado,
                }
              };

              let query = '';
              query = `
                UPDATE parte_maquinas
                  SET
                    tareas_completadas=1,
                    syncObject='${JSON.stringify(syncObject)}',
                    last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                  WHERE
                    id_parte=${this.workorderActive.id} AND id_maquina=${this.active.id};
              `;

              const saveBackPromise = new Promise((resolve) => {
                  resolve(this.$SQLiteQuery('saveBack_item', 'modelos_parte_campos_respuestas', query, e.config, db));
              });

              saveBackPromise.then(() => {
                setTimeout(() => {
                  sqlite.closeConnection('gmaoTecnicos').then(() => {
                    const maquinaParte = this.offline.workorder.maquinas.find((m) => m.id == this.active?.id);
                    console.log(maquinaParte, this.active);
                    
                    // maquinaParte.pivot.tareas_completadas = 1;
                    this.$router.go(-1);
                  });
                }, 500);
              });
              // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
            } catch (error) {
              throw Error('saveBack: ', error);
            }
          }
        });
    },

    saveBackLecturas() {
      if (this.checksChanged || this.active.finalizado) {
        this.$axios
          .post(
            `/v2/users/actions.php?call=setChecksLecturas&token=${this.gmao.user.token}&v2=1`,
            {
              data: {
                active: this.active.lecturas?.map((r) => ({
                  id: r.id,
                  lectura: r.lectura,
                  observaciones: r.observaciones,
                })),
                parte_id: this.$route.params.workorder || this.offline?.workorder?.id,
                maquina_id: this.active.id,
                finalizado: this.active.finalizado,
              }
            },
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          )
          .then(() => {
            this.$router.go(-1);
          }).catch(async (e) => {
            // TODO: NEW — IMPLEMENTAR EN OFFLIN
            if (e.code == 'ERR_NETWORK' && this.offline.status) {
              // try {
              //   const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              //   const ret = await sqlite.checkConnectionsConsistency();
              //   const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              //   let db = new SQLiteDBConnection();
              //   if (ret.result && isConn) {
              //     db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              //   } else {
              //     db = await sqlite.createConnection(
              //       'gmaoTecnicos',
              //       false,
              //       'no-encryption',
              //       1,
              //       false
              //     );
              //   }
              //   await db.open();
              //   const syncObject = {
              //     callFunction: 'setChecks',
              //     data: {
              //       active: this.active.respuestas?.map((r) => ({
              //         id: r.id,
              //         value: r.valor,
              //       })),
              //       parte_id: this.$route.params.workorder || this.offline?.workorder?.id,
              //       maquina_id: this.active.id,
              //       finalizado: this.active.finalizado,
              //     }
              //   }
              //   let query = '';
              //   query += `
              //     UPDATE parte_maquinas
              //       SET
              //         tareas_completadas=1
              //       WHERE
              //         id_parte=${this.workorderActive.id} AND id_maquina=${this.active.id};
              //   `;
              //   this.active?.respuestas?.forEach((r) => (
              //     query += `
              //       UPDATE modelos_parte_campos_respuestas
              //       SET
              //         valor='${r.valor}',
              //         syncObject='${JSON.stringify(syncObject)}',
              //         last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
              //       WHERE id=${r.id};
              //     `
              //   ));

              //   const saveBackPromise = new Promise((resolve) => {
              //       resolve(this.$SQLiteQuery('saveBack_item', 'modelos_parte_campos_respuestas', query, e.config, db));
              //   });
              //   console.log(1);
              //   saveBackPromise.then(() => {
              //     setTimeout(() => {
              //       sqlite.closeConnection('gmaoTecnicos').then(() => {
              //         const maquinaParte = this.offline.workorder.maquinas.find((m) => m.id == this.active?.id);
              //         maquinaParte.pivot.tareas_completadas = 1;
              //         this.$router.go(-1);
              //       });
              //     }, 500);
              //   });
              //   // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
              // } catch (error) {
              //   throw Error('saveBack: ', error);
              // }
            }
          });
      } else {
        this.$router.go(-1);
      }
    },

    async saveBack() {
      // Si el parte es de tipo lectura, mandamos a otra funcion
      if (this.workorderActive?.tipo_letra == 'L' && this.active?.lecturas?.length) {
        return this.saveBackLecturas();
      }

      if (+this.gmao.comportamientos.activo_vandalizado) {
        if(this.active?.parte_maquina.vandalized == null) {
          this.$openToastObject(this.$t('No se puede guardar'), this.$t('Debes especificar si el activo ha sido vandalizado'), 'danger');
          return;
        } else {
          this.$axios
            .post(
              `/v2/users/actions.php?call=setVandalized&token=${this.gmao.user.token}&v2=1`,
              {
                data: {
                  parte_maquina: this.active?.parte_maquina.id,
                  vandalizado: this.active?.parte_maquina.vandalized,
                }
              }
            );
        }
      }

      // SaveBack normal
      const mandatoryPhoto = (this.active?.respuestas || []).filter((r) => r?.campo?.predefinido?.foto && !r?.foto);

      if (mandatoryPhoto?.length) {
        this.$openToastObject(this.$t('No puede guardar checklist'), this.$t('Para guardar el checklist, tiene que rellenar los campos con la foto obligatoria.'), 'danger');
        return;
      }

      if (!mandatoryPhoto?.length && (this.checksChanged || this.active.finalizado)) {
        await this.$axios
          .post(
            `/v2/users/actions.php?call=setChecks&token=${this.gmao.user.token}&v2=1`,
            {
              data: {
                active: this.active.respuestas?.filter((a) => a?.campo?.tipo != "SEP")?.map((r) => ({
                  id: r.id,
                  value: r.valor,
                })),
                parte_id: this.$route.params.workorder || this.offline?.workorder?.id,
                maquina_id: this.active.id,
                finalizado: +this.active.finalizado,
              }
            },
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          )
          .then(() => {
            this.$openToastObject(this.$t('Checklist Resuelto'), this.$t('El checklist ha sido resuelto correctamente.'), 'success');
            this.$router.go(-1);
          }).catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {
              try {
                const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

                const ret = await sqlite.checkConnectionsConsistency();
                const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

                let db = new SQLiteDBConnection();
                if (ret.result && isConn) {
                  db = await sqlite.retrieveConnection('gmaoTecnicos', false);
                } else {
                  db = await sqlite.createConnection(
                    'gmaoTecnicos',
                    false,
                    'no-encryption',
                    1,
                    false
                  );
                }
                await db.open();
                const syncObject = {
                  callFunction: 'setChecks',
                  data: {
                    active: this.active.respuestas?.map((r) => ({
                      id: r.id,
                      value: r.valor,
                    })),
                    parte_id: this.$route.params.workorder || this.offline?.workorder?.id,
                    maquina_id: this.active.id,
                    finalizado: +this.active.finalizado,
                  }
                }
                let query = '';
                query += `
                  UPDATE parte_maquinas
                    SET
                      tareas_completadas=1
                    WHERE
                      id_parte=${this.workorderActive.id} AND id_maquina=${this.active.id};
                `;
                this.active?.respuestas?.forEach((r) => (
                  query += `
                    UPDATE modelos_parte_campos_respuestas
                    SET
                      valor='${r.valor}',
                      syncObject='${JSON.stringify(syncObject)}',
                      last_modified='${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                    WHERE id=${r.id};
                  `
                ));

                const saveBackPromise = new Promise((resolve) => {
                    resolve(this.$SQLiteQuery('saveBack_item', 'modelos_parte_campos_respuestas', query, e.config, db));
                });
                console.log(1);
                saveBackPromise.then(() => {
                  setTimeout(() => {
                    sqlite.closeConnection('gmaoTecnicos').then(() => {
                      const maquinaParte = this.offline.workorder.maquinas.find((m) => m.id == this.active?.id);
                      maquinaParte.pivot.tareas_completadas = 1;
                      this.$router.go(-1);
                    });
                  }, 500);
                });
                // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
              } catch (error) {
                throw Error('saveBack: ', error);
              }
            }
          });
      } else {
        this.$router.go(-1);
      }
    },

    setAnomaly(anomaly) {
      if (anomaly) {
        this.$axios.post(
          `/v2/users/actions.php?call=setActiveAnomaly&token=${this.gmao.user.token}&v2=1`,
          {
            data: {
              active: this.active.id,
              workorder: this.$route.params.workorder || this.offline?.workorder?.id || this.workorderActive?.id,
              anomaly,
            }
          },
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        ).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();
              const syncObject = {
                callFunction: 'setActiveAnomaly',
                data: {
                  active: this.active.id,
                  workorder: this.$route.params.workorder,
                  anomaly,
                }
              };
              const query = `
                INSERT OR REPLACE INTO modelos_parte_anomalias
                  (id, syncObject, id_parte, id_maquina, anomalias, last_modified, updated_at)
                VALUES
                  (
                    ${this.$moment().unix()},
                    '${JSON.stringify(syncObject)}',
                    ${this.$route?.params?.workorder || 0},
                    ${this.active?.id || 0},
                    '${anomaly}',
                    '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                    '${this.$moment().format('YYYY-MM-DD H:mm:ss')}'
                  );
              `;

              const setAnomalyPromise = new Promise((resolve) => {
                  resolve(this.$SQLiteQuery('setAnomaly_item', 'modelos_parte_anomalias', query, e.config, db));
              });
              setAnomalyPromise.then(() => {
                setTimeout(() => {
                  sqlite.closeConnection('gmaoTecnicos');
                }, 500);
              });
              // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
            } catch (error) {
              throw Error('setAnomalia: ', error);
            }
          }
        });
      }
    },

    elapsedTime() {
      if (this.workOrders.activeTimeRegister) {
        const time = this.$moment().diff(
          this.$moment(this.workOrders.activeTimeRegister.from),
          'seconds'
        );

        this.time.hours = Math.floor(time / 3600);
        this.time.minutes = String(Math.floor((time % 3600) / 60)).padStart(
          2,
          '0'
        );
        this.time.seconds = String((time % 3600) % 60).padStart(2, '0');
      }
    },

    async toggleTimer() {
      if (this.workOrders.activeTimeRegister?.from) {
        if (
          this.workOrders.activeTimeRegister?.active == this.active.id &&
          this.workOrders?.activeTimeRegister?.workorder ===
            this.$route.params.workorder
        ) {
          this.workOrders.activeTimeRegister.to = this.$moment();
          this.storeTimes(this.workOrders.activeTimeRegister);
          this.workOrders.activeTimeRegister = null;
          this.time = { hours: 0, minutes: 0, seconds: 0 };
        } else {
          this.alertPopup(
            this.$tc(
              'el-activo-this-workorders-timeregister-active-esta-en-ejecucion-quieres-finalizarlo-y-comenzar-este-activo',
              [this.workOrders.activeTimeRegister?.active]
            ),
            () => {
              this.workOrders.activeTimeRegister.to = this.$moment();
              this.storeTimes(this.workOrders.activeTimeRegister);
              this.workOrders.activeTimeRegister = null;
              this.time = { hours: 0, minutes: 0, seconds: 0 };

              this.workOrders.activeTimeRegister = {
                from: this.$moment(),
                active: this.active?.id || this.$route.params?.asset,
                workorder: this.$route.params.workorder,
              };
            },
            { cancel: 'cancelar', confirm: 'finalizar' }
          );
        }
      } else {
        this.workOrders.activeTimeRegister = {
          from: this.$moment(),
          active: this.active?.id || this.$route.params?.asset,
          workorder: this.$route.params.workorder || this.offline?.workorder?.id,
        };
      }
    },

    setImproductividad(val) {
      const time = val?.target?.value;
      this.$axios.post(`/v2/users/actions.php?call=setImproductividad&token=${this.gmao.user.token}&v2=1`, {
        data: {
          time,
          maquina: this.active?.parte_maquina?.id
        }
      });
    },

    storeTimes(timeRegister) {
      timeRegister.user || (timeRegister.user = this.gmao.user.id);
      timeRegister.total = this.$moment(
        this.workOrders.activeTimeRegister.to
      ).diff(this.$moment(this.workOrders.activeTimeRegister.from), 'seconds');

      this.$axios
        .post(
          `/v2/users/actions.php?call=setActiveTimes&token=${this.gmao.user.token}&v2=1`,
          {data: {...timeRegister}},
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        )
        .then(({ data }) => {
          this.active.tiempos_sum_tiempo_total = data.times;
        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();

              const syncObject = {
                callFunction: 'setActiveTimes',
                data: {
                  timeRegister
                }
              }

              const query = `
                  INSERT INTO parte_maqunas_tiempos
                    (id, syncObject, id_parte, id_maquina, id_tecnico, inicio, fin, tiempo_total, created_at, updated_at, last_modified)
                  VALUES
                    (
                      ${this.$moment().unix()},
                      '${JSON.stringify(syncObject)}',
                      ${timeRegister.workorder},
                      ${timeRegister.active},
                      ${timeRegister.user},
                      '${this.$moment(timeRegister.from).format('YYYY-MM-DD H:mm:ss')}',
                      '${this.$moment(timeRegister.to).format('YYYY-MM-DD H:mm:ss')}',
                      ${timeRegister.total},
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                      '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                    );

                  UPDATE parte_maquinas
                  SET t_estimado=t_estimado+${timeRegister.total}
                  WHERE id_parte=${timeRegister.workorder} AND id_maquina=${timeRegister.active};

                  SELECT SUM(tiempo_total) as tiempo_total FROM parte_maqunas_tiempos WHERE id_parte=${timeRegister.workorder} AND id_maquina=${timeRegister.active};
                `;

              const storeTimesPromise = new Promise((resolve) => {
                  resolve(this.$SQLiteQuery('storeTimes_item', 'parte_maqunas_tiempos', query, e.config, db));
              });
              storeTimesPromise.then(() => {
                setTimeout(() => {
                  sqlite.closeConnection('gmaoTecnicos').then(() => {
                    this.active.tiempos_sum_tiempo_total = this.offline.totalTimes;
                  });
                }, 500);
              });
              // FIXME: Posible fallo -> Mirar setTimeout. El componente carga con datos vacios y luego se rellenan los datos
            } catch (error) {
              throw Error('saveBack: ', error);
            }
          }
        });
    },

    async alertPopup(
      header,
      callback,
      { cancel, confirm } = { cancel: 'cancel', confirm: 'confirm' }
    ) {
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header,
        buttons: [
          {
            text: this.$t(cancel),
            role: 'cancel',
            cssClass: 'secondary',
            id: 'cancel-button',
          },
          {
            text: this.$t(confirm),
            id: 'confirm-button',
            handler: callback,
          },
        ],
      });

      await alert.present();
    },
  },
};
</script>

<style lang="scss" scoped>
.removeBtn {
  position: absolute;
  top: 0.5rem;
  right: 0.25rem;
}
.wo-item {
  // border: 1px solid #dadada;
  height: 100%;

  // & ion-text { margin-bottom: 20px; }
  & p {
    font-size: 12px;
  }
}
ion-content {
  --ion-background-color: #f6f6f6;
}

ion-card {
  --background: white !important;
}
.title {
  text-transform: capitalize;
  --ion-background-color: transparent;
  margin-bottom: -15px !important;
}

ion-item {
  --ion-background-color: white;
}

ion-list {
  --ion-background-color: white;
}
h5 {
  font-size: 10pt !important;
  color: #767676;
}
item-inner {
  border-style: none !important;
}

.date-modal {
  background-color: rgba(0, 0, 0, 0.4);
  --width: 290px;
  --height: 382px;
  --border-radius: 8px;
}

.date-modal ion-datetime {
  height: 382px;
  width: 100%;
}

.editableField {
  background-color: var(--ion-color-tertiary-alpha);
  border-radius: 10px;
  font-size: 12px;
  padding-left: 5px !important;
}

.assetDate::part(native) {
  padding-left: 0;
}


.priority-1 {
  border-left: 15px solid var(--priority-1);
  & > ion-icon {
    color: var(--priority-1);
  }
}
.priority-2 {
  border-left: 15px solid var(--priority-2);
  & > ion-icon {
    color: var(--priority-2);
  }
}
.priority-3 {
  border-left: 15px solid var(--priority-3);
  & > ion-icon {
    color: var(--priority-3);
  }
}
.priority-4 {
  border-left: 15px solid var(--priority-4);
  & > ion-icon {
    color: var(--priority-4);
  }
}
.priority-5 {
  border-left: 15px solid var(--priority-5);
  & > ion-icon {
    color: var(--priority-5);
  }
}

.active-finished::part(native) {
  background-color: #00acac20;
  min-height: 70px;
}

.checkboxes .ion-color {
  --border-color: var(--ion-color-tertiary);
}

.activosOT {
  min-height: 70px;
  margin-bottom: 1px;

  display: flex;
  flex-direction: row;
  flex-shrink: inherit;
  width: 100%;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-bottom: 0;
  }
}
.overflowText > h3 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90%;
}
.datatime {
  background: var(--ion-color-tertiary-alpha);
}

ion-searchbar.defaultSearchBar {
  --background: #fff;
  --border-radius: 1rem;
}

</style>

