<template>
  <template v-if="!isTextArea">
    <ion-input
      ref="g_ioninput"
      v-model="copyValue"
      :type="type"
      :label="label"
      :label-placement="labelPlacement"
      :helper-text="helperText"
      :error-text="errorText"
      @ionInput="() => updateValue()"
      @ionBlur="() => checkAsVisited"
    ></ion-input>
  </template>
  <template v-else>
    <ion-textarea
      ref="g_iontextarea"
      v-model="copyValue"
      class="gmao-textarea"
      :autoGrow="autoGrow"
      :disabled="disabled"
      :placeholder="placeholder"
      @ionChange="() => updateValue()"
      @ionBlur="checkAsVisited"
      :helper-text="helperText"
      :error-text="errorText"
      :debounce="500"
    ></ion-textarea>
  </template>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from 'vue';

import {
  IonInput,
  IonTextarea
} from '@ionic/vue';

interface MyComponentRefs {
  g_iontextarea: HTMLIonTextareaElement;
  g_ioninput: HTMLIonInputElement;
}
interface IonicVueRef {
  $el: HTMLElement;
}


export default defineComponent ({
  name: 'GIonInput',
  props: {
    type: {
      type: String as PropType<'text' | 'number' | 'password' | 'email' | 'search' | 'tel' | 'url' | 'time' | 'date'>,
      default: 'text'
    },
    modelValue: {
      type: String,
      default: '',
      required: true
    },

    // NOTE: Helpers props
    helperText: {
      type: String,
      default: null,
      required: false,
    },
    errorText: {
      type: String,
      default: null,
      required: false,
    },

    // NOTE: Label props
    label: {
      type: String,
      default: null,
      required: false,
    },
    labelPlacement: {
      type: String as PropType<"end" | "fixed" | "floating" | "stacked" | "start">,
      default: null,
      required: false,
    },

    placeholder: {
      type: String,
      default: null,
      required: false,
    },

    // NOTE: Other props
    isTextArea: {
      type: Boolean,
      default: false
    },
    autoGrow: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: false
    },

    validation: {
      type: Function,
      default: (): boolean => false,
    },
  },

  components: {
    IonInput,
    IonTextarea,
  },

  setup() {
    return {
      copyValue: ref<string | number | undefined>(),
    };
  },

  watch: {
    copyValue() {
      if (this.required) this.validateInput();
    },
    modelValue(v) {
      if (v) this.copyValue = v;
    }
  },

  methods: {
    validateInput() {
      const isValidated = this.validation(this.copyValue);
      const inputSelected: keyof MyComponentRefs = this.isTextArea ? 'g_iontextarea' : 'g_ioninput';
      const componentRef = this.$refs[inputSelected] as IonicVueRef;

      if (componentRef) {
        this.$nextTick(() => {
          this.checkAsVisited();
          if (componentRef?.$el) {
            if (!isValidated) {
              componentRef?.$el.classList.add('ion-valid');
              componentRef?.$el.classList.add('ion-invalid');
            } else {
              componentRef?.$el.classList.remove('ion-valid');
              componentRef?.$el.classList.remove('ion-invalid');
            }
          }
        })
      }
    },
    updateValue() {
      try {
        if (this.required) this.validateInput();
      } catch (error) {
        console.error('update: ', error);
      } finally {

        this.$emit('update:modelValue', this.copyValue);
      }
    },
    checkAsVisited() {
      const inputSelected: keyof MyComponentRefs = this.isTextArea ? 'g_iontextarea' : 'g_ioninput';
      const componentRef = this.$refs[inputSelected] as IonicVueRef;
      if (componentRef && componentRef?.$el) {
        if (!componentRef?.$el.classList.contains('ion-touched')) componentRef?.$el.classList.add('ion-touched');
        else return;
      }

      if (this.required) this.validateInput();
    }
  },
});
</script>

<style lang="scss" scoped>
ion-textarea {
  --border-width: none;
  --padding-bottom: 0px;
}

ion-textarea.ion-valid {
  --border-width: 0.55px;
}
</style>