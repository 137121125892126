<template>
  <template v-for="s in systems" :key="s.id">
    <ion-item @click="$emit('done', s)">
      <ion-label>
        <h3 class="ion-text-wrap">{{ parent }} {{ s?.nombre || 'Sin nombre' }}</h3>
      </ion-label>
    </ion-item>

    <template v-if="s?.all_sistemas?.length">
      <SystemTree :systems="s.all_sistemas" :parent="`${parent} ${s?.nombre || 'Sin nombre'} →`" @done="(sistema) => $emit('done', sistema)" />
    </template>
  </template>
</template>

<script>
// import { ref } from 'vue';

import {
  IonItem,
  IonLabel
} from '@ionic/vue';

export default {
  name: 'SystemTree',
  props: {
    systems: {
      required: true,
      type: Array
    },

    parent: {
      type: String,
      default: ''
    }
  },

  components: {
    IonItem,
    IonLabel
  }
}
</script>