import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import ca from './ca.json';
import pt from './pt.json';
import sv from './sv.json';
import nl from './nl.json';
import de from './de.json';
import it from './it.json';
import uk from './uk.json';
import ro from './ro.json';

export default {
  en,
  'en-US': en,
  es,
  'es-ES': es,
  fr,
  ca,
  pt,
  sv,
  nl,
  de,
  it,
  uk,
  ro
};