<template>
  <ion-item
    lines="none"
    class="title"
  >
    <h5>{{ $t('COMUNICACIONES') }}</h5>
    <ion-button
      class="ion-no-margin"
      size="small"
      fill="clear"
      color="tertiary"
      @click="$emit('update:chatModal', true)"
      :disabled="not_editable"
      slot="end"
    >
      <ion-icon :icon="add" style="margin-right: 4px" />
      <span>{{ $t('Ver más') }}</span>
    </ion-button>
  </ion-item>

  <ion-card
    style="box-shadow: none; border-radius: 15px; margin-top: 8px;"
    class="bloque"
  >
    <ion-card-content class="ion-no-padding">
      <ion-item lines="none" v-if="!comunicaciones?.length"
      class="grey">{{ $t('No hay mensajes registrados') }}
      </ion-item>
      <ion-list lines="none" v-if="(comunicaciones || [])?.length">
        <ion-item>
          <ion-label>
            <h3 v-html='comunicaciones?.at(-1)?.mensaje'></h3>
          </ion-label>

          <ion-label slot="end">
            <p style="font-size: 12px; text-align: right">
              {{ comunicaciones?.at(-1).usuario?.nombre_completo }}
            </p>
            <p style="font-size: 10px">
              {{
                $moment(comunicaciones?.at(-1)?.created_at).format('lll')
              }}
            </p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>

</template>

<script lang="ts">
import {
  IonButton,
  IonList,
  IonLabel,
  IonItem,
} from '@ionic/vue';

import { add } from 'ionicons/icons';

export default {
  name: 'VCommunications',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    comportamientos: {
      required: true,
      type: Object,
    },

    comunicaciones: {
      required: true,
      type: Array
    },

    not_editable: {
      required: true,
      type: Boolean
    },
  },

  components: {
    IonButton,
    IonList,
    IonLabel,
    IonItem,
  },

  setup() {
    return {
      // ICONS
      add,
    };
  },

  methods: {},
};
</script>

<style lang='scss' scoped>
.bloque {
  margin-bottom: 1em !important;
}

ion-content {
  --ion-background-color: #f6f6f6;
}

ion-card {
  --background: white !important;
}

.title {
  text-transform: capitalize;
  --ion-background-color: transparent;
  margin-bottom: -15px !important;
}

ion-item {
  --ion-background-color: white;
}

ion-list {
  --ion-background-color: white;
}

h5 {
  font-size: 10pt !important;
  color: #767676;
}

item-inner {
  border-style: none !important;
}
.item {
  margin-bottom: 0 !important;
}
</style>