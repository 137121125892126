<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t('Nuevo modelo') }}</ion-title>

        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-button v-bind:disabled="isDisabled" @click="$emit('done', null)">{{ $t('Añadir') }}</ion-button>
        </ion-buttons>

      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
        <ion-list style="border-radius: 15px">
          <ion-item>
            <ion-label>{{ $t('Marca') }}*</ion-label>
            <ion-input
              class="ion-text-right"
              v-model="modeloCopy.marca"
              @ionInput="$emit('update:newModelo', modeloCopy)"
            />
          </ion-item>

          <ion-item>
            <ion-label>{{ $t('Modelo') }}*</ion-label>
            <ion-input
              class="ion-text-right"
              v-model="modeloCopy.modelo"
              @ionInput="$emit('update:newModelo', modeloCopy)"
            />
          </ion-item>

          <ion-item>
            <ion-label>{{ $t('Familia') }}</ion-label>
            <ion-input
              @click="setFamilia = true"
              class="ion-text-right"
              :value="modeloCopy.familiaNombre"
              readonly
              clear-input
            ></ion-input>
          </ion-item>
        </ion-list>
    </ion-content>

  </ion-modal>

  <MSetFinder
    v-model="setFamilia"
    @done="(values) => setModeloFamilia(values)"
    @load="(e) => onLoad(e)"
    :data="familias.flat()"
    :paginated="true"
    :content="{
      title: 'Selecciona una familia',
      empty_title: 'No hay familias',
      multiple: false,
    }"
    :structure="[
      {
        tag: 'h3',
        key: 'name'
      }
    ]"
  />


</template>

<script>
import MSetFinder from '@/components/MSetFinder.vue';

// Stores
import { useGmaoStore } from '@/stores/gmao';

import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonInput,
} from '@ionic/vue';

import {
  add,
  imagesOutline,
  camera,
} from 'ionicons/icons';

import { ref } from 'vue';

export default {
  name: 'MSetActivo',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    newModelo: {
      required: true,
      type: Object,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonInput,

    MSetFinder,
  },

  watch: {
    modelValue(v) {
      if (v) this.modeloCopy = structuredClone(this.newModelo);
    },

    setFamilia(v) {
      if (v) this.applyFilters(true);
    }
  },

  computed: {
    isDisabled() {
      return !(this.modeloCopy.marca && this.modeloCopy.modelo);
    }
  },

  setup() {
    const gmao = useGmaoStore();
    return {
      add,
      imagesOutline,
      camera,

      gmao,

      modeloCopy: ref({}),
      familias: ref([]),
      setFamilia: ref(false),

      current_page: ref(1),
      last_page: ref(null),
      total: ref(null),
      loading: ref(null),
    };
  },

  methods: {
    setModeloFamilia(value) {
      const selectedFamilia = this.familias.flat().find((f) => f.id == value);
      
      this.modeloCopy.familia = selectedFamilia;
      if (this.modeloCopy.familia.id_familia)
      {
        this.modeloCopy.familiaNombre = `${this.modeloCopy.familia.nombre || '_'} > ${this.modeloCopy.familia?.subfamilia?.nombre || '_'}`
      } else this.modeloCopy.familiaNombre = this.modeloCopy.familia.nombre

      if (selectedFamilia.id_familia) {
        this.modeloCopy.id_familia = selectedFamilia.id_familia;
        this.modeloCopy.id_subfamilia = selectedFamilia.id;
      } else {
        this.modeloCopy.id_familia = selectedFamilia.id;
      }

      this.$emit('update:newModelo', this.modeloCopy);
      this.setFamilia = false;
    },
    /** PAGINATION */
    onLoad(event) {
      if (this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFilters(false).then(() => {
          event.target.complete();
        });
      } else {
        event.target.complete();
      }
    },
    async applyFilters(reset = true) {
      if (reset) {
        this.familias = [];
        this.current_page = 1;
      }
      return this.getFamilias();
    },

    async getFamilias() {
      this.loading = true;
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getFamilias',
            token: this.gmao.user.token,
            page: this.current_page,
            search: '',
            v2: 1,
          },
        })
        .then(({ data }) => {
          data.data = data.data.map((f) => {
            if (f.id_familia) f.name = `${f.nombre || '_'} > ${f?.subfamilia?.nombre || '_'}`
            else f.name = f.nombre;
            return f;
          });

          this.familias.push(data.data);

          this.current_page = data.current_page;
          this.last_page = data.last_page;
          this.total = data.total;
        })
  },
}
}
</script>