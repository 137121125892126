<template>
  <ion-header id="gmao-header" :class="`gmao-gradient ion-padding ion-no-border ${offline.status ? 'addMarginBottom' : ''}`">
    <ion-toolbar color="transparent">
      <ion-buttons slot="start">
        <slot name="start">
          <ion-icon :icon="menu" @click="openMenu" class="f20"></ion-icon>
          <div
            v-if="
              gmao.notifications ||
              (parseInt(gmao.user?.version?.replace(/\./g, '')) >
              parseInt(gmao.v?.replace(/\./g, '')))
            "
            class="alert-dot" />
        </slot>
      </ion-buttons>

      <div style="display:flex;">
        <ion-title><slot>{{ $route.name }}</slot></ion-title>
        <!-- v-if="offline.showLoader" -->
        <span v-if="offline.showLoader" style="display:flex;align-items:center;margin-right:1rem;">
          <!-- <span class="loader"></span> -->
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </span>
      </div>

      <ion-buttons slot="end">
        <slot name="end"> </slot>

        <ion-icon
          v-if="!!+gmao.comportamientos?.ver_logout_app"
          :icon="exitOutline"
          @click="login"
          class="leftSpace f20"
        ></ion-icon>
      </ion-buttons>
    </ion-toolbar>

    <slot name="toolbar"></slot>
    <slot name="bottom"></slot>

    <ion-fab v-if="$slots.chip" vertical="bottom" horizontal="end" edge>
      <ion-chip
        color="white"
        style="background-color: var(--ion-color-secondary)"
      >
        <slot name="chip"></slot>
      </ion-chip>
    </ion-fab>
    <template v-if="offline.status">
      <div class="offline-header">
        <p>{{ $t('offline') }} · {{ offline.counterQueue }}  {{ $t('pending_changes') }}</p>
      </div>
    </template>
  </ion-header>

</template>

<script>
import { useGmaoStore } from '@/stores/gmao';
import { useOfflineStore } from '@/stores/offline';
import { useWorkOrdersStore } from '@/stores/workorders';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonFab,
  IonChip,
  menuController,
} from '@ionic/vue';
import { menu, exitOutline, cloudOfflineOutline } from 'ionicons/icons';

export default {
  name: 'AppToolbar',
  props: {},

  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonIcon,
    IonFab,
    IonChip,
  },

  setup() {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();
    const wo = useWorkOrdersStore();

    return {
      menu,
      exitOutline,
      gmao,
      offline,
      wo,

      cloudOfflineOutline,
    };
  },

  methods: {
    async openMenu() {
      await menuController.open();
    },
    login() {
      localStorage.clear();
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.controller?.postMessage({
          type: 'USER_LOGGED_OUT'
        });
      }
      this.gmao.$reset();
      this.wo.$reset();
      this.offline.$reset();
      this.$router.replace({ name: 'login', query: { token: 0 } });
    },
  },
};
</script>

<style lang="scss" scoped>
#gmao-header {
  color: white;
}

ion-chip {
  --background: var(--ion-color-danger);
  --color: var(--ion-color-danger);
}

.alert-dot {
  height: 8px;
  width: 8px;
  background: var(--ion-color-tertiary);
  outline: 2px solid var(--ion-color-primary);
  border-radius: 100%;
  position: absolute;
  right: -3px;
  top: 0;
}
.offline-position-dot {
  background: var(--ion-color-danger);
  outline: 1px solid var(--ion-color-primary);
  right: 5px !important;
  top: 5px !important;
}

// Spinner
// .loader {
//   width: 25px;
//   height: 25px;
//   border-radius: 50%;
//   display: inline-block;
//   border-top: 3px solid #FFF;
//   border-right: 3px solid transparent;
//   box-sizing: border-box;
//   animation: rotation 1s linear infinite;
// }

// @keyframes rotation {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 36px;
}
.lds-ellipsis div {
  position: absolute;
  top: 16px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: -6;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: -6;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 24px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 50px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

ion-popover ion-content {
  --background: var(--ion-color-danger-shade);
  --color: white;
}

.offline-chip {
  display: flex;
  flex-direction: row;
  column-gap: .325rem;
  text-transform: capitalize;
}

.offline-header {
  position: absolute;
  background-color: var(--ion-color-dark);
  width: 100%;
  left: 0;
  padding: 8px 16px;

  p {
    margin: 0;
  }
}

.addMarginBottom {
  margin-bottom: 1rem;
}

</style>