<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ $t('Seleccionar partida presupuestaria') }}</ion-title>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        :placeholder="$t('Buscar partidas presupuestarias...')"
        v-model="partidaSearch"
      />
      <ion-list style="border-radius: 15px">
        <ion-item
          v-for="(partida, key) in computedPartidas"
          :key="`modal-partida-${key}`"
          @click="$emit('done', partida)"
        >
          <ion-label style="white-space: normal">
            <h3>{{ partida.codigo }} - {{ partida.descripcion }}</h3>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-modal>
</template>

<script>
import { ref } from 'vue';

import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonSearchbar,
} from '@ionic/vue';

export default {
  name: 'MSetPartidasModal',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    data: {
      required: true,
      type: Array,
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonSearchbar,
  },

  computed: {
    computedPartidas() {
      return this.data?.filter((p) =>
        `${p.codigo} - ${p.descripcion}`
          .toLowerCase()
          .includes(this.partidaSearch?.toLowerCase() || '')
      );
    },
  },

  setup() {
    return {
      partidaSearch: ref(null),
    };
  },

  methods: {},
};
</script>