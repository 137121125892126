<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{
            $t('Cancelar')
          }}</ion-button>
        </ion-buttons>

        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <video class="camera" ref="video" autoplay muted playsinline></video>
      <canvas ref="canvas" hidden></canvas>
    </ion-content>
  </ion-modal>
</template>

<script>
import jsQR from 'jsqr';
import Quagga from '@ericblade/quagga2';

import {
  IonButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonContent,
  IonModal,
  toastController
} from '@ionic/vue';

import { ref } from 'vue';

export default {
  name: 'QRScanner',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    title: {
      type: String,
    },

    promptCode: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonContent,
    IonModal
  },

  setup() {
    return {
      stream: ref(null),
    };
  },

  created() {
    if (this.modelValue) {
      this.initCamera();
    }
  },

  watch: {
    modelValue(newValue) {
      /**
       * Si el Modal está abierto (true), cargamos el stream de vídeo para la cámara.
       * Si el Modal está cerrado (false), destruimos el stream previamente creado.
       */

      if (newValue) {
        this.initCamera();
      } else {
        this.stopCamera();
      }
    },
  },

  methods: {
    async initCamera() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: 'environment',
          },
        }).catch(() => {
          this.$emit('update:modelValue', false);
          this.openToastOptions({ header: 'Permisos necesarios', message: 'Se necesitan permisos para acceder a la cámara' })
        });

        if(this.stream) {
          this.$refs.video.srcObject = this.stream;
          requestAnimationFrame(this.scanFrames.bind(this));
        }
      } catch (err) {
        this.$Sentry.captureException(err);
      }
    },

    stopCamera() {
      this.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    },

    async scanFrames() {
      const videoElement = this.$refs.video; // COMPROBANDO SI HAY ELEMENTO DE VÍDEO

      if (videoElement && videoElement.readyState === videoElement.HAVE_ENOUGH_DATA) {
        if (this.modelValue) {
          const canvasElement = this.$refs.canvas;

          canvasElement.height = videoElement.videoHeight;
          canvasElement.width = videoElement.videoWidth;

          const canvasContext = canvasElement.getContext('2d');
          canvasContext.drawImage(
            videoElement,
            0,
            0,
            canvasElement.width,
            canvasElement.height
          );

          const imageData = canvasContext.getImageData(
            0,
            0,
            canvasElement.width,
            canvasElement.height
          );

          const code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: 'dontInvert',
          });

          if (code) {
            if (code.data) {
              this.$emit('success', {
                type: 'qr',
                code: code.data,
              });

              if(this.promptCode) this.openToastOptions({ header: 'Código escaneado', message: code.data, color: 'success' });

              this.$emit('update:modelValue', false);
            } else {
              requestAnimationFrame(this.scanFrames.bind(this));
            }
          } else {
            // COMPROBAMOS BARCODE
            const src = canvasElement.toDataURL();

            const barcode = await Quagga.decodeSingle(
              // 3
              {
                decoder: {
                  readers: [
                    // '2of5_reader',
                    // 'codabar_reader',
                    'code_128_reader',
                    // 'code_32_reader',
                    'code_39_reader',
                    // 'code_39_vin_reader',
                    // 'code_93_reader',
                    // 'ean_8_reader',
                    'ean_reader',
                    // 'i2of5_reader',
                    // 'upc_e_reader',
                    // 'upc_reader',
                  ],
                },
                locate: true,
                src,
              },
              function (result) {
                if (result?.codeResult) {
                  return result.codeResult.code;
                } else {
                  return [];
                }
              }
            );

            if (!Array.isArray(barcode)) {
              if (barcode?.codeResult?.code) {
                this.$emit('success', {
                  type: 'barcode',
                  format: barcode?.codeResult?.format,
                  code: barcode?.codeResult?.code,
                });
                this.$emit('update:modelValue', false);
              } else {
                requestAnimationFrame(this.scanFrames.bind(this));
              }
            } else {
              requestAnimationFrame(this.scanFrames.bind(this));
            }
          }
        }
      } else {
        requestAnimationFrame(this.scanFrames.bind(this));
      }
    },

    async openToastOptions({ header, message, color = 'danger' }) {
      const toast = await toastController.create({
        header,
        message,
        position: 'top',
        color,
        duration: '4000',
        buttons: [
          {
            text: this.$t('Cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },
  },
};
</script>

<style lang="scss" scoped>
.camera {
  height: 100%;
  background: #000;
  width: 100%;
  // object-fit: cover;
}
</style>