import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!

  return (!_ctx.isTextArea)
    ? (_openBlock(), _createBlock(_component_ion_input, {
        key: 0,
        ref: "g_ioninput",
        modelValue: _ctx.copyValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.copyValue) = $event)),
        type: _ctx.type,
        label: _ctx.label,
        "label-placement": _ctx.labelPlacement,
        "helper-text": _ctx.helperText,
        "error-text": _ctx.errorText,
        onIonInput: _cache[1] || (_cache[1] = () => _ctx.updateValue()),
        onIonBlur: _cache[2] || (_cache[2] = () => _ctx.checkAsVisited)
      }, null, 8, ["modelValue", "type", "label", "label-placement", "helper-text", "error-text"]))
    : (_openBlock(), _createBlock(_component_ion_textarea, {
        key: 1,
        ref: "g_iontextarea",
        modelValue: _ctx.copyValue,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.copyValue) = $event)),
        class: "gmao-textarea",
        autoGrow: _ctx.autoGrow,
        disabled: _ctx.disabled,
        placeholder: _ctx.placeholder,
        onIonChange: _cache[4] || (_cache[4] = () => _ctx.updateValue()),
        onIonBlur: _ctx.checkAsVisited,
        "helper-text": _ctx.helperText,
        "error-text": _ctx.errorText,
        debounce: 500
      }, null, 8, ["modelValue", "autoGrow", "disabled", "placeholder", "onIonBlur", "helper-text", "error-text"]))
}