<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ $t('Seleccionar instalacion') }}</ion-title>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-list style="border-radius: 15px">
        <ion-item @click="$emit('done', null)">
          <ion-label>
            <h3 style="font-weight: 500">– {{ $t('Selecciona una solución') }} –</h3>
          </ion-label>
        </ion-item>
        
        <template v-if="$appVersion() > 148">
          <ion-item
            v-for="(sistema, key) in data"
            :key="`sistema-${key}`"
            @click="$emit('done', sistema)"
          >
            <ion-label style="white-space: normal">
              <!-- {{ "›".repeat(s.level) }} -->
              <h3>{{ sistema.name || sistema?.nombre }}</h3>
            </ion-label>
          </ion-item>
        </template>

        <template v-else> <!-- VERSIONES ANTIGUAS A LA 1.4.8 -->
          <SystemTree :systems="data.flat()" @done="(sistema) => $emit('done', sistema)"/>
        </template>
      </ion-list>

    </ion-content>

  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
} from '@ionic/vue';

import SystemTree from '@/components/TreeView/SystemTree.vue';

export default {
  name: 'MSetSistema',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    data: {
      required: true,
      type: Array,
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    SystemTree,
  },

  setup() {
    return {};
  },

  methods: {},
};
</script>