<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ $t('Seleccionar herramienta') }}</ion-title>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        :placeholder="$t('Buscar herramientas...')"
        v-model="toolSearch"
        @ionInput="applyFilters(true)"
        :debounce="500"
      />

      <h5>{{ $t('Todas las herramientas') }}</h5>

      <ion-list fullscreen>
        <template v-for="(page, idx) in tools">
          <ion-item
            v-for="tool in page?.data"
            :key="`modal-tool-${idx}-${tool?.id}`"
            :disabled="
              !editable ||
              ((tool.tecnico && tool?.tecnico?.id_tecnico != gmao.user.id) || tool.no_disponible)
            "
            @click="() => {
              $emit('update:modelValue', false);
              $router.push({ name: 'tool', params: { id: tool.id, name: tool?.nombre } })
            }"
          >
            <ion-label>
              <h3 class="wrap-text">{{ tool.numero_serie ? `${tool.numero_serie} -` : '' }} {{ tool.nombre }}</h3>
              <p class="wrap-text">{{ tool.marca || 'Sin marca' }} - {{ tool.modelo || 'Sin modelo' }}</p>
              <p class="wrap-text" v-if="tool.observaciones">{{ tool.observaciones }}</p>
            </ion-label>

            <ion-buttons slot="end">
              <template v-if="tool.tecnico">
                <ion-icon :icon="person" class="f20"></ion-icon>
                <!-- <ion-label>{{ tool.tecnico.tecnico.nombre_completo }}</ion-label> -->
              </template>

              <template v-else-if="tool.no_disponible">
                <ion-icon :icon="banOutline" class="f20"></ion-icon>
                <!-- <ion-label>{{ $t('no-disponible') }}</ion-label> -->
              </template>

              <template v-else>
                <ion-icon :icon="chevronForward" class="f20"></ion-icon>
              </template>
            </ion-buttons>
          </ion-item>
        </template>
      </ion-list>

      <ion-infinite-scroll
        threshold="100px"
        id="infinite-scroll"
        @ionInfinite="onLoad($event)"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('Cargando más herramientas...')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>

  </ion-modal>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';

// Stores
import { useGmaoStore } from '@/stores/gmao';

// EXPORT MODULE
import { SQLiteDBConnection } from 'vue-sqlite-hook/dist';
import { useOfflineStore } from '@/stores/offline';

import {
  banOutline,
  person,
} from 'ionicons/icons';

import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
} from '@ionic/vue';

export default {
  name: 'MSearchTools',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    editable: {
      type: Boolean,
      required: false,
    },

    parte: {
      type: String,
      required: false,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
  },

  setup() {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();

    const app = getCurrentInstance();

    return {
      toolSearch: ref(null),
      tools: ref([]),

      current_page: ref(1),
      last_page: ref(null),
      total: ref(null),
      loading: ref(null),

      // Icons
      banOutline,
      person,

      // Stores
      gmao,
      offline,

      // OTHER
      app,
    };
  },

  watch: {
    modelValue(ev) {
      if (ev) this.applyFilters(true);
    },
  },

  methods: {
    /** PAGINATION */
    onLoad(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFilters(false).then(() => {
          event.target.complete();
        });
      } else {
        event.target.complete();
      }
    },

    async applyFilters(reset = true) {
      if (reset) {
        this.tools = [];
        this.current_page = 1;
      }

      return this.getTools();
    },

    async getTools() {
      this.loading = true;
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getHerramientas',
            token: this.gmao.user.token,
            value: this.gmao.user.id,
            parte: this.parte,
            page: this.current_page,
            searchWH: this.toolSearch,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.tools.push(data);

          this.current_page = data.current_page;
          this.last_page = data.last_page;
          this.total = data.total;
        })
        .catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();
              const query = `
                SELECT * FROM herramientas A
                ${
                  this.toolSearch || this.selectedTool.activo
                    ? `
                  WHERE
                    ${
                      this.toolSearch
                        ? `
                      nombre LIKE '%${this.toolSearch}%' OR codigo LIKE '%${this.toolSearch}%'
                    `
                        : ''
                    }
                    ${this.toolSearch && this.selectedTool.activo ? 'AND' : ''}
                    ${
                      this.selectedTool.activo
                        ? `
                      EXISTS (SELECT * FROM repuestos_en_maquina R WHERE R.id_maquina=${this.selectedTool.activo} AND R.id_articulo=A.id)
                    `
                        : ''
                    }
                `
                    : ''
                }
              `;

              const getToolsPromise = new Promise((resolve) => {
                resolve(
                  this.$SQLiteQuery(
                    'getTools_workorder',
                    'partes',
                    query,
                    e.config,
                    db
                  )
                );
              });
              getToolsPromise.then(() => {
                setTimeout(() => {
                  // this.setActivos = false;
                  sqlite.closeConnection('gmaoTecnicos').then(async () => {
                    this.tools.push(this.offline.selTools);
                    // await this.getWorkorder(this.$route.params.id).then(async () => {
                    // });
                  });
                }, 100);
              });
            } catch (err) {
              // this.$Sentry.withScope(function(scope) {

              //   scope.setTag("offline", "Workorder: GET Materiales");

              //   scope.setFingerprint([err.name, err.message, String(err.stack)]);

              // });
                this.$Sentry.captureException(err);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

  },
};
</script>