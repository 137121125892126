<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ $t('Seleccionar lote') }}</ion-title>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-list style="border-radius: 15px; margin-bottom: 100px">
        <template v-if="data?.length">
          <ion-item
            v-for="(lote, key) in data"
            :key="`almacen-lotes-${key}`"
            @click="$emit('done', lote)"
          >
            <ion-label class="ion-text-wrap">
              {{ lote.lote.codigo || 'Sin código' }}
            </ion-label>
          </ion-item>
        </template>
        <template v-else>
            <ion-label>
              {{ $t('Sin lotes') }}
            </ion-label>
        </template>
      </ion-list>
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
} from '@ionic/vue';

export default {
  name: 'MSetMaterialLotes',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    data: {
      type: Array,
      required: true,
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
  },

  setup() {

    return {};
  },

  methods: {},
};
</script>