import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import './registerServiceWorker';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/gmao.css';
import './theme/transitions.css';

/* Virtual Scroller */
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import VueVirtualScroller from 'vue-virtual-scroller';

/* Axios */
import axios, { AxiosError } from 'axios';

/* Moment */
// import * as moment from 'moment';

/* Timezone */
import moment from 'moment-timezone';

/* Pinia */
import { createPinia } from 'pinia'

/* Signature */
import VueSignaturePad from 'vue-signature-pad';

/* i18n */
import { createI18n } from 'vue-i18n';

/* Capacitor Camera */
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

/* PWA Elements */
import { defineCustomElements } from '@ionic/pwa-elements/loader';
defineCustomElements(window);

import messages from '@/i18n';

const i18n:any = createI18n({
  locale: navigator.language,
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
})


const app = createApp(App)
  .use(IonicVue, {
    innerHTMLTemplatesEnabled: true,
  })
  .use(router)
  .use(VueSignaturePad)
  .use(i18n)
  .use(VueVirtualScroller)
  .use(createPinia());

// PINIA GMAO
import { useGmaoStore } from '@/stores/gmao';
const gmao: any = useGmaoStore();

// PINIA TRADUCCIONES
import { useLangStore } from '@/stores/lang';
const lang: any = useLangStore();

// PINIA WORKORDERS
import { useWorkOrdersStore } from '@/stores/workorders';
const wo:any = useWorkOrdersStore();

i18n.global.locale = gmao.user?.lang || navigator.language || 'en'

// SOBREESCRIBIR LA FUNCIÓN $t DE i18n PARA INCLUIR TRADUCCIONES
const t = i18n.global.t;
i18n.global.t = (key: string | undefined = '') => {
  return lang?.trans && lang?.trans?.find((trans: any) => trans?.campo == key)?.mensaje || t(key);
}

axios.defaults.params = {}
axios.defaults.params['version'] = gmao.v;

axios.interceptors.response.use((response) => {
  if(response?.data?.error == '500') {
    console.log(response?.data?.message);
  }

  return response;
});

app.provide('axios', axios);


/* Onesignal */
import OneSignal from 'onesignal-cordova-plugin';
document.addEventListener("deviceready", OneSignalInit, false);

function OneSignalInit(): void {
  OneSignal.setAppId("045d387e-728a-4aab-bb7c-4565ed97b041");
  OneSignal.setNotificationOpenedHandler(function(jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
  });

  OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      console.log("User accepted notifications: " + accepted);
  });

  OneSignal.getDeviceState((state) => {
    app.config.globalProperties.$oneSignalState = state;
  });
}

/* Sentry */
// import * as Sentry from "@sentry/capacitor";
import * as SentryVue from "@sentry/vue";
import * as SentryBrowser from '@sentry/browser';
// import { Integrations } from '@sentry/tracing';

SentryVue.setContext("character", {
  user: gmao?.user?.email,
  server: gmao?.workspace?.descripcion,
  version: gmao?.v,
});

SentryVue.init({
	app,
	dsn: 'https://c008d84b9ff0ac7d5a0908e81ce1010f@sentry.tecneca.com/3',
	integrations: [
		new SentryBrowser.BrowserTracing({
			routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "app.gmaocloud.es", /^\//],
		}),
    new SentryVue.Integrations.Breadcrumbs({ console: false }),
    new SentryVue.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
	],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0
});

/* Global vars */
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$moment = (_dateInput = moment(), format:boolean = false) => {

  const dateFormats = (locale:string) => {
    let l = null;
    switch (locale) {
      case 'en-US':
      case 'en':
        l = 'MM/DD/YYYY';
        break;

      default:
        l = 'DD/MM/YYYY';
        break;
    }
    return l;
  };

  const locale = navigator.language || 'es';

  if (format) return moment(_dateInput, dateFormats(locale)).locale(locale);
  return moment(_dateInput).locale(locale);
};
app.config.globalProperties.$oneSignal = OneSignal;
app.config.globalProperties.$Camera = Camera;
app.config.globalProperties.$CameraResultType = CameraResultType;
app.config.globalProperties.$CameraSource = CameraSource;
app.config.globalProperties.$Sentry = SentryVue;

/* Object to FormData */

app.config.globalProperties.$objectToFormData = (data: object) => {
  const formdata = new FormData();

  for (const [key, value] of Object.entries(data)) {
    formdata.append(key, (value || ''));
  }

  return formdata;
}

import { alertController } from '@ionic/vue';

app.config.globalProperties.$showDeleteAlertController = async (
  header: string = t('generic_delete_alert_title'),
  message: string = t('generic_delete_alert_message'),
  callback: () => void,
  { cancel, confirm } = { cancel: 'cancelar', confirm: 'yes_delete' }
) => {
  const alert = await alertController.create({
    cssClass: 'my-custom-class',
    header,
    message: t(message),
    buttons: [
      {
        text: t(cancel),
        role: 'cancel',
        cssClass: 'secondary',
        id: 'cancel-button',
      },
      {
        text: t(confirm),
        id: 'confirm-button',
        handler: callback,
      },
    ],
  });

  await alert.present();
}

app.config.globalProperties.$alertController = async (header: string, message: string|undefined = undefined, callback: () => void,
  { cancel, confirm } = { cancel: 'cancelar', confirm: 'Confirmar' }
) => {
  const alert = await alertController.create({
    cssClass: 'my-custom-class',
    header,
    message: message,
    buttons: [
      {
        text: cancel,
        role: 'cancel',
        cssClass: 'secondary',
        id: 'cancel-button',
      },
      {
        text: confirm,
        id: 'confirm-button',
        handler: callback,
      },
    ],
  });

  await alert.present();
}

/**
 * XXX: NOTE: Simulación del controlador de errores de la aplicacion.

 * A futuro, cuando se haga la migración con el nuevo offline -> migrar esta funcion alli
 * REVIEW: IDEA -> Pasar el control del offline de las vistas a un controlador a parte mediante esta funcion.
 */
app.config.globalProperties.$errorController = async (e: AxiosError | undefined = undefined, error: boolean = true) => {
  console.log('PROXIMAMENTE IMPLEMENTACION: ', e);

  if (!error) {
    /**
     * NOTE: Esto es temporal. Sirve para mostrar un mensaje de un registro recien borrado.
     */
    await app.config.globalProperties.$openToastObject(
      t('delete_success_title'),
      undefined,
      'success',
      'bottom'
    );
  } else {
    await app.config.globalProperties.$openToastObject(t('Ha ocurrido un error'), t('Ha habido un error al procesar las solicitud'), 'danger', 'bottom');
  }
}

/** GEOLOCATION */
import { Geolocation } from '@capacitor/geolocation';

/** FIXME: ANALOGO DE LA FUNCION getGeolocation en TimeToggle.vue */

/**
 * CHECK GEO PERMISSIONS: asks for Geolocation permissions regarding the device type.

 * => Technical POV:
 *    - Firstly we check for the Geolocation permissions.
 *      -> IF 'granted': return true
 *      -> Otherwise (denied, prompt):
 *        + We show a Toaster Object <- (error message)
 *        + We check for the device and request permissions. FIXME: *** *Does this make sense?

 */
app.config.globalProperties.$checkGeoPermissions = async (interruptProcess:boolean = true) => {
  let permissions:any = await Geolocation.checkPermissions();

  if (permissions?.location === 'granted') return true;

  if (['denied', 'prompt'].includes(permissions?.location)) {
    try {

      /** Mostramos al usuario un mensaje que no tiene permisos y los necesita
       *  para seguir usando la app.
       */
      await app.config.globalProperties.$openToastObject(
        i18n.global.t('error-con-los-permisos'),
        `${i18n.global.t('no-tienes-permisos-de-geolocalizacion')}`,
        'danger',
        'top',
        '4000',
        [
          {
            text: i18n.global.t('help'),
            role: 'cancel',
            handler: () => {
              window.open('https://gmao-cloud.notion.site/WEB-v2-Permitir-permisos-geolocalizaci-n-c0abcf9f429943fba6ab461e1ea020c7');
            }
          }
        ]
      );

      /**
       * Si están negados los permisos.
       * Los volvemos a pedir segun el tipo de dispositivo
       *  -> Catch(...): lanzamos excepcion para informar al usuario de que no tiene permisos...
       */

      if (interruptProcess) {
        // FIXME: *** *REVISAR: Esto es raro, porque si no tengo permisos y los vuelvo a solicitar va a fallar siempre...
          // No se hasta que punto eso tiene sentido...
        if (typeof navigator === 'undefined') {
          permissions = await Geolocation.requestPermissions();
        } else {
          permissions = await new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, async (e) => {

            app.config.globalProperties.$Sentry.captureException(`WEB:checkGeoPermissions::${e.message}`);

            reject(new Error(e.message));

          }));
        }

        // FIXME: *** *REVISAR: si es objeto de permisos -> true
        return !!permissions;
      }

      return false;

    } catch (error) {
      await app.config.globalProperties.$openToastObject(
        i18n.global.t('error-con-los-permisos'),
        i18n.global.t('no-tienes-permisos-de-geolocalizacion'),
        'danger',
        'top',
        '4000',
        [
          {
            text: i18n.global.t('help'),
            role: 'cancel',
            handler: () => {
              window.open('https://gmao-cloud.notion.site/WEB-v2-Permitir-permisos-geolocalizaci-n-c0abcf9f429943fba6ab461e1ea020c7');
            }
          }
        ]
      );

      app.config.globalProperties.$Sentry.captureException(new Error(`Error:checkGeoPermissions::${error}`));
      if (interruptProcess) throw new Error(`Error:checkGeoPermissions::${error}`); // FIXME: Se usa para cortar toda ejecucion posterior. Motivo: TimeToggle.vue
    }
  }

};

/**
 * GET GEOLOCATION: returns a GeolocationPosition class of the device.

 * => Technical POV:
 *    - We create two promises (geolocationPromise, timeoutPromise):
 *      1. 'geolocationPromise' is for the Geolocation position (with device distinction)
 *      2. 'timeoutPromise' is for the Timeout dismiss -> loadingController.
 *    - Then we do a Promise.race() to resolve the promise that finishes first.
 *
 *    + With this approach if any error occurs OR it takes too long (TIMEOUT) to get the GEO position
 *      It will send an Error to Sentry and dismiss the loadingController, so user can continue with the work.

 *    + If previous to get the Geolocation we need to check for the permissions -> checkPermissions=true
        If the user does not have the permissions it won't get any GEO position.

 *    ** Regarding the 'checkPermissions' flag. This is done because in the app sometimes we directly do not
          check for the permissions - we get the position straightforward: e.g when open a WO

 * @param checkPermissions
 * @returns GeolocationPosition position
 */
app.config.globalProperties.$getGeolocation = async (checkPermissions: boolean = false, interruption:boolean = true): Promise<any> => {
  if (checkPermissions && !(await app.config.globalProperties.$checkGeoPermissions(interruption))) {return;}

  const loader = await loadingController.create({
    message: i18n.global.t('Geolocalizando'),
  });
  await loader.present();

  const TIMEOUT = 10000;
  const device = platform;

  const geolocationPromise = new Promise((resolve, reject) => {
    if (device.includes('mobile') || device.includes('mobileweb')) {
      resolve(Geolocation?.getCurrentPosition());
    } else return navigator.geolocation.getCurrentPosition(resolve, reject);

  });

  const timeoutPromise = new Promise((_, reject) => setTimeout(() => {
    reject(new Error('Geolocation Timeout'));
  }, TIMEOUT));

  try {
    const position = await Promise.race([geolocationPromise, timeoutPromise]);

    // NOTE: DEBUG
    // console.log('$getGeolocation::POSITION: ', position);

    return position;
  } catch (err) {
    let networkInfo = { connected: navigator.onLine, connectionType: 'unknown' };
    
    if (Capacitor.isNativePlatform()) networkInfo = await Network.getStatus();
    else if ((navigator as any).connection) {
      networkInfo = {
        connected: navigator.onLine,
        connectionType: (navigator as any).connection?.type || (navigator as any).connection?.effectiveType || 'unknown',
      };
    } else {
      networkInfo = {
          connected: navigator.onLine,
          connectionType: 'unknown'
      };
    }

    const geoPermissionStatus = await app.config.globalProperties.$checkGeoPermissions(interruption);
    
    app.config.globalProperties.$Sentry.captureException(err, {
      extra: {
        errorType: 'Geolocation Timeout',
        networkInfo,
        permissions: {
          geolocationGranted: geoPermissionStatus,
        },
      },
    });
    
    return {};

  } finally {
    /** En caso de que falle o no siempre llegará aquí. */
    await loader?.dismiss();
  }

};

interface timeObject {
  inicio: string;
  fin: string;
}
interface dateObject {
  desde: string;
  hasta: string;
}

/* Validate time/date */
app.config.globalProperties.$isTimeValid = async (time: timeObject, date:dateObject) => {
    const startTime = time.inicio;
    const endTime = time.fin;

    const startDateTime = moment(date?.desde);
    const endDateTime = moment(date?.hasta);

    const isEndBeforeStartHour = moment(endTime, 'hh:mm').isBefore(moment(startTime, 'hh:mm'));
    const isEndBeforeStartDate = moment(endDateTime, 'YYYY-MM-DD').isBefore(moment(startDateTime, 'YYYY-MM-DD'));
    const isSameDate = startDateTime.isSame(endDateTime, 'day');


    if (isEndBeforeStartHour && isSameDate) {
      await app.config.globalProperties.$openToastObject(
        i18n.global.t('Ha ocurrido un error'),
        i18n.global.t('El tiempo de fin no puede ser menor al de inicio'), 'danger', 'bottom');
      return false;
    }
    if (isEndBeforeStartDate) {
      await app.config.globalProperties.$openToastObject(
        i18n.global.t('Ha ocurrido un error'),
        i18n.global.t('La fecha de fin no puede ser menor a la de inicio'), 'danger', 'bottom');
      return false;
    }


    return true;

    // Edit
    // if (this.newHora.id) {
    //   if (this.validTime) {
    //     if(validateTo) {
    //       console.log('VALIDATE');
    //       if(validateTo == 'from') {
    //         this.$emit('update:newHora', { from: value });
    //       } else {
    //         this.$emit('update:newHora', { to: value });
    //       }
    //     } else {
    //       console.log('NO VALIDATE');
    //       this.$emit('update:newHora', { from: value });
    //       this.$emit('update:newHora', { to: value });
    //     }
    //   }
    // }
  };

/* Base64 to Blob */
app.config.globalProperties.$base64toBlob = (b64Data: string, contentType = 'image/png', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
};

app.config.globalProperties.$appVersion = () => {
  return +(gmao.v || '0').replace(/\./g, '');
};

app.config.globalProperties.$timeFormatter = (time: number) => {
  return {
    hours: Math.floor(time / 3600),
    minutes: String(Math.floor((time % 3600) / 60)).padStart(2, '0'),
    seconds: String((time % 3600) % 60).padStart(2, '0')
  }
};

app.config.globalProperties.$getBase64Image = async (url: string, contentType = 'image/png') => {
  return new Promise((resolve) => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.src = url;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      if(ctx) {
        ctx.drawImage(img, 0, 0);
        const data = canvas.toDataURL(contentType);
        resolve(data);
      }
    }
  });
}

/* Compressor JS */
import Compressor from 'compressorjs';

app.config.globalProperties.$compressImage = async (file: File | Blob, resolve: () => void, reject: () => void) => {
  new Compressor(file, {
    quality: 1,
    maxWidth: 1024,

    success: resolve,
    error: reject
  });
}

type Permission = {
  todos: number,
  ver: number,
  crear: number,
  editar: number,
  borrar: number,
};

app.config.globalProperties.$checkPermissions = (data: Permission, permission: string) => {
  switch(permission) {
    case 'ver': return data?.todos || data?.ver;
    case 'crear': return data?.todos || data?.crear;
    case 'editar': return data?.todos || data?.editar;
    case 'borrar': return data?.todos || data?.borrar;
  }

  return false;
};

app.config.globalProperties.$hasPermissions = (label: string, permission: string) => {
  const permisos = gmao?.user?.permisos;
  const permisosG = gmao?.user?.permisosG;

  if(typeof permisos?.[label]?.[permission] !== 'undefined' || typeof permisos?.[label]?.todos !== 'undefined') {
    return permisos?.[label]?.[permission] || permisos?.[label]?.todos;
  }

  return permisosG?.[label]?.[permission] || permisosG?.[label]?.todos;
}

// ==============================================================================
/**                                   OFFLINE                                  */
// ==============================================================================

// import { useState } from '@/composables/state';
import { defineCustomElements as jeepSqlite, applyPolyfills } from "jeep-sqlite/loader";
import { Capacitor } from '@capacitor/core';
import { CapacitorSQLite, SQLiteConnection, SQLiteDBConnection } from '@capacitor-community/sqlite';

import { downloadOffline } from '@/utils/DBSQLite/syncOffline';

// OFFLINE STORE
import { useOfflineStore } from '@/stores/offline';
const offline: any = useOfflineStore();

// Network API
import { Network } from '@capacitor/network';

app.config.globalProperties.$Network = Network;

Network.addListener('networkStatusChange', async (status) => {
  console.log('*** Network status changed', status);
  offline.status = !status.connected;
  if (status.connected && offline.counterQueue > 0) {
    offline.loading = await loadingController.create({
      message: 'Sincronizando datos...',
    });
    offline.loading.present();

    const promesa = new Promise((resolve) => {
      const fun = app.config.globalProperties.$syncAllData();
      resolve(fun);
    });

    setTimeout(() => {
      offline.loading.dismiss();
    }, 20000);

    promesa.catch((err) => {
      app.config.globalProperties.$openToastObject(
        'Ha habido un error al sincronizar',
        'Ha ocurrido un error en el servidor'
      );
      console.error(err);
    });

  }
});

// GLOBAL APP FUNCTIONS

import { loadingController, toastController } from '@ionic/vue';

app.config.globalProperties.$openToastObject = async (header:string, message: string = '', color:any = 'danger', position:any = "top", duration:any = '4000', buttons:any = []) => {
  const toast = await toastController.create({
    header,
    message,
    position,
    color,
    duration,
    buttons
  });

  await toast.present();
}

// Control loaders
if (offline.showLoader) {
  setTimeout(() => {
    offline.showLoader = false;
  }, 15000);
}
if (offline.loading?.presented) {
  setTimeout(() => {
    offline.loading?.dismiss();
  }, 15000);
}

// OFFLINE CONTROLLERS
import setController from '@/utils/DBSQLite/setQueries';
import getController from '@/utils/DBSQLite/getQueries';

applyPolyfills().then(() => {
    jeepSqlite(window);
});

// Check Platform and init stores
const platform = Capacitor.getPlatform();
let sqlite: SQLiteConnection;

app.config.globalProperties.$cacheDataToOffline = async (table: string, data: any) => {
  console.log(table, data);
  if (!table.length && (Array.isArray(data) && !data?.length || !Object.entries(data)?.length)) {
    console.error(`Data está vacio:: ${data}`);
    return;
  }

  const promesasPila: any[] = [];

  try {
      const ret = await sqlite.checkConnectionsConsistency();
      const isConn = (await sqlite.isConnection("gmaoTecnicos", false)).result;

      console.log(`CACHE: Connection Consistency ${ret.result} and Connection ${isConn}`);

      let db: SQLiteDBConnection
      if (ret.result && isConn) {
        db = await sqlite.retrieveConnection("gmaoTecnicos", false);
      } else {
        db = await sqlite.createConnection("gmaoTecnicos", false, "no-encryption", 1, false);
      }
      await db.open();

      if (table === 'partes') {
        const keysToCache = [
          {
            relation: 'maquinas',
            table: 'maquinas',
            columns: []
          },
          {
            relation: 'maquinas_respuesta',
            table: 'modelos_parte_campos_respuestas',
            columns: []
          },
          {
            relation: 'materiales',
            table: 'articulos',
            columns: []
          },
          {
            relation: 'sistema',
            table: 'sistemas',
            columns: []
          },
        ];
        let queryParte: string = '';

        db.beginTransaction();

        queryParte = `
          INSERT OR REPLACE INTO partes (id, id_estado_actual, estado_actual, id_tecnico, id_direccion, id_proyecto, id_tipo, problema, fecha, created_at, updated_at)
          VALUES (
            ${data.id},
            ${data.id_estado_actual},
            '${JSON.stringify(data.estado_actual)}',
            ${data.id_tecnico},
            ${data.id_direccion},
            ${data.id_proyecto},
            ${data.id_tipo},
            '${data.problema}',
            '${data.fecha}',
            '${data.created_at}',
            '${data.updated_at}'
          );
        `;

        if (queryParte.length) {
          promesasPila.push(new Promise((resolve) => {
            const item = db.execute(queryParte, false);
            resolve(item);
          }))
        }

        (Object.keys(data) || []).forEach((k: any) => {
          const model = keysToCache.find((a) => a.relation === k);
          
          if (model) {
            const entry = data[k];
            if (k === 'maquinas') {
              let queryMaquinas: string = '';
              (entry || []).map((e:any) => {
                delete(e.documentos);
                delete(e.hijas);
                delete(e.imagenes);
                

                const newObj = {
                  id: e.pivot.id,
                  pivot: e.pivot,
                  id_parte: e.pivot.id_parte,
                  id_maquina: e.pivot.id_maquina,
                  tareas_completadas: e.pivot.tareas_completadas,
                  id_modelo_parte: e.pivot.id_modelo_parte,
                  t_estimado: e.pivot.t_estimado,
                  orden: e.pivot.orden,
                }

                const columns = Object.keys(newObj)
                const values = Object.values(newObj).map((a:any) => {
                  // Si el valor es null, undefined, una cadena vacía o una cadena que solo tiene espacios
                  if (a === null || a === undefined || (typeof a === 'string' && !a.trim().length)) {
                    return '""';
                  } 
                  // Si el valor es una cadena no vacía
                  else if (typeof a === 'string') {
                    return `"${a}"`;
                  } 
                  // Si el valor es un objeto o un array no vacío
                  else if (typeof a === 'object' && (Array.isArray(a) || Object.keys(a).length)) {
                    return `'${JSON.stringify(a)}'`;
                  }

                  // Para todos los demás valores (incluyendo números), devolver el valor tal cual
                  return a;
                });
                queryMaquinas = `
                INSERT OR REPLACE INTO parte_maquinas (${columns.toString()}) VALUES
                  (${values.toString()});
                `;
              });

              if (queryMaquinas.length) {
                promesasPila.push(new Promise((resolve) => {
                  const item = db.run(queryMaquinas, [], false);
                  resolve(item);
                }));

              }

              const checklists = entry.map((r:any) => r.respuestas).flat();

              let queryRespuestas: string = '';
              
              (checklists || []).forEach((c:any) => {
                
                const columns = Object.keys(c);
                const values = Object.values(c).map((a:any) => {
                  // Si el valor es null, undefined, una cadena vacía o una cadena que solo tiene espacios
                  if (a === null || a === undefined || (typeof a === 'string' && !a.trim().length)) {
                    return '""';
                  } 
                  // Si el valor es una cadena no vacía
                  else if (typeof a === 'string') {
                    return `"${a}"`;
                  } 
                  // Si el valor es un objeto o un array no vacío
                  else if (typeof a === 'object' && (Array.isArray(a) || Object.keys(a).length)) {
                    return `'${JSON.stringify(a)}'`;
                  }

                  // Para todos los demás valores (incluyendo números), devolver el valor tal cual
                  return a;
                });
                

                queryRespuestas = ` INSERT OR REPLACE INTO modelos_parte_campos_respuestas (${columns.toString()}) VALUES (${values.toString()});`;

                if (queryRespuestas.length) {
                  promesasPila.push(new Promise((resolve) => {
                    const item = db.run(queryRespuestas, [], false);
                    resolve(item);
                  }));
                }

              });
            }
          }
        });

        Promise.all(promesasPila)
          .then((a) => {
            console.log(a);
            db.commitTransaction();
          }).catch((e) => {
            const error = ensureError(e);
            db.rollbackTransaction();
            throw new Error('Chaching WO', { cause: error });
          }).finally(() => {
            db.close();
          });
      }

    } catch (error) {
      console.error(`Ha ocurrido un error al cachear:: ${error}`);
    } finally {
      // sqlite.closeAllConnections();
    }
};

const ensureError = (value: unknown): Error => {
  if (value instanceof Error) return value

  let stringified = '[Unable to stringify the thrown value]'
  try {
    stringified = JSON.stringify(value)
  } catch { /* empty */ }

  const error = new Error(`This value was thrown as is, not through an Error: ${stringified}`)
  return error
}

window.addEventListener('DOMContentLoaded', async () => {
  try {
    // platform = Capacitor.getPlatform();
    sqlite = new SQLiteConnection(CapacitorSQLite);
    if(platform === "web") {
      // Create the 'jeep-sqlite' Stencil component
      const jeepSqlite = document.createElement('jeep-sqlite');
      // jeepSqlite.setAttribute("wasmpath", "public/assets");
      document.body.appendChild(jeepSqlite);
      await customElements.whenDefined('jeep-sqlite');


      // Initialize the Web store
      await sqlite.initWebStore();

    } else if (platform === 'android') {
      try {
        const sqlite = CapacitorSQLite as any;
        await sqlite.requestPermissions();
      } catch (e) {
        console.log('No access to DB', e);
      }
    } else if (platform === 'ios') {
      // IOS permissions TODO:
    }
  } catch (e:any) {
    // throw new Error(`Error DOM: ${e}`)
    SentryVue.withScope(function(scope) {

      scope.setTag("sqlite", "main: DOMContentLoaded");

      scope.setFingerprint([e.name, e.message, String(e.stack)]);

      SentryVue.captureException(e);
    });
  }
});

app.config.globalProperties.$buildSQLiteDB = async (table: string, data: any, sql: SQLiteConnection) => {
  try {
    // Check DB Connection and Open DB
    const ret = await sql.checkConnectionsConsistency();
    const isConn = (await sql.isConnection("gmaoTecnicos", false)).result;

    console.log(`BUILD: Connection Consistency ${ret.result} and Connection ${isConn}`);

    let db: SQLiteDBConnection
    if (ret.result && isConn) {
      db = await sql.retrieveConnection("gmaoTecnicos", false);
    } else {
      db = await sql.createConnection("gmaoTecnicos", false, "no-encryption", 1, false);
    }
    await db.open();

    // =======================================================
    if (Array.isArray(data)) {
      // Gestion de tablas relación...
      if (table == 'direcciones') {
        const partes = [...new Set(data.map((p: any) => p.limit_partes).flat())] || [];
        const sistemas = [...new Set(data.map((p: any) => p.sistemas).flat())] || [];
        const dirClientes = [...new Set(data.map((p: any) => p.cliente).flat())] || [];
        const dirDoc = [...new Set(data.map((p: any) => p?.documentos).flat())] || [];
        const cliDoc = dirClientes.filter(a => a).map((p: any) => p?.documentos).flat() || [];
        const documentos = [...new Set(dirDoc.concat(cliDoc))];

        const clientes = (dirClientes.filter(a => a) || [])?.map((c) => {
          c.documentos = null;
          return c;
        });

        partes.forEach(async (parte: any) => {
          const columns = Object.keys(parte)
          const val = columns.map(() => {
            return '?';
          }).toString();
          const query = `INSERT OR IGNORE INTO partes (${columns.toString()}) VALUES (${val})`;
          let values = Object.values(parte);
          values = values.map((v) => {
            // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
            //   v = `'${v}'`
            // }
            if (typeof v == 'object' && v != null) {
              v = JSON.stringify(v);
            }
            if (v == null) {
              v = null;
            }
            return v;
          });
          await db.query(query, values);
        });
        sistemas.forEach(async (s: any) => {
          const columns = Object.keys(s)
          const val = columns.map(() => {
            return '?';
          }).toString();
          const query = `INSERT OR IGNORE INTO sistemas (${columns.toString()}) VALUES (${val})`;

          let values = Object.values(s);
          values = values.map((v) => {
            // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
            //   v = `'${v}'`
            // }
            if (typeof v == 'object' && v != null) {
              v = JSON.stringify(v);
            }
            if (v == null) {
              v = null;
            }
            return v;
          });
          await db.query(query, values);
        });

        documentos.forEach(async (d: any) => {
          const columns = Object.keys(d)
          const val = columns.map(() => {
            return '?';
          }).toString();
          const query = `INSERT OR IGNORE INTO documentos (${columns.toString()}) VALUES (${val})`;
          let values = Object.values(d);
          values = values.map((v) => {
            // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
            //   v = `'${v}'`
            // }
            if (typeof v == 'object' && v != null) {
              v = JSON.stringify(v);
            }
            if (v == null) {
              v = null;
            }
            return v;
          });
          await db.query(query, values);
        });
        clientes.forEach(async (d: any) => {
          const columns = Object.keys(d)
          const val = columns.map(() => {
            return '?';
          }).toString();
          const query = `INSERT OR IGNORE INTO clientes (${columns.toString()}) VALUES (${val})`;
          let values = Object.values(d);
          values = values.map((v) => {
            // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
            //   v = `'${v}'`
            // }
            if (typeof v == 'object' && v != null) {
              v = JSON.stringify(v);
            }
            if (v == null) {
              v = null;
            }
            return v;
          });
          await db.query(query, values);
        });

        if (cliDoc.length) {
          cliDoc.forEach(async (d: any) => {
            const columns = Object.keys(d)
            const val = columns.map(() => {
              return '?';
            }).toString();
            const query = `INSERT OR IGNORE INTO documentos (${columns.toString()}) VALUES (${val})`;
            let values = Object.values(d);
            values = values.map((v) => {
              // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
              //   v = `'${v}'`
              // }
              if (typeof v == 'object' && v != null) {
                v = JSON.stringify(v);
              }
              if (v == null) {
                v = null;
              }
              return v;
            });
            await db.query(query, values);
          });
        }
      }

      // Tabla de Articulos
      // if (table == 'articulos') {
      //   const almacenes = data.map((p) => p.almacenes).flat();

      //   (almacenes || []).forEach(async (al: any, index: number) => {
      //     al.id = index+1;
      //     al.id_almacen = al.pivot.id_almacen;
      //     al.id_articulo = al.pivot.id_articulo;
      //     al.stock = al.pivot.stock;
      //     al.ubicacion = al.pivot?.ubicacion;

      //     const columns = Object.keys(al)
      //     const val = columns.map(() => {
      //       return '?';
      //     }).toString();
      //     const query = `INSERT INTO almacen_articulos (${columns.toString()}) VALUES (${val})`;
      //     let values = Object.values(al);

      //     values = values.map((v) => {
      //       // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
      //       //     v = `'${v}'`
      //       //   }
      //         if (typeof v == 'object' && v != null) {
      //           v = JSON.stringify(v);
      //         }
      //         if (v == null) {
      //           v = null;
      //         }
      //         return v;
      //     });
      //     await db.query(query, values);
      //   });
      // }

      // Tabla FAQ Respuestas
      if (table == 'faq_respuestas') {
        const materiales = [...new Set(data.map((p) => p.materiales).flat())];

        materiales.forEach(async (material: any) => {
          const columns = Object.keys(material)
          const val = columns.map(() => {
            return '?';
          }).toString();
          const query = `INSERT OR IGNORE INTO faq_materiales_respuestas (${columns.toString()}) VALUES (${val})`;
          let values = Object.values(material);
          values = values.map((v) => {
            // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
            //   v = `'${v}'`
            // }
            if (typeof v == 'object' && v != null) {
              v = JSON.stringify(v);
            }
            if (v == null) {
              v = null;
            }
            return v;
          });
          await db.query(query, values);
        });
      }
      data.forEach(async (d:any) => {
        const columns = Object.keys(d);
        const values = columns.map(() => {
          return '?';
        }).toString();
        const sqlcmd = `INSERT OR REPLACE INTO ${table} (${columns.toString()}) VALUES (${values})`;
        
        // Tabla de Direcciones - Borrado de campos "with"
        if (table == 'direcciones') {
          d.partes = null;
          d.sistemas = null;
          d.documentos = null;
          d.cliente = null;
          d.documentos = null;
        }

        // Tabla de Articulos - Borrado de campos "with"
        if (table == 'articulos') {
          d.almacenes = null;
        }

        // Tabla de Articulos - Borrado de campos "with"
        if (table == 'faq_respuestas') {
          d.materiales = null;
        }

        // Tabla de Partes ***
        if (table == 'partes') {
          const maquinas_respuestas = [...new Set(data.map((p) => p.maquinas_respuestas).flat())];
          const maquinas = [...new Set(data.map((p) => p.maquinas).flat())];
          const maquinaRespuestas = [...new Set(maquinas.map((p) => p.respuestas).flat())];
          const imagenes = [...new Set(data.map((p) => p.imagenes).flat())];
          const tecnicos = [...new Set(data.map((p) => p.tecnicos).flat())];
          const lineas = [...new Set(data.map((p) => p.lineas).flat())];
          const horas = [...new Set(data.map((p) => p.horas).flat())];
          const comunicaciones = [...new Set(data.map((p) => p.comunicaciones).flat())];
          const firmas = [...new Set(data.map((p) => [p.firma, p.firma_tecnico]).flat())];

          // this.offline.horas = horas; ***

          horas.filter((i) => i.id_parte == d.id).forEach(async (hora: any) => {
            const columns = Object.keys(hora)
            const val = columns.map(() => {
              return '?';
            }).toString();
            const query = `INSERT OR IGNORE INTO parte_horas (${columns.toString()}) VALUES (${val})`;
            let values = Object.values(hora);
            values = values.map((v) => {
              // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
              //   v = `'${v}'`
              // }
              if (typeof v == 'object' && v != null) {
                v = JSON.stringify(v);
              }
              if (v == null) {
                v = null;
              }
              return v;
            });
            await db.query(query, values);
            d.horas = [];
          });

          maquinas_respuestas.filter((i) => i?.id_parte == d.id).forEach(async (maquinaRes: any) => {
            const columns = Object.keys(maquinaRes)
            const val = columns.map(() => {
              return '?';
            }).toString();
            const query = `INSERT OR IGNORE INTO faq_respuestas_parte_maquinas (${columns.toString()}) VALUES (${val})`;
            let values = Object.values(maquinaRes);
            values = values.map((v) => {
              // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
              //   v = `'${v}'`
              // }
              if (typeof v == 'object' && v != null) {
                v = JSON.stringify(v);
              }
              if (v == null) {
                v = null;
              }
              return v;
            });
            await db.query(query, values);
            d.maquinas_respuestas = [];
          });

          let queryMaquinas = '';
          maquinas.filter((i) => i.pivot?.id_parte == d.id).forEach(async (maquina: any) => {

            const columns = Object.keys(maquina)

            let values = Object.values(maquina);
            values = values.map((a:any) => {
              // Si el valor es null, undefined, una cadena vacía o una cadena que solo tiene espacios
              if (a === null || a === undefined || (typeof a === 'string' && !a.trim().length)) {
                return '""';
              } 
              // Si el valor es una cadena no vacía
              else if (typeof a === 'string') {
                return `"${a}"`;
              } 
              // Si el valor es un objeto o un array no vacío
              else if (typeof a === 'object' && (Array.isArray(a) || Object.keys(a).length)) {
                return `'${JSON.stringify(a)}'`;
              }

              // Para todos los demás valores (incluyendo números), devolver el valor tal cual
              return a;
            });
            queryMaquinas += `INSERT OR IGNORE INTO maquinas (${columns.toString()}) VALUES (${values.toString()});`;

            // NOTE: Relacionamos maquinas con partes

            
            const parteMaquina = {
              id: maquina.pivot.id,
              id_parte: maquina.pivot.id_parte,
              id_maquina: maquina.pivot.id_maquina,
              id_modelo_parte: maquina.pivot.id_modelo_parte,
              tareas_completadas: maquina.pivot.tareas_completadas
            };

            let values2 = Object.values(parteMaquina);
            values2 = values2.map((a:any) => {
              // Si el valor es null, undefined, una cadena vacía o una cadena que solo tiene espacios
              if (a === null || a === undefined || (typeof a === 'string' && !a.trim().length)) {
                return '""';
              } 
              // Si el valor es una cadena no vacía
              else if (typeof a === 'string') {
                return `"${a}"`;
              } 
              // Si el valor es un objeto o un array no vacío
              else if (typeof a === 'object' && (Array.isArray(a) || Object.keys(a).length)) {
                return `'${JSON.stringify(a)}'`;
              }

              // Para todos los demás valores (incluyendo números), devolver el valor tal cual
              return a;
            });

            queryMaquinas += `
              INSERT INTO parte_maquinas (id, id_parte, id_maquina, id_modelo_parte, tareas_completadas)
              VALUES (${values2.toString()});
            `;

            d.maquinas = [];
          });
          if (queryMaquinas?.length > 10) {
            await db.query(queryMaquinas);
          }

          (maquinaRespuestas || []).filter((i) => i?.id_parte == d.id).forEach(async (maquinaRespuesta: any) => {
            const columns = Object.keys(maquinaRespuesta)
            const val = columns.map(() => {
              return '?';
            }).toString();
            const query = `INSERT OR IGNORE INTO modelos_parte_campos_respuestas (${columns.toString()}) VALUES (${val})`;

            let values = Object.values(maquinaRespuesta);
            values = values.map((v:any) => {
              // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
              //   v = `'${v}'`
              // }

              if (typeof v == 'object' && v != null) {
                v = JSON.stringify(v);
              }
              if (v == null) {
                v = null;
              }
              return v;
            });

            await db.query(query, values);
            // d.respuestas = [];
          });

          // ModeloParteMaquina
          const modeloMaquinaParte = maquinas.filter((i) => i.pivot?.id_parte == d.id).map((a:any) => a.modelo);

          modeloMaquinaParte.forEach(async (modelo:any) => {
            if (modelo) {
              const columns = Object.keys(modelo)
              const val = columns.map(() => {
                return '?';
              }).toString();
              const query = `INSERT OR IGNORE INTO maquina_modelos (${columns.toString()}) VALUES (${val})`;

              let values = Object.values(modelo);
              values = values.map((v:any) => {
                // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
                //   v = `'${v}'`
                // }
                if (typeof v == 'object' && v != null) {
                  v = JSON.stringify(v);
                }
                if (v == null) {
                  v = null;
                }
                return v;
              });
              await db.query(query, values);
            }
          })

          // ModeloParteAnomalias
          const anomaliaModeloParte = [...new Set(maquinas.filter((i) => i.pivot.id_parte == d.id).map((a:any) => a?.anomalia) || [])];
          if (anomaliaModeloParte.length) {
            anomaliaModeloParte.forEach(async (anomalia:any) => {
              if (anomalia) {
                const columns = Object.keys(anomalia)
                const val = columns.map(() => {
                  return '?';
                }).toString();
                const query = `INSERT OR IGNORE INTO modelos_parte_anomalias (${columns.toString()}) VALUES (${val})`;

                let values = Object.values(anomalia);
                values = values.map((v:any) => {
                  // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
                  //   v = `'${v}'`
                  // }
                  if (typeof v == 'object' && v != null) {
                    v = JSON.stringify(v);
                  }
                  if (v == null) {
                    v = null;
                  }
                  return v;
                });

                await db.query(query, values);
              }
            });
          }
          // ParteComunicaciones
          if (comunicaciones.length) {
            comunicaciones.forEach(async (comunicacion:any) => {
              const columns = Object.keys(comunicacion)
              const val = columns.map(() => {
                return '?';
              }).toString();

              const query = `INSERT OR IGNORE INTO incidencia_comunicaciones (${columns.toString()}) VALUES (${val})`;

              let values = Object.values(comunicacion);
              values = values.map((v:any) => {
                // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
                //   v = `'${v}'`
                // }
                if (typeof v == 'object' && v != null) {
                  v = JSON.stringify(v);
                }
                if (v == null) {
                  v = null;
                }
                return v;
              });
              await db.query(query, values);
            });
          }

          imagenes.filter((i) => i.id_parte == d.id).forEach(async (imagen: any, index: number) => {
            // TODO: *** Fetch de las imagenes y guardarlas como base64
            const query = `INSERT OR IGNORE INTO parte_imagenes (id, id_parte, id_tecnico, src, src_thum) VALUES (?,?,?,?,?)`;
            const values: any = [index+1, imagen.id_parte, d?.id_tecnico, imagen.src, imagen.src_thum];
            await db.query(query, values);
            d.imagenes = [];
          });

          tecnicos.filter((i) => i.id_parte == d.id).forEach(async (tecnico: any, index: number) => {
            const query = `INSERT OR IGNORE INTO parte_tecnicos (id, id_parte, id_tecnico) VALUES (?,?,?)`;
            const values: any = [index+1, tecnico.id_parte, tecnico.id_tecnico];
            await db.query(query, values);
            d.tecnicos = [];
          });

          lineas.filter((i) => i.id_parte == d.id).forEach(async (linea: any) => {
            const columns = Object.keys(linea)
            const val = columns.map(() => {
              return '?';
            }).toString();
            const query = `INSERT OR IGNORE INTO parte_materiales (${columns.toString()}) VALUES (${val})`;
            let values = Object.values(linea);
            values = values.map((v) => {
              // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
              //   v = `'${v}'`
              // }
              if (typeof v == 'object' && v != null) {
                v = JSON.stringify(v);
              }
              if (v == null) {
                v = null;
              }
              return v;
            });
            await db.query(query, values);
            d.lineas = [];
          });
          d.direccion = {};

          firmas.forEach(async (modelo:any) => {
            if (modelo) {
              const columns = Object.keys(modelo)
              const val = columns.map(() => {
                return '?';
              }).toString();
              const query = `INSERT OR IGNORE INTO parte_firma (${columns.toString()}) VALUES (${val})`;

              let values = Object.values(modelo);
              values = values.map((v:any) => {
                if (typeof v == 'object' && v != null) {
                  v = JSON.stringify(v);
                }
                if (v == null) {
                  v = null;
                }
                return v;
              });
              await db.query(query, values);
            }
          })
        }

        let valores = Object.values(d);
        valores = valores.map((v) => {
          // if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
          //   v = `'${v}'`
          // }
          if (typeof v == 'object' && v != null) {
            v = JSON.stringify(v);
          }
          if (v == null) {
            v = null;
          }
          return v;
        });

        await db.query(sqlcmd, valores); // Siempre últitmo
      });

    }
    else {
      if (table == 'usuarios') {
        const columns = Object.keys(data);

        if (Object.keys(data.role).length) {
          const columns = Object.keys(data.role);
          const values = columns.map(() => {
            return '?';
          }).toString();
          let valores = Object.values(data.role);
          valores = valores.map((v) => {
            if (typeof v == 'object') {
              v = JSON.stringify(v);
            }
            return v;
          });
          console.log(data);
          
          const sqlcmd = `INSERT INTO usuarios_roles (${columns.toString()}) VALUES (${values})`;

          await db.query(sqlcmd, valores);

          // data.role = [];
        }

        if (data?.comportamientos?.length) {
          Object.keys(data.comportamientos).forEach(async (k: any, index: number) => {
            const query = `INSERT OR IGNORE INTO preferencias_globales (id,label,value) VALUES (?,?,?)`;
            const values: any = [index+1, k, data.comportamientos[`${k}`]];
            await db.query(query, values);
          });
          data.comportamientos = [];
        }

        if (data.modulos.length) {
          Object.keys(data.modulos).forEach(async (k: any, index: number) => {
            const query = `INSERT OR IGNORE INTO modulos_gmao (id,codigo,activo) VALUES (?,?,?)`;
            const values: any = [index+1, k, data.modulos[`${k}`]];
            await db.query(query, values);
          });
          data.modulos = [];
        }

        let valores = Object.values(data);
        valores = valores.map((v:any) => {
          if (typeof v != 'boolean' && typeof v != 'number' && typeof v != 'object') {
            if (!app.config.globalProperties.$moment(v,"YYYY-MM-DD", true).isValid() || app.config.globalProperties.$moment(v, "h:mm:ss", true).isValid()) {
              v = `'${v}'`;
            }
          }
          if (typeof v == 'object' && v != null) {
            v = `'${JSON.stringify(v)}'`;
          }
          if (v == null) {
            v = 'null';
          }
          return v;
        });

        const sqlcmd = `INSERT OR REPLACE INTO usuarios (${columns.toString()}) VALUES (${valores.toString()})`;
      

        try {
          await db.query(sqlcmd);
        } catch (error:any) {
          console.log(error, sqlcmd);
          // new Error(error);
        }
      }
    }

    if (table == 'usuarios_vacaciones') {
      // NOTE: Aqui caben dos tablas: usuarios_vacaciones y festivos
      const vacaciones = data['vacaciones'];
      const festivos = data['festivos'];

      vacaciones.forEach(async (d:any) => {
        const columns = Object.keys(d);
        const values = columns.map(() => {
          return '?';
        }).toString();
        const sqlcmd = `INSERT OR REPLACE INTO usuarios_vacaciones (${columns.toString()}) VALUES (${values})`;
        console.log(sqlcmd);
        
        let valores = Object.values(d);
        valores = valores.map((v) => {
          if (typeof v == 'object' && v != null) {
            v = JSON.stringify(v);
          }
          if (v == null) {
            v = null;
          }
          return v;
        });

        await db.query(sqlcmd, valores); // Siempre últitmo
      });

      festivos.forEach(async (d:any) => {
        const columns = Object.keys(d);
        const values = columns.map(() => {
          return '?';
        }).toString();
        const sqlcmd = `INSERT OR REPLACE INTO festivos (${columns.toString()}) VALUES (${values})`;
        console.log(sqlcmd);
        
        let valores = Object.values(d);
        valores = valores.map((v) => {
          if (typeof v == 'object' && v != null) {
            v = JSON.stringify(v);
          }
          if (v == null) {
            v = null;
          }
          return v;
        });

        await db.query(sqlcmd, valores); // Siempre últitmo
      });
    }

    // =======================================================
    return sqlite;

  } catch (err:any) {
    SentryVue.withScope(function(scope) {

      scope.setTag("sqlite", "main: buildSQLiteDB");

      scope.setFingerprint([err.name, err.message, String(err.stack)]);

      SentryVue.captureException(new Error(err));
    });

    console.log(`Error Building Tables: ${err.stack}`);
    // throw new Error(`Error Building Tables: ${err}`)
  }
}

app.config.globalProperties.$initSQLiteDB = async (schemas: Array<any>) => {
  try {
    // Check DB Connection and Open DB
    const ret = await sqlite.checkConnectionsConsistency();
    const isConn = (await sqlite.isConnection("gmaoTecnicos", false)).result;

    console.log(`INIT: Connection Consistency ${ret.result} and Connection ${isConn}`);

    let db: SQLiteDBConnection
    if (ret.result && isConn) {
      db = await sqlite.retrieveConnection("gmaoTecnicos", false);
    } else {
      db = await sqlite.createConnection("gmaoTecnicos", false, "no-encryption", 1, false);
    }
    await db.open();
    let executeSchemas = '';
    if (schemas.length) {
      schemas.forEach(async (s) => {
        let schema = 'DROP TABLE IF EXISTS `'+ s['Table'] +'`; ' +  s['Create Table'].split(',\n  PRIMARY KEY')[0] +');';
        if (schema.length) {
          const deleteWordsArray = ['CHARACTER SET utf8', 'CHARACTER SET utf8mb3', 'COLLATE', 'utf8_unicode_ci', 'utf8mb3_unicode_ci'];
          schema = schema.replace('AUTO_INCREMENT', 'PRIMARY KEY')
          const expresion = deleteWordsArray.join("|");
          schema = schema.replace(new RegExp('\\b(' + expresion + ')\\b', 'gi'), ' ').replace(/\s{2,}/g, ' ');

          // Columnas complementarias
          if(s['Table'] == 'usuarios') {
            schema = schema.slice(0, -2) + ', `proveedores_count` INT DEFAULT NULL, `status` tinyint(1) DEFAULT 1, `login` tinyint(1) DEFAULT 1, `hostapi` varchar(127) DEFAULT NULL, `hostgeo` varchar(127) DEFAULT NULL, `img` varchar(255) DEFAULT NULL, `token` varchar(255) DEFAULT NULL, `showhanstropper` tinyint(1) DEFAULT 1, `ajustes` text DEFAULT NULL, `permisosG` text DEFAULT NULL, `permisos` text DEFAULT NULL, `version` varchar(15) DEFAULT NULL, `nombre_completo` varchar(127) DEFAULT NULL, `modulos` varchar(255) DEFAULT NULL, `comportamientos` varchar(255) DEFAULT NULL, `role` varchar(127) DEFAULT NULL, last_modified timestamp DEFAULT NULL, `equipo_tecnicos` TEXT DEFAULT NULL, `equipos_responsable` TEXT DEFAULT NULL);';
          }
          else if(s['Table'] == 'direcciones') {
            schema = schema.slice(0, -2) + ', `cliente` varchar(255) DEFAULT NULL, `partes` varchar(255) DEFAULT NULL, `sistemas` varchar(255) DEFAULT NULL, `documentos` varchar(255) DEFAULT NULL, last_modified timestamp DEFAULT NULL, `limit_partes` TEXT DEFAULT NULL);';
          }
          else if(s['Table'] == 'partes') {
            schema = schema.slice(0, -2) + ', `tipo_label` TEXT DEFAULT NULL, `tipo_parte` TEXT DEFAULT NULL, `proyecto` TEXT DEFAULT NULL, `maquinas_count` int DEFAULT NULL, `not_finished` TEXT DEFAULT NULL, `offline` varchar(16) DEFAULT NULL, `syncObject` LONGTEXT DEFAULT NULL, `referencia_formateada` varchar(31) DEFAULT NULL, `tipo_letra` varchar(15) DEFAULT NULL, `tecnico` text DEFAULT NULL, `incidencia` text DEFAULT NULL, `obra` text DEFAULT NULL, `gama` varchar(255) DEFAULT NULL, `sub_gama` varchar(255) DEFAULT NULL, `firma` varchar(255) DEFAULT NULL, `firma_tecnico` varchar(255) DEFAULT NULL, `horas` varchar(255) DEFAULT NULL, `direccion` text DEFAULT NULL, `maquinas` varchar(255) DEFAULT NULL, `imagenes` varchar(255) DEFAULT NULL, `lineas` varchar(255) DEFAULT NULL, `maquinas_respuestas` varchar(255) DEFAULT NULL, `materiales` varchar(255) DEFAULT NULL, `sistema` varchar(255) DEFAULT NULL, `tecnicos` varchar(255) DEFAULT NULL, `comunicaciones` text DEFAULT NULL, `documentos` text DEFAULT NULL, `tecnicos_adicionales` TEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'sistemas') {
            schema = schema.slice(0, -2) + ', `sistemaTree` text DEFAULT NULL, `padre` text DEFAULT NULL);';
          }
          else if(s['Table'] == 'maquinas') {
            schema = schema.slice(0, -2) + ', `anomalia` TEXT DEFAULT NULL, `pivot` TEXT DEFAULT NULL, `padre` text DEFAULT NULL, `maquinaTree` text DEFAULT NULL, `modelo` text DEFAULT NULL, syncObject LONGTEXT DEFAULT NULL, `sistema` text DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'articulos') {
            schema = schema.slice(0, -2) + ', `almacenes` varchar(255) DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'gamas') {
            schema = schema.slice(0, -2) + ', `especialidades_count` varchar(31) DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'faq_respuestas') {
            schema = schema.slice(0, -2) + ', `materiales` varchar(255) DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'clientes') {
            schema = schema.slice(0, -2) + ', `documentos` text DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'parte_materiales') {
            schema = schema.slice(0, -2) + `, pvp_moneda_default text DEFAULT NULL, tpvp text DEFAULT NULL, tpcp text DEFAULT NULL, articulo text DEFAULT NULL, syncObject LONGTEXT DEFAULT NULL, ${!schema.includes('id_lote') ? ' `id_lote` int DEFAULT NULL,' : ''} id_almacen int DEFAULT NULL, last_modified timestamp DEFAULT NULL);`;
          }
          else if(s['Table'] == 'parte_maquinas') {
            schema = schema.slice(0, -2) + ', `lat` varchar default null, `lng` varchar default null, `finalizado` varchar null, `campos` TEXT DEFAULT NULL, `tiempos_sum_tiempo_total` INT DEFAULT 0, `imagenes_count` INT DEFAULT 0, `padre` text DEFAULT NULL, `maquinaTree` text DEFAULT NULL, `fechafabricacion` timestamp DEFAULT NULL, `id_erp` TEXT DEFAULT NULL, `id_direccion_zona` TEXT DEFAULT NULL, `id_maquina_contrato` TEXT DEFAULT NULL, `respuestas` text DEFAULT NULL, syncObject LONGTEXT DEFAULT NULL, `anomalia` text DEFAULT NULL, `modelo` text DEFAULT NULL, `pivot` varchar(255) DEFAULT NULL, `id_modelo` int DEFAULT NULL, `id_sistema` int DEFAULT NULL, `nombre` varchar(255) DEFAULT NULL, `contador` int DEFAULT NULL, `numeroserie` varchar(255) DEFAULT NULL, `coste_instalacion` varchar(255) DEFAULT NULL, `codigo_qr` varchar(255) DEFAULT NULL, `fecha_instalacion` timestamp DEFAULT NULL, `fingarantia` timestamp DEFAULT NULL, `observaciones` varchar(255) DEFAULT NULL, `ubicacion` varchar(255) DEFAULT NULL, `prioridad` int DEFAULT NULL, `numcompra` varchar(255) DEFAULT NULL, `proveedor` text DEFAULT NULL, `sistema` text DEFAULT NULL, `fechaCompra` timestamp DEFAULT NULL, `contrato` text DEFAULT NULL, `id_remoto` int DEFAULT NULL, `dado_baja` int DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'almacen_articulos') {
            schema = schema.slice(0, -2) + ', `id_cliente` int DEFAULT NULL, `id_direccion` int DEFAULT NULL, `id_erp` int DEFAULT NULL, `id_proveedor` int DEFAULT NULL, `id_tipo` int DEFAULT NULL, `nombre` varchar(200) DEFAULT NULL, `pivot` varchar(255) DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'faq_materiales_respuestas') {
            schema = schema.slice(0, -2) + ', `articulo` varchar(255) DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'parte_horas') {
            schema = schema.slice(0, -2) + ', `tecnico` text DEFAULT NULL, syncObject LONGTEXT DEFAULT NULL, `tipo_tiempo` text DEFAULT NULL, desde_formatted text DEFAULT NULL, hasta_formatted text DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'vehiculo_horas') {
            schema = schema.slice(0, -2) + ', syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'usuarios_vacaciones') {
            schema = schema.slice(0, -2) + ', syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL, diff varchar(255) DEFAULT NULL, diffWeekDays varchar(255) DEFAULT NULL);';
          }
          else if(s['Table'] == 'festivos') {
            schema = schema.slice(0, -2) + ', syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'tipos_tiempo') {
            schema = schema.slice(0, -2) + ', `tipo_defecto` INT DEFAULT 0);';
          }
          else if(s['Table'] == 'modelos_parte_anomalias') {
            schema = schema.slice(0, -2) + ', `url` varchar(127) DEFAULT NULL, syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'maquina_imagenes') {
            schema = schema.slice(0, -2) + ', `base64Src` LONGTEXT DEFAULT NULL, syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'parte_imagenes') {
            schema = schema.slice(0, -2) + ', `base64Src` LONGTEXT DEFAULT NULL, syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'material_linea_imagenes') {
            schema = schema.slice(0, -2) + ', syncObject LONGTEXT DEFAULT NULL, `base64Src` LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'parte_tecnicos') {
            schema = schema.slice(0, -2) + ', syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'parte_firma') {
            schema = schema.slice(0, -2) + ', `parte_firma` VARCHAR DEFAULT NULL, syncObject LONGTEXT DEFAULT NULL, `base64Src` LONGTEXT DEFAULT NULL, enviar_email varchar(63) DEFAULT NULL, genera_presupuesto tinyint(1) DEFAULT 0, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'incidencia_imagenes') {
            schema = schema.slice(0, -2) + ', `base64Src` LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'incidencias') {
            schema = schema.slice(0, -2) + ', syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'faq_respuestas_parte_maquinas') {
            schema = schema.slice(0, -2) + ', syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'modelos_parte_campos_respuestas') {
            schema = schema.slice(0, -2) + ', syncObject LONGTEXT DEFAULT NULL, campo TEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'repuestos_en_maquina') {
            schema = schema.slice(0, -2) + ', last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'lotes_almacenes') {
            schema = schema.slice(0, -2) + ', last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'jornada_horas') {
            schema = schema.slice(0, -2) + ', `jornada_vehiculo` LONGTEXT DEFAULT NULL, `vehicle` TEXT DEFAULT NULL, `syncObject` LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL, start VARCHAR DEFAULT NULL, stop VARCHAR DEFAULT NULL, km VARCHAR DEFAULT NULL, coords_start VARCHAR DEFAULT NULL, address_coords VARCHAR DEFAULT NULL);';
          }
          else if(s['Table'] == 'lotes') {
            schema = schema.slice(0, -2) + ', last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'parte_maqunas_tiempos') {
            schema = schema.slice(0, -2) + ', syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'incidencia_comunicaciones') {
            schema = schema.slice(0, -2) + ', syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'parte_estados_historial') {
            schema = schema.slice(0, -2) + ', syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'maquina_modelos') {
            schema = schema.slice(0, -2) + ', familia TEXT DEFAULT NULL, subfamilia TEXT DEFAULT NULL, syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          else if(s['Table'] == 'almacenes') {
            schema = schema.slice(0, -2) + ', equipos TEXT DEFAULT NULL, gerentes TEXT DEFAULT NULL, syncObject LONGTEXT DEFAULT NULL, last_modified timestamp DEFAULT NULL);';
          }
          executeSchemas += schema;
        }

      });
      try {
        await db.execute(executeSchemas);
      } catch (e:any) {
        console.log(e);

        SentryVue.withScope(function(scope) {

          scope.setTag("sqlite", "main: initSQLiteDB");

          scope.setFingerprint([e.name, e.message, String(e.stack)]);

          SentryVue.captureException(e);
        });

        // throw new Error(`Schemas error: ${e}`);
      }
    } else {
      throw Error('No hay esquemas DB');
    }

    return sqlite;

  } catch (err:any) {

    SentryVue.withScope(function(scope) {

      scope.setTag("sqlite", "main: initSQLiteDB");

      scope.setFingerprint([err.name, err.message, String(err.stack)]);

      SentryVue.captureException(err);
    });

    console.log(`Error Init DB: ${err}`);
    // throw new Error(`Error Init DB: ${err}`)
  }
}

app.config.globalProperties.$checkConnection = async (connection: boolean) => {
  console.log("Estado: ", connection ? 'Online' : 'Offline');
  return connection;
  if (connection && offline.counterQueue > 0) {
    // Sincronizar directamente
    app.config.globalProperties.$syncAllData().then(() => {
      console.log('Sincronizado con exito');
    });

  } else if (!connection  && offline.counterQueue > 0) {
    // TODO: Marcar parte de rojo si ha sido cambiado y despues no hay conexion
    // const parte = JSON.parse(JSON.stringify(wo.workorder));
    // const offlineWO = (wo.workorders.at(-1)).find((w:any) => w.id == parte.id)

    // offlineWO.offline = '#FF0000'
    // Marcar los partes modificados en rojo
  } else if (!connection) {
    // app.config.globalProperties.$SQLiteQuery('getPartes_workorders', 'partes', 'SELECT * FROM partes', {config: {method: 'post'}}, db)
  }
}

app.config.globalProperties.$SQLiteQuery = async (functionName:string, table: string, query: string, config: any, dbEX: SQLiteDBConnection, extra: object = {}) => {

  const data = { functionName, table, query, config, dbEX, extra };

  try {
    const method = config.method;

    switch (method) {
      /** Ambas tienen que devolver un promise para que despues de la asignacion de los datos, se pueda cerrar la conexion con la DB */
      case 'get':
          // GET FUNCTIONS
        return getController(data, offline);

      case 'post':
          // SET FUNCTIONS
        return setController(data, offline);
    }

  } catch (e:any) {
    SentryVue.withScope(function(scope) {

      scope.setTag("sqlite", "main: SQLiteQuery");

      scope.setFingerprint([e.name, e.message, String(e.stack)]);

      SentryVue.captureException(e);
    });
    console.log(`Error SQLite Query: ${e}`);
    // throw new Error(`Error SQLite Query: ${e}`)
  }
}

app.config.globalProperties.$syncAllData = async () => {
  /** FIXME: Posible mejora o optimizacion de esta funcion... A medida que se hagan INSERTs o UPDATEs o DELETEs
   * ir pusheandolo en un array de Pinia y luego pasarlo aqui.
   * De esa forma, evitamos volver a hacer llamadas cuando vayamos a sincronizar y sera algo mas rapido.
  */

  try {
    const ret = await sqlite.checkConnectionsConsistency();
    const isConn = (await sqlite.isConnection("gmaoTecnicos", false)).result;

    console.log(`SYNC: Connection Consistency ${ret.result} and Connection ${isConn}`);

    let db: SQLiteDBConnection
    if (ret.result && isConn) {
      db = await sqlite.retrieveConnection("gmaoTecnicos", false);
    } else {
      db = await sqlite.createConnection("gmaoTecnicos", false, "no-encryption", 1, false);
    }
    await db.open();

    // Consultar cambios en la tabla Partes
    const promesaPartes = new Promise((resolve) => {
      const resultados = db.query(`SELECT * FROM partes WHERE last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Partes horas
    const promesaPartesHoras = new Promise((resolve) => {
      const resultados = db.query(`SELECT syncObject, id_parte FROM parte_horas WHERE syncObject IS NOT NULL AND last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Parte_maquinas
    const promesaPartesMaquinas = new Promise((resolve) => {
      const resultados = db.query(`SELECT syncObject, id_parte FROM parte_maquinas WHERE syncObject IS NOT NULL AND last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Partes maquinas Tiempo
    const promesaPartesMaquinasTiempos = new Promise((resolve) => {
      const resultados = db.query(`SELECT syncObject, id_parte FROM parte_maqunas_tiempos WHERE syncObject IS NOT NULL AND last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Partes tecnicos
    const promesaPartesTecnicos = new Promise((resolve) => {
      const resultados = db.query(`SELECT syncObject, id_parte FROM parte_tecnicos WHERE syncObject IS NOT NULL AND last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Partes Estados Historial
    const promesaPartesEstadosHistorial = new Promise((resolve) => {
      const resultados = db.query(`SELECT syncObject, id_parte FROM parte_estados_historial WHERE syncObject IS NOT NULL AND last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Partes firma
    const promesaPartesFirma = new Promise((resolve) => {
      const resultados = db.query(`SELECT * FROM parte_firma WHERE last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Partes imagenes
    const promesaPartesImagenes = new Promise((resolve) => {
      const resultados = db.query(`SELECT * FROM parte_imagenes WHERE last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Partes materiales
    const promesaPartesMateriales = new Promise((resolve) => {
      const resultados = db.query(`SELECT syncObject, id_parte FROM parte_materiales WHERE syncObject IS NOT NULL AND last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Partes materiales imagenes
    const promesaPartesMaterialesImagenes = new Promise((resolve) => {
      const resultados = db.query(`SELECT * FROM material_linea_imagenes WHERE last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla FAQ
    const promesaFAQRespuestasParteMaquinas = new Promise((resolve) => {
      const resultados = db.query(`SELECT syncObject, id_parte FROM faq_respuestas_parte_maquinas WHERE syncObject IS NOT NULL AND last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Modelos Parte Anomalias
    const promesaModelosParteAnomalias = new Promise((resolve) => {
      const resultados = db.query(`SELECT syncObject, id_parte FROM modelos_parte_anomalias WHERE syncObject IS NOT NULL AND last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Incidencias
    const promesaIncidencias = new Promise((resolve) => {
      const resultados = db.query(`SELECT syncObject FROM incidencias WHERE syncObject IS NOT NULL AND last_modified IS NOT NULL`);
      resolve(resultados);
    });
    // Consultar cambios en la tabla Incidencias Imagenes
    const promesaIncidenciasImagenes = new Promise((resolve) => {
      const resultados = db.query(`SELECT * FROM incidencia_imagenes WHERE last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Partes comunicaciones
    const promesaIncidenciasComunicaciones = new Promise((resolve) => {
      const resultados = db.query(`SELECT syncObject, id_parte FROM incidencia_comunicaciones WHERE syncObject IS NOT NULL AND last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Maquinas
    const promesaMaquinas = new Promise((resolve) => {
      const resultados = db.query(`SELECT * FROM maquinas WHERE syncObject IS NOT NULL AND last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Modelos Parte Respuestas
    const promesaModelosParteCampos = new Promise((resolve) => {
      const resultados = db.query(`SELECT syncObject FROM modelos_parte_campos_respuestas WHERE syncObject IS NOT NULL AND last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // Consultar cambios en la tabla Modelos Parte Respuestas
    const promesaJornadaHoras = new Promise((resolve) => {
      const resultados = db.query(`SELECT syncObject FROM jornada_horas WHERE syncObject IS NOT NULL AND last_modified IS NOT NULL`);
      resolve(resultados);
    });

    // let sync:any;

    return Promise.all([
        promesaPartes,
        promesaPartesHoras,
        promesaPartesMaquinas,
        promesaPartesMaquinasTiempos,
        promesaPartesTecnicos,
        promesaPartesEstadosHistorial,
        promesaPartesFirma,
        promesaPartesImagenes,
        promesaPartesMateriales,
        promesaPartesMaterialesImagenes,
        promesaFAQRespuestasParteMaquinas,
        promesaModelosParteAnomalias,
        promesaIncidencias,
        promesaIncidenciasImagenes,
        promesaIncidenciasComunicaciones,
        promesaMaquinas,
        promesaModelosParteCampos,
        promesaJornadaHoras
      ]).then(async (value:any) => {

        console.log('Promise.all -> Values: ', value);
        let syncUpload:any = null;
        const id_incidencia_antiguo:any = [];
        const id_incidencia_nuevo:any = [];

        let parteID = 'id_parte';

        const promesas:any = [];
        // let promesas2:any = [];

        let datos = value.flat().map((v:any) => v?.values).flat(); // => [{...syncObject, ...}, {...syncObject, id}, {...syncObject, id_parte}]

        // Object Parse
        datos.map((dato:any) => {
            if(typeof dato.syncObject == 'string') dato.syncObject = JSON.parse(dato?.syncObject);
            return dato;
        });

        // Transformar en FormData()
        const dataTransform = [
          'setWorkorderSignatureImage',
          'setWorkorderSignature',
          'setImputacionMaterialImagen',
          'setImageWorkorder',
          'setWorkorderActive',
          'setIncidencia',
          'setUpdateParteMaquinaChecklist'
        ];

        const promesa = new Promise((resolve) => {
          // Si 'setIncidencia' existe, entonces la creamos antes
          const setIncidencia = datos?.filter((v:any) => v?.syncObject?.callFunction == 'setIncidencia');

          if (setIncidencia.length) {
            promesas.push(setIncidencia.map(async (incidencia:any) => {
              id_incidencia_antiguo.push(incidencia?.id);

              const callName = incidencia?.syncObject?.callFunction;
              const callData = incidencia?.syncObject?.data;

              syncUpload = await axios.post(`/v2/users/actions.php?call=${callName}&token=${gmao.user.token}&v2=1`, callData, {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                })
                .then(async (data:any) => {
                  id_incidencia_nuevo.push(data?.data?.incidencia?.id);
                  return data;
                }).catch((e) => {
                  console.log('Ha habido algun problema: ', e);
                });

              return new Promise((resolve) => {
                resolve(syncUpload);
              });
            }));
            // Promise.all(promesas).then(() => {
            //   console.log('Done incidencias: ', id_incidencia_nuevo, id_incidencia_antiguo);
            // })
          }

          /** Si tenemos partes nuevos por setear, los creamos y sustituimos el nuevo id_parte por el viejo */
          // eslint-disable-next-line no-prototype-builtins
          const setJornadasVehiculos = datos?.filter((v:any) => v?.syncObject?.hasOwnProperty('vehicle_fin') && v?.syncObject?.callFunction == 'setJornada');
          const setPartes = datos?.filter((v:any) => v?.syncObject?.callFunction == 'setParte');
          const setUpdateWorkorder = datos?.filter((v:any) => v?.syncObject?.callFunction == 'setUpdateWorkorder');
          const setDuplicateWorkorders = datos?.filter((v:any) => v?.syncObject?.callFunction == 'setDuplicateWorkorder');
          console.log('Partes: ', setPartes);

          if (setUpdateWorkorder.length) {
            setUpdateWorkorder.map((p:any) => {
              Object.keys(p).map((key:any) => {
                  if (p[key]) {
                      try {
                          p[key] = JSON.parse(p[key])
                      } catch {
                        p[key]
                      }
                      if ((Object.keys(p[key]).length || p[key].length == 0 || Object.keys(p[key]).length == 0) && typeof p[key] == 'object') delete p[key]
                  }
              });

              return p;
            })
            setUpdateWorkorder.forEach((parte:any) => {
              axios
                .post(`/v2/users/actions.php?call=setParte&token=${gmao.user.token}&v2=1`, {data: parte}, {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                })
                .catch((e) => {
                  SentryVue.withScope(function(scope) {

                    scope.setTag("sqlite", "main: syncAllData");

                    scope.setFingerprint([e.name, 'Ha habido algun problema con setUpdateWorkorder | ' + e.message, String(e.stack)]);

                    SentryVue.captureException(e);
                  });
                });
            });
          }

          if (setPartes.length) {
            const setToWo = [
              'setWorkorderActives',
              'setDeleteWorkorderActive',
              'setChecks',
              'setWorkorderActive',
              'setWorkorderSignatureImage',
              'setWorkorderSignature',
              'setImputacionMaterial',
              'setDeleteWorkorderMaterial',
              'setDeleteWorkorderImage',
              'setImageWorkorder',
              'setUpdateParteMaquinaChecklist'
            ];

            const parteIDS = setPartes.map((i:any) => i.id);
            datos = datos.reduce((p:any, c:any) => (!parteIDS.includes(c?.id) && p.push(c), p), []); // Delete 'setParte' queries
            const parteRelacionados = JSON.parse(JSON.stringify(datos.filter((dato:any) => parteIDS.includes(dato?.id_parte) || parteIDS.includes(dato?.syncObject?.data?.id_parte) || parteIDS.includes(dato?.syncObject?.data?.workorder))));
            console.log('Relaciones con parte: ', parteRelacionados);

            if (parteRelacionados.length) {
              datos = datos.reduce((p:any, c:any) => (!parteIDS.includes(c?.id_parte) && p.push(c), p), []); // Delete all related to 'setParte' queries from main var
              datos = datos.reduce((p:any, c:any) => (!parteIDS.includes(c?.syncObject?.data?.id_parte) && p.push(c), p), []); // Delete all related to 'setWorkorderActive' queries from main var
              console.log('Nuevo set datos: ', datos);
            }

            let parteDuplicateIDS:any;
            if (setDuplicateWorkorders.length) {
              parteDuplicateIDS = setDuplicateWorkorders.map((i:any) => i.id);
              datos = datos.reduce((p:any, c:any) => (!parteDuplicateIDS.includes(c?.id) && p.push(c), p), []); // Delete 'setParte' queries
            }

            setPartes.forEach(async (parte:any, index:number) => {

              await axios
              .post(`/v2/users/actions.php?call=setParte&token=${gmao.user.token}&v2=1`, {data: {...parte}}, {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              })
              .then(async (data:any) => {
                const parteNuevoId = data.data?.parte?.id;
                const parteRelacionado = parteRelacionados.filter((dato:any) => [parteIDS[index]].includes(dato?.id_parte) || [parteIDS[index]].includes(dato?.syncObject?.data?.id_parte) || [parteIDS[index]].includes(dato?.syncObject?.data?.workorder)); // [{...}, {...}, ...]

                parteRelacionado.map((f:any) => {
                  if (!setToWo.includes(f?.syncObject?.callFunction)) {
                    parteID = 'workorder';
                  } else parteID = 'id_parte';

                  if (f?.syncObject?.data[parteID] == parteIDS[index]) {
                      f.syncObject.data[parteID] = parteNuevoId;
                  }
                  return f;
                });
                console.log('Cambio parte relacionados: ', parteRelacionado);

                const nestedParteRelacionado = JSON.parse(JSON.stringify(parteRelacionado));
                console.log('Nested: ', nestedParteRelacionado);

                  promesas.push(nestedParteRelacionado.map(async (parte:any) => {
                    const callName = parte?.syncObject?.callFunction;
                    const callHeaders = parte?.syncObject?.headers;
                    let callData = parte?.syncObject?.data;

                    if (setIncidencia.length && callData?.id_incidencia) {
                      const idx = id_incidencia_antiguo?.findIndex((i:any) => i == callData.id_incidencia);
                      if (idx >= 0) callData.id_incidencia = id_incidencia_nuevo[idx];
                    }
                    if (setDuplicateWorkorders.length && callName == 'setDuplicateWorkorder') {
                      console.log('IDS: ', parteIDS);
                      console.log('Data: ', callData);

                      const idx = parteIDS?.findIndex((i:any) => i == callData.workorder);
                      console.log('Index: ', idx, parteNuevoId);

                      if (idx >= 0) callData.workorder = parteNuevoId;
                    }

                    // Object -> FormData
                    if (dataTransform?.includes(callName)) {
                      const formData:any = new FormData();
                      if (callName == 'setWorkorderSignature' && !(callData instanceof FormData)) {
                        
                        const file = await app.config.globalProperties.$base64toBlob(parte?.base64Src.split(',')[1]);
                        formData.append('lat', callData.geolocation?.coords?.latitude || '');
                        formData.append('lng', callData.geolocation?.coords?.longitude || '');
                        formData.append('name', callData.firma.nombre || '');
                        formData.append('dni', callData.firma.dni || '');
                        formData.append('firma_tec', +callData.tecnico || '');
                        formData.append('genera_presupuesto', callData.genera_presupuesto || '');
                        formData.append('enviar_email', callData.enviar_email || '');
                        formData.append('id_parte', callData.id_parte);
                        formData.append('signature', file);

                      } else if (callName == 'setWorkorderSignatureImage' && !(callData instanceof FormData)) {
                        const file = await app.config.globalProperties.$base64toBlob(parte?.base64Src.split(',')[1]);
                        formData.append('lat', callData.lat || '');
                        formData.append('lng', callData.lng || '');
                        formData.append('name', callData.nombre || '');
                        formData.append('dni', callData.dni || '');
                        formData.append('firma_tec', 0);
                        formData.append('signature', file);
                        formData.append('id_parte', callData.id_parte);

                      } else if (callName == 'setImputacionMaterialImagen' && !(callData instanceof FormData)) {
                        const file = await app.config.globalProperties.$base64toBlob(parte.base64Src.split(',')[1]);
                        formData.append('file', file);
                        formData.append('id_usuario', callData.id_usuario || '');

                      } else if (callName == 'setImageWorkorder' && !(callData instanceof FormData)) {
                        const file = await app.config.globalProperties.$base64toBlob(parte.base64Src.split(',')[1]);
                        formData.append('file', file);
                        formData.append('id_parte', callData.id_parte || '');

                      } else if (callName == 'setWorkorderActive' && !(callData instanceof FormData)) {
                        for (const [key, value] of Object.entries(callData)) {
                          if (key != 'image' && key != 'images') {
                            if (value instanceof Array) {
                              value.forEach((img, index) => {
                                formData.append(`${key}${index}`, img);
                              });
                            } else {
                              formData.append(key, value);
                            }
                          }
                        }
                      } else if (callName == 'setIncidencia' && !(callData instanceof FormData)) {
                        formData.append('id_tecnico', callData.id_tecnico);
                        formData.append('descripcion', callData.incData?.problema);
                        formData.append('id_direccion', callData.incData.address?.id || callData.customAddress?.id);
                        formData.append('id_cliente', callData.incData.address?.id_cliente || callData.customAddress?.id_cliente);
                        callData.images?.forEach(async (img:any, index:any) => {
                          const file = await app.config.globalProperties.$base64toBlob(img.split(',')[1]);
                          formData.append(`blob${index}`, file);
                        });
                      } else if (callName == 'setUpdateParteMaquinaChecklist' && !(callData instanceof FormData)) {
                        formData.append('tipo_letra', callData.tipo_letra);
                        formData.append('parte_maquina', callData.parte_maquina);
                        formData.append('id', callData.id);
                        formData.append('field', callData.field);
                        formData.append('valor', callData.valor);
                      }

                      callData = formData;
                    }

                    syncUpload = await axios.post(`/v2/users/actions.php?call=${callName}&token=${gmao.user.token}&v2=1`, {data: callData}, 
                      {
                        headers: callHeaders || {'Content-Type': 'application/x-www-form-urlencoded'},
                      }
                      )
                      .then(async (data:any) => {
                        console.log(`Success from ${callName} and data is ${data}`);
                        return data;
                      }).catch((e) => {
                        console.log(`Ha habido un problema con ${callName} y ${e}`);
                      });

                    return new Promise((resolve) => {
                      resolve(syncUpload);
                    });
                  }));
                  // Promise.all(promesas).then(() => {
                  //   console.log('Exito!!!');
                  // });
                  return data;
                }).catch((e:any) => {
                  console.log('Ha habido algun problema con setParte: ', e);

                  SentryVue.withScope(function(scope) {

                    scope.setTag("sqlite", "main: syncAllData");

                    scope.setFingerprint([e.name, 'Ha habido algun problema con setParte | ' + e.message, String(e.stack)]);

                    SentryVue.captureException(e);
                  });

                  // throw new Error(`Sync Error: ${e}`);

                });

            });
          }

          promesas.push(datos.map(async (dato:any) => {
            const callName = dato?.syncObject?.callFunction;
            const callHeaders = dato?.syncObject?.headers;
            // eslint-disable-next-line no-prototype-builtins
            const isVehicleFinished = dato?.syncObject?.hasOwnProperty('vehicle_fin') && dato?.syncObject?.vehicle_fin;
            let callData = dato?.syncObject?.data;

            // Object -> FormData
            if (dataTransform?.includes(callName)) {
              const formData:any = new FormData();
              if (callName == 'setWorkorderSignature' && !(callData instanceof FormData)) {
                let file = null;
                if (dato.base64Src) file = await app.config.globalProperties.$base64toBlob(dato.base64Src.split(',')[1]);
                formData.append('lat', callData.geolocation?.coords?.latitude || '');
                formData.append('lng', callData.geolocation?.coords?.longitude || '');
                formData.append('name', callData.firma.nombre || '');
                formData.append('dni', callData.firma.dni || '');
                formData.append('firma_tec', +callData.tecnico || '');
                formData.append('genera_presupuesto', callData.genera_presupuesto || '');
                formData.append('enviar_email', callData.enviar_email || '');
                formData.append('id_parte', callData.id_parte);
                formData.append('signature', file || '');

              } else if (callName == 'setWorkorderSignatureImage' && !(callData instanceof FormData)) {
                  const file = await app.config.globalProperties.$base64toBlob(dato?.base64Src.split(',')[1]);
                  formData.append('lat', callData.lat || '');
                  formData.append('lng', callData.lng || '');
                  formData.append('name', callData.nombre || '');
                  formData.append('dni', callData.dni || '');
                  formData.append('firma_tec', 0);
                  formData.append('signature', file);
                  formData.append('id_parte', callData.id_parte);

              } else if (callName == 'setImputacionMaterialImagen' && !(callData instanceof FormData)) {
                const file = await app.config.globalProperties.$base64toBlob(dato.base64Src.split(',')[1]);
                formData.append('file', file);
                formData.append('id_usuario', callData.id_usuario || '');

              } else if (callName == 'setImageWorkorder' && !(callData instanceof FormData)) {
                const file = await app.config.globalProperties.$base64toBlob(dato.base64Src.split(',')[1]);
                formData.append('file', file);
                formData.append('id_parte', callData.id_parte || '');

              } else if (callName == 'setWorkorderActive' && !(callData instanceof FormData)) {
                for (const [key, value] of Object.entries(callData)) {
                  if (key != 'image' && key != 'images') {
                    if (value instanceof Array) {
                      value.forEach((img, index) => {
                        formData.append(`${key}${index}`, img);
                      });
                    } else {
                      formData.append(key, value);
                    }
                  }
                }
              } else if (callName == 'setIncidencia' && !(callData instanceof FormData)) {
                formData.append('id_tecnico', callData.id_tecnico);
                formData.append('descripcion', callData.incData?.problema);
                formData.append('id_direccion', callData.incData.address?.id || callData.customAddress?.id);
                formData.append('id_cliente', callData.incData.address?.id_cliente || callData.customAddress?.id_cliente);
                callData.images?.forEach(async (img:any, index:any) => {
                  const file = await app.config.globalProperties.$base64toBlob(img.split(',')[1]);
                  formData.append(`blob${index}`, file);
                });
              } else if (callName == 'setUpdateParteMaquinaChecklist' && !(callData instanceof FormData)) {
                formData.append('tipo_letra', callData.tipo_letra);
                formData.append('parte_maquina', callData.parte_maquina);
                formData.append('id', callData.id);
                formData.append('field', callData.field);
                formData.append('valor', callData.valor);

              }

              callData = formData;
            }


            if (callName && callData) {
              let payload = null;
              switch (callName) {
                case 'setWorkorderActives':
                  payload = { data: callData };
                  break;
                case 'setWorkorderTimesBlock':
                  payload = { data: callData };
                  break;
                case 'setImputacionMaterial':
                  payload = { data: callData };
                  break;
                case 'setWorkorderTimes':
                  payload = { data: callData };
                  break;
                case 'setUpdateParteMaquinaChecklist':                  
                  payload = callData;
                  break;
                case 'setCreateJornada':                  
                  payload = { data: callData };
                  break;
                case 'setJornada':
                  payload = { data: callData };
                  break;

                default:
                  payload = callData;
                  break;
              }
              
              if (callName == 'setJornada' && !isVehicleFinished && setJornadasVehiculos?.length > 1) return;
              
              syncUpload = await axios.post(`/v2/users/actions.php?call=${callName}&token=${gmao.user.token}&v2=1`, payload, {
                  headers: callHeaders || {'Content-Type': 'application/x-www-form-urlencoded'},
                })
                .then(async (data:any) => {
                  console.log(`Success from ${callName} and data is ${data}`);
                }).catch((e) => {
                  console.log(`Ha habido un problema con ${callName} y ${e}`);
                });

              return new Promise((resolve) => {
                resolve(syncUpload);
              });
            }
          }));
          if (promesas.length) {
            resolve(promesas);
          }
        });

        promesa.then(() => {
          Promise.all(promesas).then(async () => {
            console.log('Done');
            const varObject = {
              offline,
              gmao,
              wo,
              app
            };
            await downloadOffline(gmao.user, varObject)
            // await app.config.globalProperties.$syncAndPut(gmao.user);
            if (offline.loading?.presented) {
              offline.loading?.dismiss();
              app.config.globalProperties.$openToastObject(
                'Sincronizado correctamente',
                'Sus datos han sido actualizados correctamente.',
                'success'
              );
            }
          })
        });

    });

    // return sync;

  } catch(e:any) {
    SentryVue.withScope(function(scope) {

      scope.setTag("sqlite", "main: syncAllData");

      scope.setFingerprint([e.name, e.message, String(e.stack)]);

      SentryVue.captureException(e);
    });
    console.log('SYNC: ', e);
    // throw new Error(`SYNC Data Failed: ${e}`);
  }

}

router.isReady().then(() => {
  app.mount('#app');
});