import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useGmaoStore = defineStore('gmao', {
    state: () => {
        return {
            user: useStorage('user', {}),
            workspace: useStorage('workspace', {}),
            time: useStorage('time', []),
            timeRegister: useStorage('timeRegister', { id: null }),
            timeRegisterEfective: useStorage('timeRegisterEfective', { id: null }),
            nonEffectiveType: useStorage('nonEffectiveType', ''),
            warehouse: useStorage('warehouse', []),
            deviceId: useStorage('deviceId', ''),
            v: '1.6.6',
            scanActive: false,
            isMobile: useStorage('isMobile', false),
        }
    },

    actions: {},

    getters: {
        comportamientos(state:any) {
            return state.user?.comportamientos;
        },

        modulos(state:any) {
            return state.user?.modulos;
        },

        esTecnico(state:any) {
            return state.user?.id_role === 5;
        },

        esUsuario(state:any) {
            return state.user?.id_role != 5;
        },
    }
});
