import moment from 'moment';

export default function getController(datos:any, store: any = null) {
    const { functionName } = datos;

    switch (functionName) {
        case 'getPartes_workorders':
            getPartes_workorders(datos, store);
            break;
        case 'getDirecciones_workorders':
            getDirecciones_workorders(datos, store);
            break;
        case 'getParte_general':
            getParte_general(datos, store);
            break;
        case 'getActivos_general':
            getActivos_general(datos, store);
            break;
        case 'setOperarioAdicional_workorder':
            getOperarioAdicional_workorder(datos, store);
            break;
        case 'getReplies_workorder':
            getReplies_workorder(datos, store);
            break;
        case 'getReplies_item':
            getReplies_workorder(datos, store);
            break;
        case 'getQuestions_workorder':
            getQuestions_workorder(datos, store);
            break;
        case 'setEspecialidad_workorder':
            setEspecialidad_workorder(datos, store);
            break;
        case 'setSubespecialidad_workorder':
            setSubespecialidad_workorder(datos, store);
            break;
        case 'setOperario_workorder':
            setOperario_workorder(datos, store);
            break;
        case 'getTextosPredefinidos_general':
            getTextosPredefinidos_general(datos, store);
            break;
        case 'getModelos_workorder':
            getModelos_workorder(datos, store);
            break;
        case 'getSistemas_workorder':
            getSistemas_workorder(datos, store);
            break;
        case 'getMaquinas_workorder':
            getMaquinas_workorder(datos, store);
            break;
        case 'getArticle_workorder':
            getArticle_workorder(datos, store);
            break;
        case 'getMaterials_workorder':
            getMaterials_workorder(datos, store);
            break;
        case 'getLotesMaterial_workorder':
            getLotesMaterial_workorder(datos, store);
            break;
        case 'getAlmacenesMaterial_workorder':
            getAlmacenesMaterial_workorder(datos, store);
            break;
        case 'getDirecciones_new_workorder':
            getDirecciones_new_workorder(datos, store);
            break;
        case 'getProyectos_new_workorder':
            getProyectos_new_workorder(datos, store);
            break;
        case 'getMaquinasSistemas_workorder':
            getMaquinasSistemas_workorder(datos, store);
            break;
        case 'getParteMaquina_item':
            getParteMaquina_item(datos, store);
            break;
        case 'getVehiculosTex':
            getVehiculosTex(datos, store);
            break;
        case 'getIsWorkingDay':
            getIsWorkingDay(datos, store);
            break;
        default:
            throw new Error(`GET SQLITE: La funcion "${functionName}" no existe`);
    }

    // TODO: Posible otra solución
    // const fun: Window = window[functionName];

    // if (typeof fun == 'function') fun(table, query, config, dbEX);
    // else throw new Error("La función no existe...");


    // FUNCIONES -------------------------------------------------------------------------------------------------------

    // function function_name(datos:any, store:any) {
    //     const {table, query, config, dbEX} = data;
    //     .
    //     .
    //     .
    // }

    function getParte_general(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const parteGet = dbEX.query(query);
            resolve(parteGet);
        });

        promesa.then(async (value:any) => {
            const partePromise: any = new Promise((resolve) => {
                resolve(setUpWorkOrders(value, dbEX));
            });
            partePromise.then((value:any) => {
                const parte = value[0];
                console.log('Parte procesado: ', parte);

                setTimeout(() => {
                    // Comunicaciones
                    const comunicacionPromise = new Promise((resolve) => {
                        const comunicacion = dbEX.query(`SELECT * FROM incidencia_comunicaciones WHERE id_parte=${parte?.id || 0} AND deleted_at IS NULL`);
                        resolve(comunicacion);
                    });
                        comunicacionPromise.then(async (value:any) => {
                            const comunicaciones = value.values;
                            parte.comunicaciones = comunicaciones;
                        });

                    // Documentos - Parte
                    if (parte?.documentos?.length) {
                        const documentoPromise = new Promise((resolve) => {
                            const documento = dbEX.query(`SELECT * FROM documentos WHERE id_parte=${parte?.id || 0} AND tecnico_ver=1 AND (expires_at IS NULL OR expires_at>='${moment().format("YYYY-MM-DD H:mm:ss")}') AND deleted_at IS NULL`);
                            resolve(documento);
                        });
                            documentoPromise.then(async (value:any) => {
                                const documentos = value.values;
                                parte.documentos = documentos;
                            });
                    } else parte.documentos = [];
                    if (parte.tecnicos?.length) {
                        const tecnicos = parte.tecnicos?.map((t:any) => t.id_tecnico).toString();

                        // Tecnicos Adicionales
                        const tecnicoAdicional = new Promise((resolve) => {
                            const tecnicosAdicionales = dbEX.query(`
                                SELECT DISTINCT *
                                FROM usuarios U
                                WHERE U.id IN (SELECT T.id_tecnico FROM parte_tecnicos T WHERE T.id_tecnico IN (${tecnicos}) AND T.deleted_at IS NULL) AND U.id_role IN (5, 9)
                            `);
                            resolve(tecnicosAdicionales);
                        });
                            tecnicoAdicional.then(async (value:any) => {
                                const tecnicosAdi = value.values;
                                parte.tecnicos_adicionales = tecnicosAdi;
                            });
                    } else parte.tecnicos_adicionales = [];
                }, 1);


                // Documentos - Direccion
                const direccionDocumentoPromise = new Promise((resolve) => {
                    const documento = dbEX.query(`SELECT * FROM documentos WHERE id_direccion=${parte?.id_direccion || 0} AND tecnico_ver=1 AND (expires_at IS NULL OR expires_at>='${moment().format("YYYY-MM-DD H:mm:ss")}') AND deleted_at IS NULL`);
                    resolve(documento);
                });
                    direccionDocumentoPromise.then(async (value:any) => {
                        const direccionDocumentos = value.values;
                        if (direccionDocumentos.length) parte.direccion.documentos = direccionDocumentos || [];
                    });
                // Documentos - Direccion->Cliente
                const direccionClienteDocumentoPromise = new Promise((resolve) => {
                    // FIXME: !!!
                    setTimeout(() => {
                        const documento = dbEX.query(`SELECT * FROM documentos WHERE id_cliente=${parte?.direccion?.id_cliente || 0} AND id_direccion=${parte?.id_direccion || 0} AND tecnico_ver=1 AND (expires_at IS NULL OR expires_at>='${moment().format("YYYY-MM-DD H:mm:ss")}') AND deleted_at IS NULL`);
                        resolve(documento);
                    }, 10);
                });
                    direccionClienteDocumentoPromise.then(async (value:any) => {
                        const direccionClienteDocumentos = value.values;
                        // FIXME:
                        if (parte?.direccion && direccionClienteDocumentos.length && parte.direccion?.cliente) parte.direccion.cliente.documentos = direccionClienteDocumentos;
                    });
                // Documentos - Maquinas
                // FIXME: forEach is not a function | Comprobado y la variable si es un Array...
                // if (parte?.maquinas?.length) {
                //     console.log('***: ', parte?.maquinas);
                //     (parte?.maquinas || [])?.forEach((m:any, index:number) => {
                //         const maquinaPromise = new Promise((resolve) => {
                //             const maquina = dbEX.query(`SELECT * FROM documentos WHERE id_maquina=${m?.id} AND tecnico_ver=1 AND (expires_at IS NULL OR expires_at>=${moment().format("YYYY-MM-DD H:mm:ss")}) AND deleted_at IS NULL`);
                //             resolve(maquina);
                //         });
                //             maquinaPromise.then(async (value:any) => {
                //                 const maquinaDocumentos = value.values;
                //                 parte.maquina[index].documentos = maquinaDocumentos;
                //             });
                //     });
                // }

                if (parte.id_gama) {
                    // Gamas
                    const gamaPromise = new Promise((resolve) => {
                        const gama = dbEX.query(`
                        SELECT *
                        FROM gamas
                        WHERE id=${parte.id_gama}
                        `);
                        resolve(gama);
                    });
                        gamaPromise.then(async (value:any) => {
                        const gama: any = value.values[0];
                        parte.gama = gama;
                        });
                }
                if (parte.id_subgama) {
                    // Sub Gamas
                    const subGamaPromise = new Promise((resolve) => {
                        const subGama = dbEX.query(`
                        SELECT *
                        FROM gamas
                        WHERE id=${parte.id_subgama} AND id_gama=${parte?.id_gama || 0}
                        `);
                        resolve(subGama);
                    });
                        subGamaPromise.then(async (value:any) => {
                        const subGama: any = value.values[0];
                        parte.sub_gama = subGama;
                        });
                }
                parte.offline = '#FF0000'
                store.workorder = parte;

                return parte;
            });
        });
    }

    function getPartes_workorders(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        const promeseFinal = new Promise((resolve) => {
            const res = promesa.then((value: any) => {
                const partes = value;
                const partesProvide = setUpWorkOrders(partes, dbEX);

                store.workorders = partesProvide;
                return partesProvide;
            });

            resolve(res);
        });
        return promeseFinal;
    }

    function getDirecciones_workorders(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            store.addresses = value.values;
        })
    }

    function getActivos_general(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const maquinas = value.values;
            maquinas.map((m:any) => {
                if (m.modelo) {
                    m.modelo = JSON.parse(m.modelo);
                }
            });
            store.assets = maquinas;
        })
    }

    function getOperarioAdicional_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const tecnicos = value.values;
            store.operariosAdicionales = tecnicos;
        })
    }

    function getReplies_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const respuestas = value.values;

            respuestas.map(async (r:any) => {
                const respuestaMaterialPromise = new Promise((resolve) => {
                    resolve(dbEX.query(`
                        SELECT DISTINCT A.*, F.cantidad
                        FROM articulos as A
                        INNER JOIN faq_materiales_respuestas F
                        ON A.id=F.id_articulo
                            WHERE F.id_respuesta=${r.id || 0} AND (A.deleted_at IS NULL OR A.deleted_at='null');
                    `));
                });
                return await respuestaMaterialPromise.then(async (value:any) => {
                    const materiales = value.values;
                    r.materiales = materiales;
                    await (r.materiales || []).map((m:any) => {
                        const materialAlmacenPromise = new Promise((resolve) => {
                            resolve(dbEX.query(`
                                SELECT *
                                FROM almacenes
                                WHERE id IN (SELECT id_almacen FROM almacen_articulos WHERE id_articulo=${m.id || 0}) AND (deleted_at IS NULL OR deleted_at='null')
                            `));
                        });
                        materialAlmacenPromise.then((value:any) => {
                            const almacen = value.values;
                            m.almacenes = almacen;
                            return m;
                        });
                    });
                    return await r;
                });
            });
            store.replies = respuestas;
        })
    }

    function getQuestions_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const preguntas = value.values;
            store.questions = preguntas;
        });
    }

    function setEspecialidad_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const gamas = value.values;
            store.gamas = gamas;
        });
    }

    function setSubespecialidad_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const subgamas = value.values;
            store.subgamas = subgamas;
        });
    }

    function setOperario_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const tecnicos = value.values;
            store.tecnicos = tecnicos;
        });
    }

    function getTextosPredefinidos_general(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const soluciones = value.values;
            store.textosPredefinidos = soluciones;
        });
    }

    function getModelos_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const modelos = value.values;
            store.modelos = modelos;
        });
    }

    function getSistemas_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const sistemas = value.values;
            store.sistemas = sistemas;
        });
    }

    function getMaquinas_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const assets = value.values;
            if (query.includes('parte_maquinas')) { // Busqueda de maquinas de un parte. A futuro habra pensar otra lógica
                store.wo_assets = assets;
            } else {
                store.assets = assets;
            }
        });
    }

    function getArticle_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query[0]);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const articulo = value.values[0];
            if (Array.isArray(query) && query[1]) {
                const { id_almacen, id_articulo } = query[1];

                const almacenesPromise = new Promise((resolve) => {
                    resolve(dbEX.query(`SELECT * FROM almacen_articulos WHERE id_almacen=${id_almacen} AND id_articulo=${id_articulo}`))
                });
                almacenesPromise.then((value:any) => {
                    const almacenes = value.values;
                    articulo.almacenes = almacenes;
                })
            }
            store.articulo = articulo;
        });
    }

    function getMaterials_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            store.selMaterials.all.data = value.values;
        });
    }

    function getLotesMaterial_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const lotes = value.values;

            lotes.map((l:any) => {
                const lotePromise = new Promise((resolve) => {
                    resolve(dbEX.query(`SELECT * FROM lotes WHERE id=${l?.id_lote || 0}`));
                });
                lotePromise.then((value:any) => {

                    l.lote = value.values[0];
                    return l;
                });
            });
            store.lotes = lotes;
        });
    }

    function getAlmacenesMaterial_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query[0]);
            resolve(datos);
        });
        promesa.then((value:any) => {
            const almacenes = value.values;

            almacenes.map((al:any) => {
                const articuloPromise = new Promise((resolve) => {
                    resolve(dbEX.query(`SELECT * FROM articulos WHERE id=${query[1].id_articulo || 0}`));

                });
                articuloPromise.then((value:any) => {
                    al.articulos = value.values;
                    return al;
                });
            });
            store.almacenesArticulo = almacenes;
        });
    }

    // FUNCIONES INTERNAS
    function setUpWorkOrders(partes:any, dbEX:any) {
        const partesProvide: any = [];
        partes?.values?.forEach((parte: any) => {
            // Direccion - cliente
            const direccionPromise = new Promise((resolve) => {
                const direccion = dbEX.query(`SELECT * FROM direcciones WHERE id=${parte?.id_direccion || 0} AND (deleted_at IS NULL OR deleted_at='null')`);
                resolve(direccion);
            });
                direccionPromise.then(async (value:any) => {
                const dir = {
                    cliente: {},
                    values: value.values[0]
                };
                const clientePromise = new Promise((resolve) => {
                    const cliente = dbEX.query(`SELECT * FROM clientes WHERE id=${dir.values?.id_cliente || 0} AND (deleted_at IS NULL OR deleted_at='null')`);
                    resolve(cliente);
                });
                clientePromise.then((value:any) => {
                    if (value.values[0]) dir.cliente = value?.values[0];
                });

                parte.direccion = dir.values;
                });

            // Horas
            const horaPromise = new Promise((resolve) => {
                const hora = dbEX.query(`
                    SELECT * FROM parte_horas
                    WHERE id_parte=${parte?.id || 0} AND 
                        (deleted_at IS NULL OR deleted_at='null')
                    ORDER BY id ASC
                    `);
                resolve(hora);
            });
                horaPromise.then(async (value:any) => {
                const horas = value.values;

                horas.map((hora:any) => {
                    if (hora.id_tipo_tiempo) {
                        const tipoTiempo = new Promise((resolve) => {
                            const tipo = dbEX.query(`SELECT * FROM tipos_tiempo WHERE id=${hora.id_tipo_tiempo || 0} AND (deleted_at IS NULL OR deleted_at='null')`);
                            resolve(tipo);
                        });
                        tipoTiempo.then((value:any) => {
                            hora.tipo_tiempo = value.values[0];
                        });
                    }
                    if (hora.id_tecnico) {
                        const tecnico = new Promise((resolve) => {
                            const tec = dbEX.query(`SELECT * FROM usuarios WHERE usuarios.id=${hora.id_tecnico} AND usuarios.id_role IN (5, 9) AND (usuarios.deleted_at IS NULL OR usuarios.deleted_at='null')`);
                            resolve(tec);
                        });
                        tecnico.then((value:any) => {
                            hora.tecnico = value.values[0];
                        });
                    }
                    return hora;
                });
                parte.horas = horas;
                });
            // Imagenes
            const imagenPromise = new Promise((resolve) => {
                const imagen = dbEX.query(`SELECT * FROM parte_imagenes WHERE id_parte=${parte?.id || 0} AND id_tecnico=${parte.id_tecnico || 0} AND (deleted_at IS NULL OR deleted_at='null')`);
                resolve(imagen);
            });
                imagenPromise.then(async (value:any) => {
                parte.imagenes = value.values;
                });
            // Tecnico/s
            const tecnicosPromise = new Promise((resolve) => {
                const tecnicos = dbEX.query(`SELECT * FROM parte_tecnicos WHERE id_parte=${parte?.id || 0} AND (deleted_at IS NULL OR deleted_at='null')`);
                resolve(tecnicos);
            });
                tecnicosPromise.then(async (value:any) => {
                    parte.tecnicos = value.values;
                });
            const tecnicoPromise = new Promise((resolve) => {
                const tecnico = dbEX.query(`
                    SELECT *
                    FROM usuarios U
                    WHERE U.id=${parte?.id_tecnico || 0} AND U.id_role=(
                    SELECT id FROM usuarios_roles WHERE nombre='Técnico'
                    ) AND (U.deleted_at is NULL OR U.deleted_at='null')
                `);
                resolve(tecnico);
            });
                tecnicoPromise.then(async (value:any) => {
                    parte.tecnico = value.values[0];
                });

            // Sistema - Maquinas
            const sistemaPromise = new Promise((resolve) => {
                const sistema = dbEX.query(`SELECT * FROM sistemas WHERE id_direccion=${parte?.id_direccion || 0} AND (deleted_at IS NULL OR deleted_at='null')`);
                resolve(sistema);
            });
                sistemaPromise.then(async (value:any) => {
                const sis = {
                    maquinas: [],
                    values: value.values[0]
                };
                const maquinaPromise = new Promise((resolve) => {
                    const maquina = dbEX.query(`SELECT * FROM maquinas WHERE id_sistema=${sis.values?.id || 0} AND (deleted_at IS NULL OR deleted_at='null')`);
                    resolve(maquina);
                });
                maquinaPromise.then((value:any) => {
                    if (value.values[0]) sis.maquinas = value.values;
                });

                parte.sistema = sis.values;
                });

            // Lineas
            const lineaPromise = new Promise((resolve) => {
                const linea = dbEX.query(`SELECT * FROM parte_materiales WHERE id_parte=${parte?.id || 0} AND (deleted_at IS NULL OR deleted_at='null')`);
                resolve(linea);
            });

                lineaPromise.then(async (value:any) => {
                    parte.lineas = value.values;
                    parte.lineas.map((l:any) => {
                        const articuloPromise = new Promise((resolve) => {
                            resolve(dbEX.query(`
                                SELECT * FROM articulos WHERE id=${l.id_articulo || 0}
                            `))
                        });
                        articuloPromise.then((value:any) => {
                            const articulo = value.values[0];
                            l.articulo = articulo;
                            return l;
                        });
                        // Articulo movimiento -> almacen
                        const articuloMovimientoPromise = new Promise((resolve) => {
                            resolve(dbEX.query(`
                                SELECT * FROM almacenes WHERE id=${l?.id_almacen || 0}
                            `));

                        });
                        articuloMovimientoPromise.then((value:any) => {

                            const almacen = value.values[0];
                            l.almacen = almacen || null;
                            return l;
                        });
                        // MoviStock -> lote
                        const lotePromise = new Promise((resolve) => {
                            resolve(dbEX.query(`
                                SELECT * FROM lotes WHERE id IN (SELECT id_lote FROM lotes_almacenes WHERE id_lote=${l?.id_lote || 0} AND id_almacen=${l?.id_almacen || 0})
                            `));

                        });
                        lotePromise.then((value:any) => {

                            const lote = value.values[0];
                            l.lote = lote || null;
                            return l;
                        });
                    })
                });

            // Materiales
            const materialPromise = new Promise((resolve) => {
                const material = dbEX.query(`
                SELECT DISTINCT A.*, P.cantidad, P.descuento_por, P.id_maquina
                FROM articulos as A
                INNER JOIN parte_materiales P
                ON A.id=P.id_articulo
                    WHERE P.id_parte=${parte?.id || 0} AND (A.deleted_at IS NULL OR A.deleted_at='null')
                `);
                resolve(material);
            });
                materialPromise.then(async (value:any) => {
                const materiales = value.values.map((m:any) => {
                    m.pivot = {
                    id_maquina: m.id_maquina,
                    descuento_por: m.descuento_por,
                    cantidad: m.cantidad,
                    }

                    delete m.id_maquina;
                    delete m.descuento_por;
                    delete m.cantidad;

                    return m;
                });
                parte.materiales = materiales;
                });

            // Maquinas
            const maquinaPromise = new Promise((resolve) => {
                const maquina = dbEX.query(`
                SELECT DISTINCT M.*, P.tareas_completadas, P.pivot as pivot
                FROM maquinas as M
                INNER JOIN parte_maquinas P
                ON P.id_maquina=M.id
                    WHERE P.id_parte=${parte?.id || 0} AND (P.deleted_at IS NULL OR P.deleted_at='null')
                `);
                // ${parte?.id_sistema ? ` AND (M.id_sistema=${parte?.id_sistema} OR M.id_sistema IS NULL)` : ''}

                resolve(maquina);
            });
                maquinaPromise.then(async (value:any) => {
                const maquinas: any = [];
                value.values.forEach((m:any) => {
                    // // Anomalia
                    const anomaliaMaquinaPromise = new Promise((resolve) => {
                        const anomalia = dbEX.query(`SELECT * FROM modelos_parte_anomalias WHERE id_parte=${parte?.id || 0} ${m?.id ? ` AND id_maquina=${m?.id}` : ''}`)
                        resolve(anomalia);
                    });

                    anomaliaMaquinaPromise.then((value:any) => {
                        if (value.values[0]) {
                            m.anomalia = value?.values[0];
                        } else {
                            m.anomalia = null;
                        }
                    });
                    // Modelo
                    const modeloMaquinaPromise = new Promise((resolve) => {
                        const modelo = dbEX.query(`SELECT * FROM maquina_modelos WHERE id=${m?.id_modelo || 0}`)
                        resolve(modelo);
                    });
                    modeloMaquinaPromise.then((value:any) => {
                        if (value.values[0]) {
                            m.modelo = value?.values[0];
                        } else {
                            m.modelo = null;
                        }
                    });

                    // Maquina Checklist
                    const maquinaRespuestasCheckListPromise = new Promise((resolve) => {
                        const modelo = dbEX.query(`SELECT * FROM modelos_parte_campos_respuestas WHERE id_parte=${parte?.id || 0} ${m?.id ? ` AND id_maquina=${m?.id}` : ''} AND (deleted_at IS NULL OR deleted_at='null')`)
                        resolve(modelo);
                    });
                    maquinaRespuestasCheckListPromise.then((value:any) => {
                        if (value.values) {
                            const resps = value?.values;
                            resps.map((r:any) => {
                                r.campo = JSON.parse(r.campo);
                                return r;
                            })
                            m.respuestas = resps;
                        } else {
                        m.respuestas = null;
                        }
                    });

                    m.pivot = JSON.parse(m.pivot);
                    if (m?.pivot?.tareas_completadas == 0) m.pivot.tareas_completadas = m?.tareas_completadas;
                    // m.pivot = {
                    //     id_maquina: m?.id,
                    //     id_parte: parte?.id,
                    //     descuento_por: m.descuento_por || '',
                    //     cantidad: m.cantidad || '',
                    //     tareas_completadas: m.tareas_completadas || 0,
                    // }

                    // delete m.id_maquina;
                    // delete m.descuento_por;
                    // delete m.cantidad;

                    maquinas.push(m);
                });
                    parte.maquinas = maquinas;
                    // Maquinas Respuestas
                    const maquinaRespuestasPromise = new Promise((resolve) => {
                        if (parte.maquinas && parte.maquinas.length) {
                            const ids = parte?.maquinas?.map((m:any) => m.id);

                            const maquina = dbEX.query(`
                                SELECT *
                                FROM faq_respuestas_parte_maquinas
                                WHERE id_parte=${parte?.id || 0} AND id_maquina IN (${ids.toString()}) AND (deleted_at IS NULL OR deleted_at='null')
                            `);

                            resolve(maquina);
                        }

                    });
                        maquinaRespuestasPromise.then(async (value:any) => {
                            const maquinasRespuestas: any = value.values;
                            parte.maquinas_respuestas = maquinasRespuestas;
                        });

                });

            // Firma Tecnico
            const firmaTecnicoPromise = new Promise((resolve) => {
                const firmaTec = dbEX.query(`
                    SELECT * FROM parte_firma WHERE id_parte=${parte?.id || 0} AND id_tecnico=${parte.id_tecnico || 0} AND firma_tec=1 AND (deleted_at IS NULL OR deleted_at='null')
                `);

                resolve(firmaTec);
            });
                firmaTecnicoPromise.then(async (value:any) => {
                const firmaTec = value.values[0];
                parte.firma_tecnico = firmaTec;
                });

            // Firma Cliente
            const firmaClientePromise = new Promise((resolve) => {
                const firmaCli = dbEX.query(`
                    SELECT * FROM parte_firma WHERE id_parte=${parte?.id || 0} AND id_tecnico=${parte.id_tecnico || 0} AND (firma_tec=0 OR firma_tec IS NULL) AND (deleted_at IS NULL OR deleted_at='null')
                `);

                resolve(firmaCli);
            });
                firmaClientePromise.then(async (value:any) => {
                const firmaCli = value.values[0];
                parte.firma = firmaCli;
                });

                // if (parte.last_modified) parte.offline = '#FF0000';
            partesProvide.push(parte);
        });
        return partesProvide;
    }

    async function getDirecciones_new_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });

        promesa.then((value:any) => {
            console.log('resultado: ', value);

            store.addresses = value.values;
        });
    }

    async function getProyectos_new_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });

        promesa.then((value:any) => {
            store.proyectos = value.values;
        });
    }

    async function getMaquinasSistemas_workorder(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });

        promesa.then((value:any) => {
            console.log('resultado: ', value);
            store.assets = value.values;
        });
    }

    async function getParteMaquina_item(datos:any, store:any) {
        const {query: d, dbEX} = datos;
        try {
            let query = null;
            let datosAsset = null;

            if (d?.length) {
                query = d[0];
                datosAsset = d[1];
            }

            const promesa = new Promise((resolve) => {
                const datos: any = dbEX.query(query);
                resolve(datos);
            });

            promesa.then((value:any) => {
                const asset = value.values[0];
                if (asset && datosAsset) {
                    const queryRespuestas = `SELECT * FROM modelos_parte_campos_respuestas
                        WHERE id_parte=${datosAsset.wo} AND id_maquina=${asset.id} AND (deleted_at IS NULL OR deleted_at='null' OR deleted_at='');
                    `;
                    
                    const promesa = new Promise((resolve) => {
                        const datos: any = dbEX.query(queryRespuestas);
                        resolve(datos);
                    });
                    let resp = []
                    promesa.then((data: any) => {
                        resp = data.values;
                        resp = resp?.map((r: any) => {
                            r.campo = JSON.parse(r.campo);
                            return r;
                        }) || [];
                        asset.respuestas = resp;

                        store.item_asset = asset;
                    })
                } else {
                    console.error('Faltan datos:: ');
                    store.item_asset = asset;
                }
            });
        } catch (error) {
            console.error('LA:: ', error);
            
        }
    }

    async function getVehiculosTex(datos:any, store:any) {
        const {query, dbEX} = datos;

        const promesa = new Promise((resolve) => {
            const datos: any = dbEX.query(query);
            resolve(datos);
        });

        promesa.then((value:any) => {
            console.log('vehiculos: ', value);
            store.vehiculos = value.values;
        });
    }

    async function getIsWorkingDay(datos:any, store:any) {
        const { dbEX } = datos;
        const queries = [];

        const vacaciones = await dbEX.query(`SELECT COUNT(*) FROM usuarios_vacaciones;`);

        const festivos = await dbEX.query(`SELECT COUNT(*) FROM festivos;`);

        queries.push([vacaciones, festivos]);
        
        Promise.all(queries).then((data:any) => {
            const count1 = data.flat().map((v:any) => v.values).flat()?.length;
            store.getIsWorkingDay = !!count1;
        })
    }
}