<template>
  <ion-modal
    :is-open="modelValue"
    :swipe-to-close="true"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >

    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t('Nuevo desplazamiento') }}</ion-title>

        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-grid class="ion-grid-padding">
        <ion-item v-if="displacementTypes?.length" @click="setDisplacementType = true">
          <ion-label position="stacked">{{ `${$t('Tipo')}` }}</ion-label>
          <ion-input
            class="ion-text-right"
            :value="displacementAdd?.displacement?.codigo"
            readonly
            clear-input
          />
        </ion-item>


        <ion-row>
          <ion-col size="12">
            <ion-item>
              <ion-label position="stacked">{{ $t('Nº Km') }} *</ion-label>
              <ion-input type="number" inputmode="decimal" v-model="displacementAdd.cantidad"/>
            </ion-item>
          </ion-col>
          <ion-col size="12" v-if="comportamientos?.mostrar_cuenta_linea">
            <ion-item>
              <ion-label position="stacked">{{ $t('Cuenta') }} *</ion-label>
              <ion-input type="text" v-model="displacementAdd.cuenta"/>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item>
              <ion-label position="stacked">{{ $t('Descripción') }}</ion-label>
              <ion-textarea v-model="displacementAdd.observaciones" />
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item>
              <ion-label position="stacked">{{ $t('PCP') }}</ion-label>
              <ion-input type="number" inputmode="decimal" v-model="displacementAdd.pcp"/>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item>
              <ion-label position="stacked">{{ $t('PVP') }}</ion-label>
              <ion-input type="number" inputmode="decimal" v-model="displacementAdd.pvp"/>
            </ion-item>
          </ion-col>
        </ion-row>

        <ion-button
          expand="full"
          @click="setDisplacement(displacementAdd)"
          :disabled="disabledButton"
        >
          {{ $t('Añadir desplazamiento') }}
        </ion-button>
      </ion-grid>
    </ion-content>
  </ion-modal>


  <MSetFinder
    v-model="setDisplacementType"
    @done="(values) => setType(values)"
    :data="displacementTypes"
    :paginated="false"
    :returnWholeObject="true"
    :content="{
      title: 'Selecciona un tipo',
      empty_title: 'No hay tipos',
      multiple: false,
    }"
    :structure="[
      {
        tag: 'h3',
        key: 'descripcion'
      },
      {
        tag: 'p',
        key: 'codigo'
      }
    ]"
  />


</template>

<script>
import { ref, getCurrentInstance } from 'vue';

import MSetFinder from '@/components/MSetFinder.vue';

// Stores
import { useGmaoStore } from '@/stores/gmao';

// EXPORT MODULE
import { useOfflineStore } from '@/stores/offline';

import { camera } from 'ionicons/icons';

import {
  IonModal,
  IonButton,
  IonLabel,
  IonItem,
  IonContent,
  IonTextarea,
  IonInput,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue';

export default {
  name: 'MSetAddDisplacement',

  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    workorder: {
      required: true,
      type: Object,
    },

    modulos: {
      type: Object,
      required: true,
    },

    comportamientos: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonLabel,
    IonItem,
    IonContent,
    IonTextarea,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,

    MSetFinder,
  },

  watch: {
    modelValue(val) {
      if (val) {
        this.getDisplacementTypes();
      } else this.displacementAdd = {};
    },
  },

  computed: {
    disabledButton() {
      return false;
    },
  },

  setup() {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();

    const app = getCurrentInstance();

    return {
      displacementAdd: ref({}),
      displacementTypes: ref([]),

      setDisplacementType: ref(false),

      // Stores
      gmao,
      offline,

      // OTHER
      app,
      camera,
    };
  },

  methods: {

    setType(type) {
      this.displacementAdd.displacement = type;
      this.setDisplacementType = false;
    },

    // POST
    setDisplacement() {
      if (!this.displacementAdd.cantidad > 0) {
        this.app.appContext.config.globalProperties.$openToastObject(this.$t('Introduce los campos obligatorios'), this.$t('Tiene que introducir los campos obligatorios para poder imputar un desplazamiento.'), 'danger');
      } else {
        const formdata = new FormData();
        formdata.append('u', this.gmao.user.id);
        formdata.append('desplazamiento', this.displacementAdd.displacement?.id || '');
        formdata.append('id_parte', this.workorder?.id || this.$route.params?.id || '');



        formdata.append('cantidad', this.displacementAdd.cantidad || '');
        if (this.comportamientos?.mostrar_cuenta_linea) formdata.append('cuenta', this.displacementAdd?.cuenta || '');
        formdata.append('observaciones', this.displacementAdd.observaciones || '');
        formdata.append('pcp', this.displacementAdd.pcp || '');
        formdata.append('pvp', this.displacementAdd.pvp || '');

        this.$axios
          .post(
            `/v2/users/actions.php?call=setDisplacement&token=${this.gmao.user.token}&v2=1`, formdata)
          .then(({ data }) => {
            if (!+data.status && !data.displacement?.id) {
              this.app.appContext.config.globalProperties.$openToastObject(this.$t('Ha ocurrido un error'), data.mns || this.$t('No se ha podido añadir el desplazamiento.'), 'danger');
            } else {
              this.$emit('update:modelValue', false)
            }
            this.$emit('done', null);
          }).catch((error) => {
          // TODO: Offline
          console.log(error);
        });
      }
    },

    getDisplacementTypes() {
      return this.$axios
        .get('/v2/users/actions.php', {
            params: {
              call: 'getTiposDesplazamientos',
              token: this.gmao.user.token,
              page: -1,
              v2: 1,
            },
          })
          .then(({ data }) => {
            this.displacementTypes = data;
            return data;
          })
    },
  },
};
</script>