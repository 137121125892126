<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ $t('Seleccionar técnico') }}</ion-title>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        :placeholder="$t('Buscar técnicos...')"
        :value="tecnicoSearch"
        @ionInput="({ target }) => $emit('update:tecnicoSearch', target.value)"
        class="ion-no-padding"
      />

      <template v-if="data?.length">
        <ion-list style="margin-top: 12px;">
          <ion-item
            v-if="isTecnicosAdicionales && !newHora.id"
            @click="$emit('done', false)"
            class="ion-no-padding"
          >
            <ion-label>
              <h3>{{ $t('todos-los-tecnicos') }}</h3>
            </ion-label>
          </ion-item>

          <ion-item
            v-for="(tecnico, key) in data"
            :key="`modal-tecnico-${key}`"
            @click="$emit('done', tecnico)"
            class="ion-no-padding"
          >
            <ion-label>
              <h3>{{ tecnico?.nombre_completo }}</h3>
            </ion-label>
          </ion-item>
        </ion-list>
      </template>
    </ion-content>

  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonSearchbar,
} from '@ionic/vue';

export default {
  name: 'MSetTecnicosModal',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    data: {
      required: true,
      type: Array,
    },

    tecnicoSearch: {
      required: true,
      type: String,
    },

    newHora: {
      required: true,
      type: Object,
    },

    isTecnicosAdicionales: {
      required: true,
      type: Boolean,
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonSearchbar,
  },

  setup() {
    return {};
  },

  methods: {},
};
</script>