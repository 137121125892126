<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>

          <ion-title v-if="!firma_tecnico">{{ $t('Firmar parte') }}</ion-title>
          <ion-title v-else>{{ $t('Firmar parte tecnico') }}</ion-title>

          <ion-buttons slot="end">
            <ion-button
              @click="$emit('done', null)"
            >{{ $t('Guardar') }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <div class="signature-block">
        <VueSignaturePad
          :ref="!firma_tecnico ? 'signaturePad' : 'signaturePadTecnico'"
          :options="{
            onBegin: () => {
              if (!firma_tecnico) $refs.signaturePad.resizeCanvas();
              else $refs.signaturePadTecnico.resizeCanvas();
            },
          }"
        />
      </div>
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonContent,
} from '@ionic/vue';

export default {
  name: 'MSetFirma',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    firma_tecnico: {
      type: Boolean,
      default: false
    },
  },

  components: {
    IonModal,
    IonButton,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonContent,
  },

  computed: {},

  setup() {

    return {};
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
.signature-block {
  border: 1px solid black;
  width: 100%;
  height: calc(100% - 44px);
  max-height: 400px;
  background: white;
}
</style>