<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ $t('Iniciar tiempos') }}</ion-title>

          <ion-buttons slot="end">
            <ion-button @click="$emit('done', true)">{{ $t('Iniciar') }}</ion-button>
          </ion-buttons>

        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-list style="border-radius: 15px">
          <ion-item
            v-if="isMainTechnician && isTecnicosAdicionales && !gmao.comportamientos?.tiempos_propio_tecnico"
            @click="$emit('tecnicosModal', null)"
            class="ion-no-padding"
          >
            <ion-label>{{ $t('Técnicos') }}</ion-label>
            <ion-input
              class="ion-text-right"
              :value="newHora.tecnico?.nombre_completo"
              readonly
              clear-input
            ></ion-input>
          </ion-item>

          <ion-item class="ion-no-padding">
            <ion-input
              @click="$emit('setTipoTiempos', null)"
              :value="newHora?.hora?.tipo_tiempo?.nombre"
              :label="$t('Tipo tiempo')"
              readonly
              clear-input
              class="ion-text-right"
            />
          </ion-item>
        </ion-list>
    </ion-content>

  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonInput,
} from '@ionic/vue';

import { useGmaoStore } from '@/stores/gmao';

export default {
  name: 'MSetHora',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    newHora: {
      required: true,
      type: Object,
    },

    isTecnicosAdicionales: {
      required: true,
      type: Boolean,
    },

    isMainTechnician: {
      required: true,
      type: Boolean,
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonInput,
  },

  setup() {
    const gmao = useGmaoStore();

    return {
      gmao
    };
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
ion-item::part(native) .item-inner {
  padding-right: 0;
}
</style>