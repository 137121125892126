import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

interface JornadaHoras {
    fecha: string;
    totalHoras: number;
    totalDia: string;
    items: Array<{
        id: number;
        direccion: string | null;
        break_time: number;
        desde: string;
        hasta: string;
        inicio: string;
        fin: string;
        duracion: string;
        total_duration: number;
        edited: string;
    }>;
}

export const useOfflineStore = defineStore('offline', {
    state: () => {
        return {
            status: useStorage('status', false),
            loading: useStorage('loading', null),
            showLoader: useStorage('showLoader', false),
            counterQueue: useStorage('offlineCounter', 0),
            workorders: [],
            addresses: [],
            workorder: useStorage('workorder', {}),
            incidence: {},
            assets: [],
            wo_assets: [],
            replies: [],
            questions: [],
            tecnicos: [],
            gamas: [],
            subgamas: [],
            operariosAdicionales: [],
            textosPredefinidos: [],
            comunicacion: {},
            modelos: [],
            sistemas: [],
            selMaterials: {all: {data: []}},
            articulo: {},
            almacenesArticulo: [],
            item_asset: {},
            lotes: [],
            proyectos: [],
            jornada_horas: {},
            workedDays: useStorage<JornadaHoras[]>('jornadas', []),
            isWorkingDay: useStorage('isWorkingDay', false),
        }
    },

    actions: {
        updateWorkedDay(updatedDay: JornadaHoras) {
            const index = this.workedDays.findIndex(day => day.fecha === updatedDay.fecha);
            if (index !== -1) {
                this.workedDays[index] = updatedDay;
            }
        }
    },
})